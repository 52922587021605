import {
  LeadManagementApi,
  LeadManagementApiLeadFindAllPaginateRequest,
  LeadServiceTimesApi,
  LeadServiceTimesApiLeadServiceTimeCreateRequest
} from '../../api-client/generated/api';
import { weekDays } from '../../helpers/weekDays';

const getAllLead = async () => {
  return {};
};

// TODO: Enviar junto con respuesta un string de donde se quedo.
const createLead = async (params: any) => {
  if (!params) {
    return null;
  }
  const {
    addressId,
    clientId,
    contactId,
    description,
    manualPricing,
    name,
    endDate,
    startDate,
    projectType
  } = params;

  const apiCaller = new LeadManagementApi();
  const apiLeadServicesCaller = new LeadServiceTimesApi();
  let newEndDate;
  if (endDate && typeof endDate === 'string') {
    newEndDate = endDate;
  } else if (endDate) {
    newEndDate = endDate.format('YYYY-MM-DD');
  } else {
    newEndDate = null;
  }
  const responseData = await apiCaller.leadCreate({
    createLeadGeneralInfoDto: {
      addressId,
      clientId,
      contactId,
      description,
      manualPricing,
      name,
      projectEndDate: newEndDate,
      projectStartDate:
        typeof startDate === 'string'
          ? startDate
          : startDate.format('YYYY-MM-DD'),
      projectType
    }
  });
  const { data } = responseData;

  if (manualPricing || projectType === 'Ad-Hoc') {
    const { id } = data;
    const date = new Date(
      typeof startDate === 'string' ? startDate : startDate.format('YYYY-MM-DD')
    );
    date.setDate(date.getDate() + 1);
    const weekDay = weekDays[date.getDay()];
    // const serviceTimeResponse =
    //   await apiLeadServicesCaller.leadServiceTimeCreate(requestParameters);
    // TODO: Review if the sencond argument is necessary to send because it's not a config

    const requestParameters: LeadServiceTimesApiLeadServiceTimeCreateRequest = {
      createLeadServiceTimeDto: {
        endDate: newEndDate,
        endTime: '18:00',
        frequency: 'Weekly',
        frequencyDays: [weekDay.name],
        individualTasks: [],
        services: manualPricing
          ? [
              {
                description: 'General Service',
                name: 'General Service',
                setupTime: null,
                tasks: [],
                teardownTime: null
              }
            ]
          : [],
        startDate:
          typeof startDate === 'string'
            ? startDate
            : startDate.format('YYYY-MM-DD'),
        startTime: '08:00'
      },
      id
    } as any;
    const serviceTimeResponse =
      await apiLeadServicesCaller.leadServiceTimeCreate(requestParameters);
    return {
      ...data,
      serviceTimes: [{ ...serviceTimeResponse.data, totalTime: 0 }]
    };
  }

  return data;
};
const getLeadsPaginated = async (
  params: LeadManagementApiLeadFindAllPaginateRequest
) => {
  if (!params) {
    return null;
  }
  const apiCaller = new LeadManagementApi();
  const response: any = await apiCaller.leadGetLeadsPaginatedForListing(params);
  const { data } = response;
  return data;
};

const getLead = async (id: string) => {
  if (!id) {
    return null;
  }
  const apiCaller = new LeadManagementApi();
  const response = await apiCaller.leadFindOne({ id });
  const { data } = response;
  const dataClient = data.client
    ? {
        ...data.client,
        name: `${data.client.firstName} ${data.client.lastName} from ${data.client.businessName}`
      }
    : null;
  const newServiceTimes = data.serviceTimes.map((serviceTime: any) => {
    const auxIndividualTasks = {
      ...serviceTime.individualTasks,
      tasks:
        serviceTime.individualTasks.length > 0
          ? serviceTime.individualTasks.map((task: any) => {
              let materials = [{}];
              if (task?.taskMaterials && task?.taskMaterials.length > 0) {
                materials = task.taskMaterials.map((materialData: any) => {
                  return {
                    cost: parseFloat(materialData.cost),
                    materialId: materialData.id,
                    name: materialData.material?.name
                  };
                });
              }
              return {
                ...task,
                checklist: task?.taskChecklist || [],
                materials,
                skills: task?.skills || []
              };
            })
          : []
    };
    let sumTotalServiceTimeHours = 0;
    return {
      ...serviceTime,
      availability: serviceTime.availability,
      individualTasks: auxIndividualTasks,
      services: serviceTime.services.map((service: any) => {
        let sumTotalServiceHours = 0;
        const checkListService =
          service?.checklist?.length > 0
            ? service?.checklist.map((checkItem: any) => checkItem.name)
            : [];
        const tasksService = service.tasks.map((task: any) => {
          let taskCheckList = [];

          if (task?.checklist && task?.checklist.length > 0) {
            taskCheckList = task?.checklist;
          } else if (service?.checklist?.length > 0) {
            taskCheckList = checkListService;
          }

          const quantity = task.quantity || 0;
          const measurePerTime = parseFloat(task.measurePerHour || 0);
          const minRequired = Number(task?.minimumResources) ?? 1;
          const cantTechnicians =
            task?.technicians?.length > 0
              ? task?.technicians?.length
              : task?.resources?.length || 1;
          const totalTime =
            task?.measure === 'Unit'
              ? quantity * measurePerTime
              : quantity / (measurePerTime || 1);
          const totalTimeNumber =
            Number((totalTime * minRequired) / cantTechnicians) || 0;

          sumTotalServiceHours += totalTimeNumber;
          return {
            ...task,
            checklist: taskCheckList,
            materials: task?.materials || [],
            quantity: task.quantity || 0,
            resources: task.technicians || [],
            skills: task?.skills || [],
            taskHoursPerDay: totalTimeNumber || 0,
            time: task.quantity || 0,
            totalTime: totalTimeNumber || 0
          };
        });
        sumTotalServiceTimeHours += sumTotalServiceHours;
        return {
          ...service,
          checklist: checkListService,
          tasks: tasksService,
          totalTime: sumTotalServiceHours
        };
      }),
      supervisor: {
        ...serviceTime?.supervisor,
        name: serviceTime?.supervisor?.firstName
          ? `${serviceTime?.supervisor.firstName} ${serviceTime?.supervisor.lastName}`
          : serviceTime?.supervisor?.name || ''
      },
      totalTime: sumTotalServiceTimeHours
    };
  });
  const originalFiles = data?.fileBank || [];
  const newFiles: File[] =
    data?.fileBank && data?.fileBank.length > 0
      ? data.fileBank.map((file: any) => {
          return new File([file], file.fileOriginalName, {
            type: file.mimeType
          });
        })
      : [];

  return {
    ...data,
    client: dataClient,
    endDate: data.projectEndDate,
    fileBank: newFiles,
    originalFiles,
    profit: Number(data?.profitPercentage),
    serviceTimes: newServiceTimes,
    startDate: data.projectStartDate
  };
};
const updateLeadGeneralInfo = async (params: any) => {
  if (!params) {
    return null;
  }
  const {
    addressId,
    clientId,
    contactId,
    description,
    id,
    manualPricing,
    name,
    endDate,
    startDate,
    projectType,
    profitPercentage,
    profit
  } = params;
  const apiCaller = new LeadManagementApi();
  let newEndDate;
  if (endDate && typeof endDate === 'string') {
    newEndDate = endDate;
  } else if (endDate) {
    newEndDate = endDate.format('YYYY-MM-DD');
  } else {
    newEndDate = null;
  }

  const response = await apiCaller.leadUpdate({
    id,
    updateLeadGeneralInfoDto: {
      addressId,
      clientId,
      contactId,
      description,
      manualPricing,
      name,
      profitPercentage: Number(profit || profitPercentage),
      projectEndDate: newEndDate,
      projectStartDate:
        typeof startDate === 'string'
          ? startDate
          : startDate.format('YYYY-MM-DD'),
      projectType
    }
  });
  const { data } = response;

  return data;
};

const setLead = (params: any) => {
  return { params };
};

const deleteLead = (id: string) => {
  return { id };
};

const convertToProject = (id: string) => {
  return { id };
};

const getLeadAnalytics = async (params: any) => {
  const { id, startDate, endDate } = params;
  if (!id) {
    return null;
  }
  const apiCaller = new LeadManagementApi();
  const response = await apiCaller.leadGetProposalCalculator({
    endDate,
    id,
    startDate
  });
  const { data } = response;
  return data;
};

export default {
  convertToProject,
  createLead,
  deleteLead,
  getAllLead,
  getLead,
  getLeadAnalytics,
  getLeadsPaginated,
  setLead,
  updateLeadGeneralInfo
};
