/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  TextField,
  TextFieldProps,
  Typography,
  styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import ArrayChips from '../list/ArrayChips';
import {
  secondaryColors,
  themeFont,
  themePalette
} from '../../theme/themeConfig';

const rolesStyle = {
  backgroundColor: '#D9F2F0',
  border: '1px solid #68C0BA',
  borderRadius: '24px !important',
  color: '#0F5C56'
};

const AutoTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${secondaryColors.SECONDARY_1}`
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderStyle: 'hidden'
  },
  backgroundColor: themePalette.BACKGROUND,
  input: {
    color: secondaryColors.SECONDARY_3,
    fontFamily: themeFont.FONT_SECONDARY,
    fontSize: '16px',
    fontWeight: 400,
    height: 40,
    lineHeight: 1.25,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingTop: 8
  },
  paddingLeft: 0,
  paddingRight: 0
}));

interface Props {
  setValue: (item: any) => void;
}

const AddServiceModal: React.FC<Props> = function AddServiceModal({
  setValue
}) {
  const { t } = useTranslation();
  const [getData, setData] = useState<boolean>(false);
  const [services, setServices] = useState<Array<any>>();
  const [serviceSelected, setServiceSelected] = useState<any>();
  const [askService, setAskService] = useState<boolean>();

  //  ================================================================================= SWR
  //  ================================================================================= SWR
  const { data } = useSWR(
    getData ? { url: 'api/AllServices' } : null,
    api.serviceApi.getAllServices
  );

  const { data: serviceData } = useSWR(
    askService
      ? {
          id: serviceSelected.id,
          relations: 'tasks,skills,categories',
          url: 'api/getService'
        }
      : null,
    api.serviceApi.getService
  );

  //  ================================================================================= USEEFFECTS

  useEffect(() => {
    if (data) {
      setData(false);
      setServices(data);
    }
  }, [data]);

  useEffect(() => {
    if (serviceData) {
      setAskService(false);
      let tasks = [];
      if (serviceData?.tasks && serviceData?.tasks?.length > 0) {
        tasks = serviceData?.tasks.map((task: any) => {
          let materials = [{}];
          if (task?.taskMaterials && task?.taskMaterials?.length > 0) {
            materials = task.taskMaterials.map((materialData: any) => {
              return {
                cost: parseFloat(materialData.cost),
                materialId: materialData.id,
                name: materialData.material?.name
              };
            });
          }
          if (task?.materials && task?.materials?.length > 0) {
            materials = task.materials.map((materialData: any) => {
              return {
                cost: parseFloat(materialData.cost),
                materialId: materialData.id,
                name: materialData.material?.name
              };
            });
          }
          return {
            ...task,
            materials
          };
        });
      }
      setValue({
        description: serviceData.description,
        id: serviceData.id,
        name: serviceData.name,
        setupTime: serviceData.setupTime,
        tasks,
        teardownTime: serviceData.teardownTime
      });
    }
  }, [serviceData]);

  useEffect(() => {
    if (serviceSelected) {
      setAskService(true);
    }
  }, [serviceSelected]);

  useEffect(() => {
    setData(true);
  }, []);

  //  ================================================================================= RENDER

  return (
    <Box
      alignItems="start"
      display="flex"
      flexDirection="column"
      gap={3}
      justifyContent="start"
      width={500}
    >
      <Typography variant="title">
        {t('LEAD.CREATE.SERVICES.ADD_SERVICE_MODAL.title')}
      </Typography>

      <Box
        alignItems="start"
        display="flex"
        flexDirection="column"
        gap={1}
        width="100%"
      >
        <Typography color="primary.main" variant="body_300">
          {t('LEAD.CREATE.SERVICES.ADD_SERVICE_MODAL.name')}
        </Typography>

        {services && services?.length > 0 && (
          <AutoCompleteInput
            closeOnSelect
            disableCreate
            onChange={(event: any, newValue: any | null) => {
              setServiceSelected(newValue);
            }}
            options={services}
            renderInput={(params) => (
              <AutoTextField
                placeholder={`${t(
                  'LEAD.CREATE.SERVICES.ADD_SERVICE_MODAL.autoCompletePlaceholder'
                )}`}
                {...params}
              />
            )}
            sx={{ margin: 0 }}
            value={serviceSelected}
          />
        )}
      </Box>

      {serviceSelected && (
        <Box display="flex" flexDirection="column" gap={3} width="100%">
          <Box
            alignItems="start"
            display="flex"
            flexDirection="column"
            gap={1}
            width="100%"
          >
            <Typography color="primary.main" variant="body_300">
              {t('LEAD.CREATE.SERVICES.ADD_SERVICE_MODAL.description')}
            </Typography>
            <Typography color="secondary.400" variant="body_300">
              {serviceSelected.description && serviceSelected.description !== ''
                ? serviceSelected.description
                : `${t('LEAD.CREATE.SERVICES.ADD_SERVICE_MODAL.noData')}`}
            </Typography>
          </Box>

          <Box alignItems="start" display="flex" flexDirection="column" gap={1}>
            <Typography
              color="primary.main"
              textAlign="left"
              variant="body_300"
            >
              {t('LEAD.CREATE.SERVICES.ADD_SERVICE_MODAL.tasks')}
            </Typography>

            {serviceSelected.tasks?.length === 0 && (
              <Typography color="secondary.400" variant="body_300">
                {t('LEAD.CREATE.SERVICES.ADD_SERVICE_MODAL.noData')}
              </Typography>
            )}
            <ArrayChips
              unlimited
              colorFont="#0F5C56"
              elementsArray={
                serviceSelected.tasks?.map((item: any) => item.name) || []
              }
              style={rolesStyle}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddServiceModal;
