import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import { t } from 'i18next';
import Stat from './Stat';

interface StatProps {
  hoursWorked: {
    amount: number | string;
    total: number | string;
  };
  tasks: number;
}

const StatsContainer = styled(Stack)(() => ({
  width: '100%'
}));

function Stats({ hoursWorked, tasks }: StatProps) {
  return (
    <StatsContainer direction="row" spacing="16px">
      <Stat title={t('TIMESHEET.totalTasks')} value={tasks.toString()} />
      <Stat
        title={t('TIMESHEET.totalWorked')}
        value={`${hoursWorked.amount}h/${hoursWorked.total}h`}
      />
    </StatsContainer>
  );
}

export default Stats;
