import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/chevron-left.svg';
import { getStatusForRangeDate } from '../../../utils/general';
import {
  mainColors,
  secondaryColors,
  textColors
} from '../../../theme/themeConfig';

export interface StateDatesList {
  formatted: string;
  start: string;
  state: string;
}
export interface Dates {
  days: string[];
  id: number;
  range: string;
  stateDatesList: StateDatesList[];
  status: string;
}

function DateToSelect({
  date,
  handleClick,
  id,
  selected,
  status
}: {
  date: string;
  handleClick: (key: number) => void;
  id: number;
  selected: number;
  status: any;
}) {
  const BGColor = selected === id ? mainColors.MAIN : `${status.bg}`;
  const BGColorText = selected === id ? textColors.TEXT : `${status.txt}`;

  return (
    <Box
      onClick={() => handleClick(id)}
      style={{
        alignItems: 'center',
        backgroundColor: BGColor,
        cursor: 'pointer',
        display: 'flex',
        height: '48px',
        minWidth: '180px',
        padding: '5px',
        width: '100%'
      }}
    >
      <Typography
        style={{
          color: BGColorText,
          fontSize: '10px',
          fontWeight: 800,
          lineHeight: '25px',
          textAlign: 'center',
          width: '100%'
        }}
      >
        {date}
      </Typography>
    </Box>
  );
}

interface DatesProps {
  dates: Dates[];
  selected: number;
  setDay: React.Dispatch<React.SetStateAction<any>>;
  setDetailInfo: React.Dispatch<React.SetStateAction<{}>>;
  setSelectedDayIndex: React.Dispatch<React.SetStateAction<number>>;
}

const CarrouseDates: React.FC<DatesProps> = function CarrouseDates({
  selected,
  dates,
  setSelectedDayIndex,
  setDay,
  setDetailInfo
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCount = 4; // Number of dates visible at a time
  const showArrows = dates.length > visibleCount;

  useEffect(() => {
    const today = new Date();

    const index = dates.findIndex((date: Dates) => {
      const [startDateStr] = date.range.split(' - ');
      const startDate = new Date(startDateStr);
      return startDate > today;
    });

    if (index !== -1) {
      const initialIndex = Math.max(0, index - Math.floor(visibleCount / 2));
      setCurrentIndex(initialIndex);
    } else {
      setCurrentIndex(Math.max(0, dates.length - visibleCount));
    }
  }, [dates]);

  const handleClick = (key: number) => {
    setSelectedDayIndex(key);
    setDay(null);
    setDetailInfo({});
  };

  const handlerCountRight = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleCount < dates.length ? prevIndex + 1 : prevIndex
    );
  };

  const handlerCountLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  return (
    <Box
      style={{
        borderBottom: `solid ${secondaryColors.SECONDARY_1} 1px`,
        borderTop: `solid ${secondaryColors.SECONDARY_1} 1px`,
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}
    >
      {showArrows && (
        <Box
          style={{
            alignItems: 'center',
            borderRight: `solid ${secondaryColors.SECONDARY_1} 1px`,
            display: 'flex',
            justifyContent: 'center',
            opacity: currentIndex === 0 ? 0.5 : 1,
            overflow: 'hidden',
            width: '45px'
          }}
        >
          <Button
            disabled={currentIndex === 0}
            onClick={handlerCountLeft}
            size="small"
            style={{ width: '100%' }}
          >
            <ChevronLeftIcon />
          </Button>
        </Box>
      )}

      <Box
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row'
        }}
      >
        {dates
          .slice(currentIndex, currentIndex + visibleCount)
          .map((dateProps, index, visibleDates) => {
            const status = getStatusForRangeDate(dateProps.status);
            const isLast = index === visibleDates.length - 1;
            return (
              <>
                <DateToSelect
                  date={dateProps.range}
                  handleClick={handleClick}
                  id={dateProps.id}
                  key={dateProps.id}
                  selected={selected}
                  status={status}
                />
                {!isLast && (
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ borderColor: secondaryColors.SECONDARY_1 }}
                  />
                )}
              </>
            );
          })}
      </Box>

      {showArrows && (
        <Box
          style={{
            alignItems: 'center',
            borderLeft: `solid ${secondaryColors.SECONDARY_1} 1px`,
            display: 'flex',
            justifyContent: 'center',
            opacity: currentIndex + visibleCount >= dates.length ? 0.5 : 1,

            overflow: 'hidden',
            width: '45px'
          }}
        >
          <Button
            disabled={currentIndex + visibleCount >= dates.length}
            onClick={handlerCountRight}
            size="small"
            style={{ width: '100%' }}
          >
            <ChevronRightIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CarrouseDates;
