import { Button, Stack } from '@mui/material';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import styled from '@emotion/styled';
import { useState } from 'react';
import { Box } from '@mui/system';
import { t } from 'i18next';
import FiltersBottomSheet, {
  FiltersBottomSheetProps
} from './FiltersBottomSheet';

interface FiltersProps
  extends Omit<FiltersBottomSheetProps, 'isOpen' | 'setIsOpen'> {
  handleOnClear: () => void;
  useFilteredData: boolean;
}

const FiltersButton = styled(Button)(() => ({
  display: 'flex',
  fontWeight: 'bold',
  gap: '10px'
}));

function Filters({
  reportRanges,
  handleDateChange,
  useFilteredData,
  handleOnClear,
  handleApply
}: FiltersProps) {
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);

  const handleClickFilters = () => {
    setIsOpenFilters(!isOpenFilters);
  };

  const handleOnApply = () => {
    handleApply();
    setIsOpenFilters(false);
  };

  return (
    <Box sx={{ marginBottom: '16px' }}>
      <Stack direction="row" spacing="8px">
        <FiltersButton onClick={handleClickFilters} variant="outlined">
          <FilterListRoundedIcon />
          {t('TIMESHEET.filters')}
        </FiltersButton>
        {useFilteredData && (
          <Button color="success" onClick={handleOnClear} variant="text">
            {t('TIMESHEET.clear')}
          </Button>
        )}
      </Stack>
      <FiltersBottomSheet
        handleApply={handleOnApply}
        handleDateChange={handleDateChange}
        isOpen={isOpenFilters}
        reportRanges={reportRanges}
        setIsOpen={setIsOpenFilters}
      />
    </Box>
  );
}

export default Filters;
