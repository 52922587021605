import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControlLabel,
  FormGroup,
  SlideProps,
  styled,
  Typography,
  Checkbox
} from '@mui/material';
import { Box, Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import useAuthStore from '../../store/zustand/auth';
import RegularInput from '../input/RegularInput';
import api from '../../api';
import { removeLocalStorage } from '../../utils/authHelper';

interface Props {
  isMobile: boolean;
  setCurrentProcess: React.Dispatch<React.SetStateAction<string>>;
}

const ButtonContainer = styled(Container)<SlideProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2)
}));

const MFA: React.FC<Props> = function MFA({ isMobile, setCurrentProcess }) {
  const { t } = useTranslation();
  const userState = useAuthStore((state) => state);
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(true);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [shouldProfile, setShouldProfile] = useState<boolean>(false);
  const [fireSendCode, setFireSendCode] = useState<boolean>(true);
  const [fireApiConfirmCode, setFireApiConfirmCode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const { mutate } = useSWRConfig();

  const { data: dataResendCode, error: errorResendCode } = useSWR(
    fireSendCode ? '/mfaSendCode' : null,
    api.authApi.sendCodeMFA
  );

  const { data: dataConfirmCode, error: errorConfirmCode } = useSWR(
    fireApiConfirmCode ? { code, rememberMe } : null,
    api.authApi.authMFA
  );

  const { data: profile, error: errorProfile } = useSWR(
    shouldProfile ? 'api/profile' : null,
    api.authApi.getProfile
  );

  // Clear cache when resend, & re-login with same User.
  const clearMfaCode = () => mutate('/mfaSendCode', null, true);
  const clearProfile = () => mutate('api/profile', null, true);
  const clearConfirmCode = () => mutate({ code, rememberMe }, null, true);

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  const logout = () => {
    removeLocalStorage('accessToken');
    userState.setUser({
      active: false,
      block: false,
      email: null,
      id: false,
      mfa: false,
      roles: []
    });
    setCurrentProcess('login');
  };

  const checkBoxChecked = (event: any) => {
    setRememberMe(event.target.checked);
  };

  const verifyButton = () => {
    if (code === '') {
      setError(true);
      setErrorMessage(`${t('GENERAL.inputRequired')}`);
      return;
    }
    clearConfirmCode();
    setFireApiConfirmCode(true);
  };

  const login = () => {
    if (code === '') {
      setError(true);
      return;
    }
    if (!error) {
      if (profile) {
        const {
          roles,
          email,
          mfa,
          block,
          active,
          firstName,
          phoneNumber,
          id,
          timeFormat,
          company
        } = profile;
        const { plan } = company;
        const rolesName = roles.map((ele: any) => ele.name);
        userState.setUser({
          active,
          block,
          email,
          firstName,
          id,
          mfa,
          phoneNumber,
          plan,
          roles: rolesName,
          timeFormat
        });
        userState.login();
        navigate('/app');
      }
    }
  };

  const resendCode = () => {
    clearMfaCode();
    setFireSendCode(true);
  };

  useEffect(() => {
    if (profile) {
      setShouldProfile(false);
      login();
    }
  }, [profile]);

  useEffect(() => {
    setError(false);
    setErrorMessage('');
  }, []);

  useEffect(() => {
    if (dataResendCode && dataResendCode.status === 201) {
      setFireSendCode(false);
    }
  }, [dataResendCode]);

  useEffect(() => {
    if (dataConfirmCode) {
      clearProfile();
      setShouldProfile(true);
    }
  }, [dataConfirmCode]);

  useEffect(() => {
    if (errorResendCode || errorConfirmCode || errorProfile) {
      setFireApiConfirmCode(false);
      setFireSendCode(false);
      setShouldProfile(false);
      setError(true);
      setErrorMessage(`${t('LOGIN.mfaError')}`);
      if (errorConfirmCode) {
        const { response } = errorConfirmCode;
        if (response.status === 401) {
          setErrorMessage(response.statusText);
          setTimeout(() => {
            setCurrentProcess('login');
          }, 5000);
        }
      }
    }
  }, [errorResendCode, errorConfirmCode]);

  return (
    <Container sx={{ alignItems: 'flex-start !important' }}>
      <Typography
        color="surface.main"
        sx={{ fontSize: 16 }}
        variant="primary-bold"
      >
        {t('LOGIN.mfaTitle')}
      </Typography>
      <br />
      <Typography color="surface.main" sx={{ fontSize: 16 }} variant="primary">
        {t('LOGIN.mfaDescription')}
      </Typography>
      <br />
      <Typography
        color="surface.main"
        sx={{ fontSize: 16, mb: 2, mt: 2 }}
        variant="primary-bold"
      >
        {userState.email}
      </Typography>
      <br />
      <Typography
        color={`${error ? 'alert.main' : 'mainText.main'}`}
        variant="primary-bold"
      >
        {t('LOGIN.mfaCode')}
      </Typography>
      <RegularInput
        required
        customStyle={{ width: '100%' }}
        error={error}
        inputInvalid={setError}
        onChange={setCode}
      />
      {getError()}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <FormGroup sx={{ mb: 1, mt: 1 }}>
          <FormControlLabel
            control={<Checkbox />}
            defaultChecked={rememberMe}
            label={
              <Typography color="secondary.main" variant="primary-bold">
                {t('LOGIN.rememberDevice')}
              </Typography>
            }
            onChange={checkBoxChecked}
          />
        </FormGroup>
      </Box>
      <ButtonContainer
        sx={{ flexDirection: isMobile ? 'column' : 'row !important' }}
      >
        <>
          <Button
            onClick={() => resendCode()}
            sx={{ width: '100%' }}
            variant="outlined"
          >
            <Typography variant="primary-bold">
              {t('LOGIN.mfaResendCode')}
            </Typography>
          </Button>
          <Box sx={{ height: 16, width: 24 }} />
          <Button
            color="primary"
            onClick={() => verifyButton()}
            sx={{ width: '100%' }}
            variant="contained"
          >
            <Typography variant="primary-bold">
              {t('GENERAL.verifyButton')}
            </Typography>
          </Button>
        </>
      </ButtonContainer>
      <ButtonContainer
        sx={{ flexDirection: isMobile ? 'column' : 'row !important' }}
      >
        <Button
          onClick={() => logout()}
          sx={{ width: '100%' }}
          variant="cancel"
        >
          <Typography variant="primary-bold">{t('MODAL.cancel')}</Typography>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default MFA;
