import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';

interface DescriptionProps {
  useFilteredData: boolean;
}

function Description({ useFilteredData }: DescriptionProps) {
  return (
    <Stack spacing="8px">
      <Typography component="p" variant="heading_400">
        {useFilteredData
          ? t('TIMESHEET.timesheetReport')
          : t('TIMESHEET.timesheetReview')}
      </Typography>
      <Typography color="secondary.main" component="p" variant="body_300">
        {t('TIMESHEET.timesheetDescription')}
      </Typography>
    </Stack>
  );
}

export default Description;
