/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Box, Container, useTheme } from '@mui/system';
import {
  Button,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  styled,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { isMobile } from 'react-device-detect';
import CounterOfferCard from './CounterOfferCard';
import api from '../../../../../api';
import useNavigationStore from '../../../../../store/zustand/navigation';
import ToastNotification from '../../../../../components/shared/ToastNotification';

interface Props {
  clientView?: boolean;
  mutate?: () => void;
  onClose: () => void;
  serviceTimes: Array<any>;
  token?: string;
}

const CounterOfferMain: React.FC<Props> = function CounterOfferMain({
  serviceTimes,
  onClose,
  token,
  clientView,
  mutate
}) {
  // =========================================================================== VARIABLES

  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore();
  const theme = useTheme();
  const [active, setActive] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [services, setServices] = useState<Array<any>>([...serviceTimes]);
  const [deleteServiceTime, setDeleteServiceTime] = useState<Array<any>>([]);
  const [recalculateApi, setRecalculateApi] = useState<boolean>(false);
  const [submitApi, setSubmitApi] = useState<boolean>(false);
  const [toastNotification, setToastNotification] = useState<any>(undefined);

  // =========================================================================== SWR

  const { data: counterOfferData, error: counterOfferError } = useSWR(
    recalculateApi || submitApi
      ? {
          deleted: deleteServiceTime,
          notModified: [],
          services,
          token,
          url: 'client/proposal/recalculate/submit'
        }
      : null,
    recalculateApi
      ? api.ProposalApi.counterOfferRecalculate
      : api.ProposalApi.counterOfferSubmit
  );

  // =========================================================================== FUNCTIONS

  const removeServiceTime = () => {
    setLoading(true);
    setDeleteServiceTime([...deleteServiceTime, serviceTimes[active].id]);
    const servicesTemp = services;
    servicesTemp.splice(active, 1);
    setServices(servicesTemp);
    if (active !== 0) {
      setActive(active - 1);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const resetAllData = () => {
    setLoading(true);
    setServices([...serviceTimes]);
    setDeleteServiceTime([]);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // =========================================================================== LIFECYCLE

  useEffect(() => {
    if (!Number.isNaN(active)) {
      setLoading(true);
    }
    if (services[active]) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [active]);

  useEffect(() => {
    if (counterOfferData) {
      if (!recalculateApi) {
        onClose();
        if (mutate) {
          mutate();
        }
      }
      setRecalculateApi(false);
      setSubmitApi(false);
      const data = counterOfferData?.values?.counterOfferData;
      setServices(
        services.map((service: any) => {
          let index = -1;
          data?.forEach((item: any, i: number) => {
            if (service.id === item.serviceTimeId) {
              index = i;
            }
          });

          if (index >= 0) {
            return {
              ...service,
              actionToCounterOffer: data[index].actionToCounterOffer,
              availability: {
                ...service?.availability,
                generalInfo: {
                  ...service?.availability?.generalInfo,
                  daysOfServiceCount: data[index].numberOfVisits
                }
              },
              sellPrice: data[index].pricePerVisit
            };
          }
          return service;
        })
      );
    }
  }, [counterOfferData]);

  useEffect(() => {
    if (counterOfferError) {
      const error =
        counterOfferError?.response?.data?.message ||
        t('NOTIFICATION.TOAST.generalError');
      setToastNotification({ text: error, type: 'error' });
      setRecalculateApi(false);
      setSubmitApi(false);
    }
  }, [counterOfferError]);

  // =========================================================================== RENDER

  return (
    <Box>
      {services?.length > 0 && (
        <Box>
          <Tabs
            aria-label="basic tabs example"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setActive(newValue);
            }}
            value={active}
          >
            {services?.map((serviceTime, index) => (
              <Tab
                key={`${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')}-${
                  index + 1
                }`}
                label={`${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')} #${
                  index + 1
                }`}
              />
            ))}
          </Tabs>
          <Typography variant="primary">
            <CounterOfferCard
              clientView={clientView}
              deleteServiceTime={removeServiceTime}
              resetValues={() => {
                resetAllData();
              }}
              serviceTime={services[active]}
              setRecalculateApi={() => {
                setRecalculateApi(true);
              }}
              setServiceTime={(serviceTime: any) => {
                const temp = services;
                temp[active] = serviceTime;
                setServices([...temp]);
              }}
            />
            {clientView && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: 2,
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'space-between',
                    width: isMobile ? '100%' : '65%'
                  }}
                >
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                    sx={{ width: 200 }}
                    variant="cancel"
                  >
                    {t('GENERAL.cancelButton')}
                  </Button>
                  <Button
                    onClick={() => resetAllData()}
                    sx={{ width: 200 }}
                    variant="primary-outlined"
                  >
                    {t('GENERAL.resetButton')}
                  </Button>
                </Box>
                <Button
                  onClick={() => {
                    setConfirmModal(
                      () => {
                        setSubmitApi(true);
                      },
                      t('GENERAL.confirmButton'),
                      () => {},
                      '',
                      <Box
                        sx={{
                          alignItems: 'end',
                          display: 'flex',
                          height: 50,
                          justifyContent: 'center'
                        }}
                      >
                        <Typography
                          color="primary"
                          fontSize={24}
                          variant="heading_400"
                        >
                          {t('LEAD.CREATE.PROPOSAL.confirmSendCounterOffer')}
                        </Typography>
                      </Box>
                    );
                  }}
                  sx={{ width: isMobile ? '100%' : 200 }}
                  variant="primary"
                >
                  {t('GENERAL.submitButton')}
                </Button>
              </Box>
            )}
          </Typography>
        </Box>
      )}
      {(clientView && !services) ||
        (services?.length === 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => resetAllData()}
              sx={{ width: 150 }}
              variant="primary"
            >
              {t('GENERAL.resetButton')}
            </Button>
          </Box>
        ))}
      {toastNotification && (
        <ToastNotification
          onClose={() => setToastNotification(undefined)}
          text={toastNotification.text}
          type={toastNotification.type}
        />
      )}
    </Box>
  );
};

CounterOfferMain.defaultProps = {
  clientView: false,
  mutate: undefined,
  token: ''
};

export default CounterOfferMain;
