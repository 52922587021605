import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SliderProps,
  BoxProps,
  Modal,
  Backdrop,
  TextField,
  TextFieldProps
} from '@mui/material';
import useSWR from 'swr';
import Api from '../../api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import useAuthStore from '../../store/zustand/auth';
import Loader from '../shared/Loader';
import {
  secondaryColors,
  themeFont,
  themePalette
} from '../../theme/themeConfig';

const ModalContainer = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow:
    '0px 4px 20px rgba(0, 0, 0, 0.1), 0px 6px 10px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  height: 'auto',
  maxHeight: 500,
  overflow: 'auto',
  padding: '40px 60px',
  position: 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 600
}));

const CustomTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    color: secondaryColors.SECONDARY_3,
    fontFamily: themeFont.FONT_SECONDARY,
    fontSize: '16px',
    fontWeight: 400
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${secondaryColors.SECONDARY_1}`
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderStyle: 'hidden'
  },
  backgroundColor: themePalette.BACKGROUND,
  paddingLeft: 0,
  paddingRight: 0
}));

const BoxCentered = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '100%',
  justifyContent: 'center'
}));

interface Props {
  id: string;
  mutate: () => void;
  roles: Array<string>;
  setShowModal: (arg0: boolean) => void;
  type: string;
  watchers: Array<any>;
}

const WatchersModal: React.FC<Props> = function WatchersModal({
  id,
  type,
  watchers: w,
  mutate,
  setShowModal,
  roles
}) {
  const { t } = useTranslation();
  const { id: profileId } = useAuthStore((state) => state);
  const [mainAction, setMainAction] = useState<boolean>(false);
  const [updateAction, setUpdateAction] = useState<boolean>(false);
  const [roleUsers, setRoleUsers] = useState<any>();
  const [error, setError] = useState<string>();
  const [valuesAutoComplete, setValuesAutoComplete] = useState<any>();
  const [profile, setProfile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [watchers, setWatchers] = useState<Array<any>>(w || []);

  const getUpdate = () => {
    return Api.ProposalApi.updateWatchers;
  };

  const getRole = () => {
    return Api.UserResocesApi.getUsersByRole;
  };

  const { data: mainResponse, error: mainError } = useSWR(
    mainAction ? { roles, url: 'get/users/roles' } : null,
    getRole()
  );

  const { data: updateResponse, error: updateError } = useSWR(
    updateAction ? { id, watchers: valuesAutoComplete } : null,
    getUpdate()
  );

  const addMeAsWatcher = () => {
    setLoading(true);
    setWatchers(
      Array.isArray(valuesAutoComplete)
        ? [...valuesAutoComplete, profile]
        : [profile]
    );
    setValuesAutoComplete(undefined);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const checkMeAsWatcher = () => {
    let watcher = false;
    if (Array.isArray(valuesAutoComplete)) {
      if (valuesAutoComplete?.length > 0) {
        valuesAutoComplete.forEach((item: any) => {
          if (item.id === profile?.id) {
            watcher = true;
          }
        });
      }
    } else if (valuesAutoComplete?.id === profileId) {
      watcher = true;
    }
    return watcher;
  };

  const getAddMeWatcher = () => {
    if (true) {
      return '';
    }
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          width: '100%'
        }}
      >
        <Button
          disabled={checkMeAsWatcher()}
          onClick={() => {
            addMeAsWatcher();
          }}
          variant="text"
        >
          <i className="custom-icon icon-add" style={{ fontSize: 18 }} />
          <Typography>{t('MODAL.addMe')}</Typography>
        </Button>
      </Box>
    );
  };

  useEffect(() => {
    if (mainResponse) {
      setMainAction(false);
      setRoleUsers(
        mainResponse.map((item: any) => {
          const userName = `${item.firstName} ${item.lastName}`;
          const userEmail = `${item.email}`;
          const label = `${userName} (${userEmail})`;
          const w: any = {
            ...item,
            label,
            name: label,
            userEmail,
            userId: id,
            userName,
            value: id
          };
          if (item.id === profileId) {
            setProfile(w);
          }
          return w;
        })
      );
    }
  }, [mainResponse]);

  useEffect(() => {
    if (roleUsers) {
      let valuesToSet: any = [];
      roleUsers.forEach((item: any) => {
        watchers.forEach((watcher: any) => {
          if (item.id === watcher.userId) {
            valuesToSet = item;
          }
        });
      });
      setValuesAutoComplete(valuesToSet);
    }
  }, [roleUsers]);

  useEffect(() => {
    if (mainError) {
      setMainAction(false);
      const message = mainError?.response?.data?.message || t('ERROR.general');
      setError(message);
    }
  }, [mainError]);

  useEffect(() => {
    if (updateResponse) {
      setUpdateAction(false);
      mutate();
      setShowModal(false);
    }
  }, [updateResponse]);

  useEffect(() => {
    if (updateError) {
      setUpdateAction(false);
    }
  }, [updateError]);

  useEffect(() => {
    setMainAction(true);
  }, []);

  return (
    <Container sx={{ margin: '40px', width: 'auto' }}>
      <Modal
        open
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ModalContainer>
          {error && (
            <BoxCentered>
              <Typography color="error.main" variant="heading_400">
                {t('ERROR.errorTitle')}
              </Typography>
              <Typography variant="primary">{error}</Typography>
            </BoxCentered>
          )}

          {!error && (
            <>
              <Box
                color="secondary.400"
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <i className="custom-icon icon-eye" style={{ fontSize: 40 }} />
                <Typography color="secondary.400" variant="modalTitle">
                  {t('MODAL.watchers')}
                </Typography>
              </Box>
              {!roleUsers && <Loader customColor="primary" customSize={36} />}
              {roleUsers && (
                <Box display="flex" flexDirection="column" gap={2}>
                  {loading && <Loader customColor="primary" customSize={24} />}
                  {!loading && (
                    <>
                      <AutoCompleteInput
                        multiple
                        defaultValue={watchers.map((item: any) => {
                          const label = `${item.userName} (${item.userEmail})`;
                          return {
                            ...item,
                            label,
                            name: label,
                            value: item.userId
                          };
                        })}
                        onChange={(event: any, newValue: any | null) => {
                          setValuesAutoComplete(newValue);
                        }}
                        options={roleUsers}
                        renderInput={(params) => (
                          <CustomTextField
                            // eslint-disable-next-line
                            {...params}
                          />
                        )}
                        sx={{ margin: 0, minWidth: 300 }}
                        value={valuesAutoComplete}
                      />
                      {/* {getAddMeWatcher()} */}
                    </>
                  )}

                  <Box
                    sx={{
                      alignSelf: 'center',
                      display: 'flex'
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        setUpdateAction(true);
                      }}
                      sx={{ height: 46, width: 120 }}
                      variant="contained"
                    >
                      <Typography variant="heading_328">
                        {t('GENERAL.confirmButton')}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </ModalContainer>
      </Modal>
    </Container>
  );
};

WatchersModal.defaultProps = {};

export default WatchersModal;
