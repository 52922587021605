import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import {
  Box,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import Stats from '../../components/technicianTimesheet/Stats';
import api from '../../api';
import ViewLoader from '../../components/shared/ViewLoader';
import useNavigationStore from '../../store/zustand/navigation';
import useAuthStore from '../../store/zustand/auth';
import Description from '../../components/technicianTimesheet/Description';
import Filters from '../../components/technicianTimesheet/Filters';
import RateSummary from '../../components/technicianTimesheet/RateSummary';
import Leads from '../../components/technicianTimesheet/Leads';
import { decimalsToHours } from '../../utils/calendarHelper';

const PageContainer = styled(Stack)(() => ({
  backgroundColor: '#FFF'
}));

const PageDivider = styled(Divider)(() => ({
  backgroundColor: '#DDDDDD',
  height: '1px'
}));

const PaddingContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px !important'
}));

const NewTimesheetTechnician: React.FC = function Timesheet() {
  const [getTaskToReview, setGetTaskToReview] = useState(false);
  const [taskToReviewData, setTaskToReviewData] = useState<any>([]);
  const [getReportData, setGetReportData] = useState(false);
  const [reportData, setReportData] = useState<any>({});
  const [totals, setTotals] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [useFilteredData, setUseFilteredData] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const userState = useAuthStore((state) => state);

  const [reportRanges, setReportRanges] = useState<any>({
    fromDate: null,
    fromDateFormated: '',
    toDate: null,
    toDateFormated: ''
  });

  const { data: taskToReviewDataResult, error: getTaskToReviewDataError } =
    useSWR(
      getTaskToReview
        ? {
            timeFormat: userState?.timeFormat,
            url: 'api/timesheet/taskToReviewForOperator'
          }
        : null,
      api.TimesheetApi.getTechnicianTaskToReviewForOperator
    );

  const {
    data: reportDataResult,
    error: getReportDataError,
    mutate: mutateReport,
    isValidating: isReportDataValidating
  } = useSWR(
    getReportData
      ? {
          fromDate: reportRanges.fromDateFormated,
          timeFormat: userState?.timeFormat,
          toDate: reportRanges.toDateFormated
        }
      : null,
    api.TimesheetApi.getTechnicianTimesheetTasks
  );

  const getCurrentData = () => {
    if (useFilteredData && reportData[0]) {
      return reportData[0];
    }
    return taskToReviewData[0];
  };

  //= ================= FUNCTIONS =============================

  const handleDateChange = (newDate: any, opt: number) => {
    if (newDate !== null && `${newDate?.$y}` !== 'NaN') {
      switch (opt) {
        case 5: {
          setReportRanges({
            ...reportRanges,
            fromDate: newDate,
            fromDateFormated: newDate
              ? `${newDate.format('YYYY-MM-DD')}T00:00:00`
              : ''
          });
          break;
        }
        case 6: {
          setReportRanges({
            ...reportRanges,
            toDate: newDate,
            toDateFormated: newDate
              ? `${newDate.format('YYYY-MM-DD')}T23:59:59`
              : ''
          });
          break;
        }
        default:
          break;
      }
    }
  };

  const handleError = () => {
    setConfirmModal(
      () => {
        console.log('handleError');
      },
      `${t('GENERAL.confirmButton')}`,
      () => {},
      ``,
      <Box display="flex" flexDirection="column" pl={8} pr={8}>
        <Box p={2}>
          <i
            className="custom-icon icon-info"
            style={{
              color: theme.palette.error.main,
              fontSize: 48
            }}
          />
        </Box>
        <Typography variant="primary">{`${t('TIMESHEET.error')}`}</Typography>
      </Box>
    );
  };

  //= ================= USE_EFFECTS =============================

  useEffect(() => {
    setIsLoading(true);
    setGetTaskToReview(true);
  }, []);

  useEffect(() => {
    if (taskToReviewDataResult && !getTaskToReviewDataError) {
      setIsLoading(false);
      setGetTaskToReview(false);
      setTaskToReviewData(taskToReviewDataResult);
    }
  }, [taskToReviewDataResult]);

  useEffect(() => {
    if (getTaskToReviewDataError) {
      setIsLoading(false);
      handleError();
    }
  }, [getTaskToReviewDataError]);

  useEffect(() => {
    if (reportDataResult && !getReportDataError) {
      setIsLoading(false);
      setGetReportData(false);
      setReportData(reportDataResult);
    }
  }, [reportDataResult]);

  useEffect(() => {
    if (getReportDataError) {
      setIsLoading(false);
      handleError();
    }
  }, [getReportDataError]);

  const calculateTotalsByStatus = (dataList: any) => {
    const totals = {
      underReview: { amount: 0, hours: 0 }
    };

    dataList.forEach((item: any) => {
      if (item.status === 'under review') {
        totals.underReview.hours += item.actualTotal;
        totals.underReview.amount += parseFloat(item.laborTotalCost);
      }
    });

    return totals;
  };

  useEffect(() => {
    const currentData = getCurrentData();
    if (currentData?.dataList) {
      setTotals(calculateTotalsByStatus(currentData.dataList));
    }
  }, [taskToReviewData, reportData, useFilteredData]);

  if (isLoading || !taskToReviewData || isReportDataValidating) {
    return <ViewLoader />;
  }

  const currentData = getCurrentData();

  return (
    <PageContainer
      id="calendar-page-container"
      key="timesheet-container"
      spacing="16px"
    >
      <PaddingContainer>
        <Description useFilteredData={useFilteredData} />
      </PaddingContainer>
      <PageDivider />
      <PaddingContainer>
        <Filters
          handleApply={() => {
            setUseFilteredData(true);
            setGetReportData(true);
            mutateReport(null, false);
          }}
          handleDateChange={handleDateChange}
          handleOnClear={() => setUseFilteredData(false)}
          reportRanges={reportRanges}
          useFilteredData={useFilteredData}
        />
        <Stats
          hoursWorked={{
            amount: decimalsToHours(currentData?.totalHoursWorked) || 0,
            total: currentData?.totalHoursScheduled || 0
          }}
          tasks={currentData?.dataList?.length || 0}
        />
        <RateSummary
          defaultExpanded
          hourlyRate={currentData?.hourlyRate || 0}
          title={t('TIMESHEET.payment')}
          total={currentData?.technicianPaymentTotal.toFixed(2) || 0}
          totalHours={decimalsToHours(currentData?.totalHoursWorked) || 0}
        />

        {totals?.underReview?.amount > 0 && (
          <RateSummary
            showIcon
            hourlyRate={currentData.hourlyRate || 0}
            title={t('TIMESHEET.underReview')}
            total={totals.underReview.amount}
            totalHours={totals.underReview.hours}
          />
        )}
      </PaddingContainer>

      <Leads leads={currentData?.dataList} />
    </PageContainer>
  );
};

export default NewTimesheetTechnician;
