import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { ServiceCollapse } from '../components/ServiceCollapse';
import TimeComponent from '../../../../components/input/TimeComponent';
import Api from '../../../../api';

interface Props {
  saveStepper: React.Dispatch<React.SetStateAction<any>>;
  setServiceTimeUpdate: React.Dispatch<React.SetStateAction<any>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ManualPricing: React.FC<Props> = function ManualPricing({
  values,
  saveStepper,
  setServiceTimeUpdate,
  setValues
}) {
  const { t } = useTranslation();
  const [services, setServices] = useState<Array<any>>([]);
  const [valuesServTime, setValuesServTime] = useState<any>();
  const [fireSave, setFireSave] = useState<boolean>(false);

  const fetcher = (...args: any[]) =>
    Api.LeadServiceTimesApi.updateLeadServicesTime(
      values.id,
      values.serviceTimes[0].id,
      {
        ...values?.serviceTimes[0],
        id: values.serviceTimes[0].id
      }
    ).then((response) => {
      return response;
    });

  const { data: dataSaveResult, error } = useSWR(
    `${fireSave}` === 'true'
      ? [
          values.id,
          values.serviceTimes[0].id,
          {
            ...values.serviceTimes[0],
            id: values.serviceTimes[0].id,
            type: values.type
          }
        ]
      : null,
    fetcher
  );

  useEffect(() => {
    if (dataSaveResult) {
      setFireSave(false);
      const newAuxServiceTimes = values.serviceTimes;
      newAuxServiceTimes[0] = dataSaveResult;

      setValues({
        ...values,
        serviceTimes: newAuxServiceTimes
      });
      saveStepper({ next: 2 });
    }
  }, [dataSaveResult]);

  useEffect(() => {
    const { serviceTimes } = values || {};
    if (values && serviceTimes && serviceTimes[0] && serviceTimes[0].services) {
      setServices(serviceTimes[0].services);
      setValuesServTime(serviceTimes[0]);
    }
  }, [values]);

  function Buttons() {
    return (
      <Box
        pt={4}
        sx={{
          display: 'flex',
          float: 'right',
          justifyContent: 'space-between',
          width: 300
        }}
      >
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            saveStepper({ next: 0 });
          }}
          sx={{ m: 1 }}
          variant="cancel"
        >
          {t('GENERAL.backButton')}
        </Button>
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            setValues({ ...values, serviceTimes: [valuesServTime] });
            setFireSave(true);
            // saveStepper({ next: 2 });
          }}
          sx={{ m: 1 }}
          variant="primary"
        >
          {t('GENERAL.nextButton')}
        </Button>
      </Box>
    );
  }

  const getHeader = () => {
    return (
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="title">
            {t('LEAD.CREATE.SERVICES.MANUAL_PRICING.title')}
          </Typography>
          <Typography color="surface.main" variant="body_200">
            {t('LEAD.CREATE.SERVICES.MANUAL_PRICING.subtitle')}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {values.serviceTimes && values.serviceTimes[0] && (
            <Box alignItems="center" display="flex" gap={1}>
              <Box display="flex" flexDirection="column" width={200}>
                <Typography variant="primary">
                  {t('LEAD.CREATE.SERVICES.travelTime')}:
                </Typography>
                <Typography variant="body_100">
                  {`(${t('LEAD.CREATE.SERVICES.roundTrip')})`}
                </Typography>
              </Box>
              <TimeComponent
                disabled={values?.readOnly}
                setTime={(travelTime) =>
                  setValues({
                    ...values,
                    serviceTimes: [{ ...values.serviceTimes[0], travelTime }]
                  })
                }
                time={
                  values?.serviceTimes[0]?.travelTime &&
                  values.serviceTimes[0].travelTime > 0
                    ? values.serviceTimes[0].travelTime
                    : 0
                }
              />
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width={350}
          >
            <Box textAlign="right">
              <Typography color="secondary.400" variant="body_200">
                {`${t('LEAD.CREATE.SERVICES.MANUAL_PRICING.address')}: ${
                  values?.address?.address
                    ? values.address.address
                    : t('LEAD.CREATE.SERVICES.MANUAL_PRICING.noData')
                }`}
              </Typography>
            </Box>
            <Box textAlign="right">
              <Typography color="secondary.400" variant="body_200">
                {`${t('LEAD.CREATE.SERVICES.MANUAL_PRICING.phone')}: ${
                  values?.contact?.phoneNumber
                    ? values.contact.phoneNumber
                    : t('LEAD.CREATE.SERVICES.MANUAL_PRICING.noData')
                }`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={4}>
        {getHeader()}
        {services &&
          services.length > 0 &&
          services.map((service: any, index: number) => {
            return (
              <ServiceCollapse
                openInit
                editableFields={['time', 'name']}
                headKeys={['name', 'time', 'delete']}
                manualPricing={values.manualPricing}
                screen="services"
                service={{ ...service, readOnly: values?.readOnly }}
                setService={(newValue: any) => {
                  const newService = valuesServTime.services;
                  newService[index] = newValue;
                  setValuesServTime({
                    ...valuesServTime,
                    services: newService
                  });
                }}
              />
            );
          })}
      </Box>
      {Buttons()}
    </Box>
  );
};

export default ManualPricing;
