import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import LeadSummary from './LeadSummary';
import { themePalette } from '../../theme/themeConfig';
import { formatSumTime } from '../../utils/calendarHelper';

interface LeadsProps {
  leads: any;
}

const LeadsContainer = styled(Box)(() => ({
  backgroundColor: themePalette.BACKGROUND,
  height: '100%',
  margin: '16px',
  padding: '16px 24px',
  width: '100%'
}));

const LeadsEmpyStateContainer = styled(Box)(() => ({
  display: 'grid',
  margin: '16px 0px',
  placeItems: 'center',
  width: '100%'
}));

function LeadsEmptyState() {
  return (
    <LeadsEmpyStateContainer>
      <Typography color="mainText.main" variant="body_200">
        {t('TIMESHEET.leadsEmptyState')}
      </Typography>
    </LeadsEmpyStateContainer>
  );
}

function Leads({ leads }: LeadsProps) {
  return (
    <LeadsContainer>
      {leads && leads?.length >= 1 && Array.isArray(leads) ? (
        leads.map((item: any) => {
          const formatTime = (time: string) => {
            return new Date(`2024-01-01T${time}`)
              .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
              .toLowerCase()
              .replace(' ', '');
          };

          const formatVariance = (variance: number) => {
            const hours = Math.abs(variance) / 60;
            return `${variance < 0 ? '-' : '+'} ${hours.toFixed(2)}`;
          };
          return (
            <LeadSummary
              key={item.id}
              leadName={item.companyName ?? item.projectName}
              status={item.status}
              subtitle={`${item.day} at ${item.actualStart}`}
              workedHours={{
                actualEnd: item.actualEnd,
                actualStart: item.actualStart,
                scheduled: item.scheduledTotal,
                variance: formatSumTime(item.variance)
              }}
            />
          );
        })
      ) : (
        <LeadsEmptyState />
      )}
    </LeadsContainer>
  );
}

export default Leads;
