/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Container,
  Drawer,
  Toolbar,
  Typography,
  IconButton
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import useSWR from 'swr';
import {
  Accordion,
  TitleTab,
  AccordionDetails,
  AccordionSummary,
  ItemTab
} from './parts';
import useOrderTabByRole from '../../../hooks/useOrderTabByRole';
import MenuComponent from '../Menu';
import useAuthStore from '../../../store/zustand/auth';
import api from '../../../api';

const MobileSidebar: React.FC = function MobileSidebar() {
  const [expanded, setExpanded] = useState<string | false>('');
  const [currentCompany, setCurrentCompany] = useState<any>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const userState = useAuthStore((state) => state);
  const menuTabs = useMemo(
    () => useOrderTabByRole(userState, currentCompany),
    [userState, currentCompany]
  );
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const { data: company } = useSWR(
    !currentCompany ? 'api/currentCompany' : null,
    api.CompanyApi.getCurrentCompany
  );

  useEffect(() => {
    if (company) {
      setCurrentCompany(company);
    }
  }, [company]);

  return (
    <Container>
      <Box
        component="nav"
        sx={{
          backgroundColor: 'primary.main',
          flexShrink: { sm: 0 },
          width: '100%'
        }}
      >
        {/* NavBar Section */}
        <Toolbar
          sx={{
            display: 'flex',
            height: '80px',
            justifyContent: 'space-between',
            marginLeft: '8px',
            marginRight: '0px',
            paddingRight: '0px'
          }}
        >
          <IconButton
            aria-label="menu"
            color="inherit"
            edge="start"
            onClick={() => {
              setOpenMenu(true);
            }}
            size="large"
            sx={{ color: 'white', mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography color="mainText.100" variant="h5">
            bluberry
          </Typography>
          <MenuComponent />
        </Toolbar>
        {/* Menu Options */}
        <Drawer
          open={openMenu}
          sx={{
            '& .MuiDrawer-paper': {
              border: 'none',
              boxSizing: 'border-box',
              maxWidth: '100vw',
              minWidth: '100vw',
              width: '100vw'
            },
            display: { xs: 'block' }
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              height: '128px',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <IconButton
              aria-label="menu-close"
              color="inherit"
              edge="start"
              onClick={() => {
                setOpenMenu(false);
              }}
              size="large"
              sx={{ mr: 2 }}
            >
              <ClearIcon />
            </IconButton>
          </Toolbar>
          {/* HERE WILL GO ALL MENU OPTIONS MAPPED */}
          {/* TODO CurrentCompany should be improved */}
          <div>
            {(currentCompany || true) &&
              userState &&
              menuTabs.map((parentTab) => (
                <Accordion
                  expanded={expanded === parentTab.text}
                  key={parentTab.text}
                  onChange={handleChange(parentTab.text)}
                >
                  <AccordionSummary
                    aria-controls={`aria ${parentTab.text}`}
                    id={parentTab.text}
                    sx={{
                      backgroundColor:
                        expanded === parentTab.text ? 'primary.400' : 'white',
                      minWidth: '90vw',
                      width: '90vw'
                    }}
                  >
                    <Box
                      sx={{
                        color:
                          expanded === parentTab.text
                            ? 'white'
                            : 'secondary.400',
                        marginLeft: '0px'
                      }}
                    >
                      <i className={`custom-icon icon-${parentTab.icon}`} />
                    </Box>
                    {parentTab.route ? (
                      <NavLink
                        onClick={() => {
                          setOpenMenu(!openMenu);
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          maxWidth: '100vw',
                          textDecoration: 'none'
                        }}
                        to={`/app/${parentTab.route}`}
                      >
                        <TitleTab
                          sx={{
                            color:
                              expanded === parentTab.text
                                ? 'white'
                                : 'secondary.400',
                            maxWidth: '100vw'
                          }}
                        >
                          {parentTab.text}
                        </TitleTab>
                      </NavLink>
                    ) : (
                      <TitleTab
                        sx={{
                          color:
                            expanded === parentTab.text
                              ? 'white'
                              : 'secondary.400',
                          maxWidth: '100vw'
                        }}
                      >
                        {parentTab.text}
                      </TitleTab>
                    )}
                  </AccordionSummary>
                  {parentTab.children
                    ? parentTab.children.map((tabItem) => (
                        <AccordionDetails key={tabItem.text}>
                          <NavLink
                            onClick={() => {
                              setOpenMenu(!openMenu);
                            }}
                            style={{ textDecoration: 'none' }}
                            to={tabItem.route}
                          >
                            {({ isActive }) => (
                              <ItemTab
                                sx={{
                                  color: isActive
                                    ? 'primary.400'
                                    : 'secondary.400'
                                }}
                              >
                                {tabItem.text}
                              </ItemTab>
                            )}
                          </NavLink>
                        </AccordionDetails>
                      ))
                    : null}
                </Accordion>
              ))}
          </div>
        </Drawer>
      </Box>
    </Container>
  );
};

export default MobileSidebar;
