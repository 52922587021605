import React, { useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AutoCompleteSupervisor from '../../../../components/input/AutoCompleteSupervisor';
import { FormLabel } from '../../../../components/form/parts';
import RegularInput from '../../../../components/input/RegularInput';
import CustomRadio from '../../../../components/form/CustomRadio';
import { secondaryColors } from '../../../../theme/themeConfig';

interface Props {
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  setValuesST: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const AssignSupervisor: React.FC<Props> = function AssignSupervisor({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  setValuesST,
  values
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [addHours, setAddHours] = useState(false);
  const [assignSupervisorFlag, setassignSupervisorFlag] = useState<string>(
    values?.serviceTimes?.[index]?.assignSupervisor ? '0' : '1'
  );
  const [supervisorTime, setSupervisorTime] = useState<string>(
    values.serviceTimes[index]?.supervisorTime || '0'
  );

  const resetState = () => {
    setSupervisorTime('0');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setassignSupervisorFlag(value);
    const newServices = values.serviceTimes;

    if (value === '1') {
      newServices[index].supervisor = null;
      newServices[index].supervisors = null;
      newServices[index].assignSupervisor = false;
      newServices[index].supervisorTime = '0';
      resetState();
    } else {
      newServices[index].assignSupervisor = true;
    }

    setValues({ ...values, serviceTimes: newServices });
  };

  const handleHoursFieldDisable = () => {
    const disable = values?.serviceTimes[index]?.supervisor?.id
      ? false
      : !!values?.serviceTimes[index]?.supervisor?.id || !addHours;
    return values?.readOnly || disable;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="32px"
      key={`assign-supervisor-div-${index}`}
    >
      <Typography color={secondaryColors.SECONDARY_5} variant="secondary-semi">
        {t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorTitle')}
      </Typography>
      <Box display="flex" gap="40px" justifyContent="space-between">
        <RadioGroup onChange={handleChange} value={assignSupervisorFlag}>
          <Box display="flex" flexDirection="column" gap="24px">
            <FormControlLabel
              control={<CustomRadio />}
              disabled={values?.readOnly}
              label={
                <Typography
                  color={secondaryColors.SECONDARY_3}
                  variant="body_300"
                >
                  {t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisor')}
                </Typography>
              }
              sx={{ margin: 0 }}
              value="0"
            />
            <FormControlLabel
              control={<CustomRadio />}
              disabled={values?.readOnly}
              label={
                <Typography
                  color={secondaryColors.SECONDARY_3}
                  variant="body_300"
                >
                  {t('LEAD.CREATE.SERVICES.SCHEDULE.dontAssingSupervisor')}
                </Typography>
              }
              sx={{ margin: 0 }}
              value="1"
            />
          </Box>
        </RadioGroup>

        {assignSupervisorFlag === '0' && (
          <Box display="flex" flex={1} flexDirection="column" gap="4px">
            <Typography color="primary.main" variant="heading_300">
              {t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorAutocomplete')}
            </Typography>
            <AutoCompleteSupervisor
              disabled={values?.readOnly}
              multiple={false}
              setValues={(value) => {
                let auxServicesTimes = values.serviceTimes;
                auxServicesTimes[index].supervisor = value;
                setAddHours(!!value.id);
                if (!value.id) {
                  auxServicesTimes[index].supervisorTime = '0';
                }
                auxServicesTimes = {
                  ...auxServicesTimes[index]
                };
                setSupervisorTime('0');
                setValuesST(values);
              }}
              type="supervisor"
              values={values?.serviceTimes?.[index] || []}
            />
          </Box>
        )}

        {assignSupervisorFlag === '0' && (
          <Box
            display="flex"
            flexDirection="column"
            gap="4px"
            sx={{
              minWidth: '318px'
            }}
          >
            <FormLabel
              label={t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorHours')}
            />
            <RegularInput
              customStyle={{
                '& .MuiInputBase-input': { height: '40px' },
                margin: 0
              }}
              disabled={handleHoursFieldDisable()}
              onChange={(supervisorTime) => {
                let auxServicesTimes = { ...values.serviceTimes };
                auxServicesTimes[index].supervisorTime = supervisorTime;
                auxServicesTimes = {
                  ...auxServicesTimes[index]
                };
                setSupervisorTime(supervisorTime);
                setValuesST(values);
              }}
              type="number"
              value={supervisorTime}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AssignSupervisor;
