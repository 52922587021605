import React, { useEffect } from 'react';
import '../../style/App.scss';
import { Box, Button, SliderProps, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Title } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useNavigationStore from '../../store/zustand/navigation';
import logo from '../../assets/images/Bluberry_Logo.png';
import useAuthStore from '../../store/zustand/auth';

const MainContainer = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  paddingBottom: 50,
  width: '100%'
}));

const HeaderContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  width: '100%'
}));
const NoAuthorized: React.FC = function NoAuthorized() {
  const navigation = useNavigationStore((state) => state);
  const userState = useAuthStore((state) => state);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // ====================================================== LIFECYCLE
  useEffect(() => {
    navigation.setCurrentStepScreen('noAuthorized');
  }, []);

  const handleLogOut = () => {
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  return (
    <Box
      alignItems="center"
      bgcolor="#f9f9f9"
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="flex-start"
      padding={4}
      textAlign="center"
    >
      <img
        alt="logo"
        className="logo-image"
        src={logo}
        style={{ marginBottom: '100px', width: '150px' }}
      />
      <ErrorOutlineIcon sx={{ color: 'red', fontSize: 80, mb: 2 }} />
      <Typography variant="heading_400">
        {t('NO_AUTHORIZED.accessDenied')}
      </Typography>
      <Typography
        paragraph
        color="textSecondary"
        sx={{ mt: 2 }}
        variant="body_200"
      >
        {t('NO_AUTHORIZED.noAccessMessage')}
      </Typography>
      <Button
        color="primary"
        onClick={handleLogOut}
        sx={{ mt: 2 }}
        variant="contained"
      >
        {t('NO_AUTHORIZED.backToLogin')}
      </Button>
    </Box>
  );
};

export default NoAuthorized;
