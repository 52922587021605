import React from 'react';
import '../../style/App.scss';
import { Box, MenuItem, Typography, SxProps, Theme } from '@mui/material';
import SelectInput from '../form/SelectInput';

interface FilterProp {
  arrayInput: string[];
  backgroundColor?: string;
  customStyle?: SxProps<Theme>;
  disabled?: boolean;
  selectInput: string;
  setSelectInput: (value: React.SetStateAction<string>) => void;
  title: string;
  width?: string;
}

const Filter: React.FC<FilterProp> = function Filter({
  arrayInput,
  backgroundColor,
  customStyle,
  selectInput,
  setSelectInput,
  title,
  disabled,
  width
}) {
  return (
    <Box alignItems="center" display="flex" width={width || '136px'}>
      <SelectInput
        displayEmpty
        backgroundColor={backgroundColor}
        disabled={disabled}
        labelId="filter-label"
        onChange={(value) => {
          setSelectInput(value.target.value as unknown as string);
        }}
        sx={customStyle}
        value={selectInput}
      >
        <MenuItem disabled sx={{ color: 'black' }} value="">
          <Typography color="secondary.400" variant="body_200">
            {title}
          </Typography>
        </MenuItem>
        {arrayInput.map((value) => (
          <MenuItem key={value} value={value}>
            <Typography color="secondary.400" variant="body_200">
              {value}
            </Typography>
          </MenuItem>
        ))}
      </SelectInput>
    </Box>
  );
};

Filter.defaultProps = {
  backgroundColor: '',
  customStyle: {},
  disabled: false,
  width: ''
};

export default Filter;
