import {
  Box,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Stack
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import styled from '@emotion/styled';
import { themePalette } from '../../theme/themeConfig';

interface RateSummaryProps {
  defaultExpanded?: boolean;
  hourlyRate: number;
  showIcon?: boolean;
  title: string;
  total: number;
  totalHours: number | string;
}

interface RateSummaryRowProps {
  darkColor?: boolean;
  title: string;
  value: number | string;
}

const styles = {
  rateContainer: {
    border: '1px solid #DDDDDD',
    borderRadius: '8px',
    boxShadow: 'none'
  },
  rateTitleContainer: {
    alignItems: 'center',
    backgroundColor: 'background.default',
    display: 'flex'
  },
  rowsContainer: {
    display: 'grid',
    gap: '16px'
  }
};

const DividerContainer = styled(Box)(() => ({
  backgroundColor: themePalette.BACKGROUND
}));

function RateSummaryRow({
  title,
  value,
  darkColor = false
}: RateSummaryRowProps) {
  const textColor = darkColor ? 'mainText.main' : 'mainText.300';

  return (
    <Stack color={textColor} direction="row" justifyContent="space-between">
      <Typography fontWeight="500" variant="body_300">
        {title}
      </Typography>
      <Typography fontWeight="500" variant="body_300">
        {value}
      </Typography>
    </Stack>
  );
}

function RateSummary({
  defaultExpanded,
  totalHours,
  hourlyRate,
  showIcon,
  title,
  total
}: RateSummaryProps) {
  const rows = [
    {
      title: 'Total Hours',
      value: totalHours
    },
    {
      title: 'Total Hourly Rate',
      value: hourlyRate
    },
    {
      darkColor: true,
      title: 'Total',
      value: total
    }
  ];

  return (
    <Accordion defaultExpanded={defaultExpanded} sx={styles.rateContainer}>
      <AccordionSummary
        expandIcon={showIcon ? <ExpandMoreRoundedIcon /> : <Box />}
        sx={styles.rateTitleContainer}
      >
        <Typography color="mainText.main" fontWeight="500" variant="body_300">
          {title}
        </Typography>
      </AccordionSummary>
      <DividerContainer paddingX="16px">
        <Divider />
      </DividerContainer>
      <AccordionDetails sx={{ backgroundColor: 'background.default' }}>
        <Box sx={styles.rowsContainer}>
          {rows.map((row) => (
            <RateSummaryRow
              darkColor={row.darkColor}
              key={row.title}
              title={row.title}
              value={row.value}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

RateSummary.defaultProps = {
  defaultExpanded: false,
  showIcon: false
};

RateSummaryRow.defaultProps = {
  darkColor: false
};

export default RateSummary;
