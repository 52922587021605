import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Button, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import dayjs from 'dayjs';
import BottomSheet from '../bottomSheet/BottomSheet';

export interface FiltersBottomSheetProps {
  handleApply: () => void;
  handleDateChange: any;
  isOpen: boolean;
  reportRanges: any;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function FiltersBottomSheet({
  isOpen,
  setIsOpen,
  reportRanges,
  handleApply,
  handleDateChange
}: FiltersBottomSheetProps) {
  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={t('TIMESHEET.filters')}
    >
      <Stack direction="column" spacing="100px" sx={{ padding: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            width: '100%'
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            key="timeSheet-LocalizationProvider-range1-fromDate"
            sx={{}}
          >
            <DatePicker
              label={t('TIMESHEET.startDate')}
              onChange={(fromDate: any) => {
                handleDateChange(fromDate, 5);
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{
                    backgroundColor: 'transparent !important',
                    width: '100%'
                  }}
                />
              )}
              value={reportRanges.fromDate}
            />
            <DatePicker
              label={t('TIMESHEET.endDate')}
              minDate={dayjs(reportRanges.fromDate) || undefined}
              onChange={(toDate: any) => {
                handleDateChange(toDate, 6);
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{
                    backgroundColor: 'transparent !important',
                    width: '100%'
                  }}
                />
              )}
              value={reportRanges.toDate}
            />
          </LocalizationProvider>
        </Box>

        <Button onClick={handleApply} variant="primary">
          {t('TIMESHEET.apply')}
        </Button>
      </Stack>
    </BottomSheet>
  );
}

export default FiltersBottomSheet;
