import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { secondaryColors } from '../../../theme/themeConfig';
import { getCurrency } from '../../../utils/general';

interface DataList {
  actualEnd: string;
  actualStart: string;
  actualTotal: number;
  clientName: string;
  companyName: string;
  day: string;
  id: number;
  laborHourCost: string;
  laborTotalCost: string;
  millisecondsWorked: string;
  projectName: string;
  scheduledEnd: string;
  scheduledStart: string;
  scheduledTotal: number;
  status: string;
  taskName: string;
  technician: string;
  travelTime: number;
  travelTimeInMilliseconds: string;
  type: string;
  typeText: string;
  variance: number;
  varianceActual: string;
  variancePercentage: string;
}

interface TimesheetExpandedReviewRowProps {
  dataList: DataList;
}

const TimesheetExpandedReviewRow: React.FC<TimesheetExpandedReviewRowProps> =
  function TimeSheetCollapsedView({ dataList }) {
    // Hooks
    const theme = useTheme();
    const { t } = useTranslation();

    // Render
    return (
      <Box
        bgcolor="#FCFCFC"
        display="flex"
        flexDirection="column"
        gap={theme.spacing(3)}
        paddingX={theme.spacing(1)}
        paddingY={theme.spacing(3)}
      >
        {/* Project information */}
        <Box>
          <Typography fontSize={18} variant="primary-bold">
            {t('TIMESHEET.projectInformationTitle')}
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            paddingX={theme.spacing(1)}
            paddingY={theme.spacing(3)}
            xs={12}
          >
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.taskName')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.taskName}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.client')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.clientName}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.companyName')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.companyName}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.laborHourCost')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {getCurrency(dataList.laborHourCost)}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.totalCost')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {getCurrency(dataList.laborTotalCost)}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Time Track */}
        <Box>
          <Typography fontSize={18} variant="primary-bold">
            {t('TIMESHEET.timeTrackTitle')}
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            paddingX={theme.spacing(1)}
            paddingY={theme.spacing(3)}
            xs={12}
          >
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.actualStart')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.actualStart}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.actualEnd')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.actualEnd}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.scheduleStart')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.scheduledStart}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.scheduleEnd')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.scheduledEnd}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.projectType')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.typeText}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

export default TimesheetExpandedReviewRow;
