import { RolesEnum } from '../../api-client/generated';
import {
  InvitationApi,
  RolesAndPermissionsApi,
  SkillApi,
  TechniciansSearchApi
} from '../../api-client/generated/api';
import { getRoleLabel } from '../../utils/general';

const apiCaller = new InvitationApi();
const rolesCaller = new RolesAndPermissionsApi();
const skillCaller = new SkillApi();
const createDirectUsersCaller = new TechniciansSearchApi();

interface Pagination {
  items: number;
  page: number;
}

const getUserInvitations = async () => {
  const response = await apiCaller.invitationGetAll();
  const { data } = response;
  return Array.isArray(data) ? data : [];
};

const getUserInvitationsPaginated = async (params: Pagination) => {
  const response = await apiCaller.invitationListInvitations(params);
  const { data } = response;
  return data;
};

const resendUserInvitation = async (email: string) => {
  const response = await apiCaller.invitationResendInvitation({ email });
  const { status, statusText } = response;
  return { status, statusText };
};

const deleteUserInvitation = async (id: string) => {
  const response = await apiCaller.invitationDeleteInvitation({ id });
  const { status, statusText } = response;
  return { status, statusText };
};

const sendInvitation = async (params: any) => {
  const {
    email,
    firstName,
    lastName,
    jobTitle,
    rate,
    roleIds,
    skillIds,
    phoneNumber,
    specialRole
  } = params;
  let response;
  if (specialRole) {
    response = await createDirectUsersCaller.technicianCreateTechnician({
      createDirectUserDTO: {
        email,
        firstName,
        jobTitle,
        lastName,
        phoneNumber,
        rate,
        roleIds,
        skillIds
      }
    });
  } else {
    response = await apiCaller.invitationCreate({
      createInvitationDTO: {
        email,
        firstName,
        jobTitle,
        lastName,
        phoneNumber,
        rate,
        roleIds,
        skillIds
      }
    });
  }
  const { status, statusText } = response;
  return { status, statusText };
};

const getRoles = async () => {
  const response = await rolesCaller.rolesGetRoles();

  if (response?.data) {
    response.data = response.data?.map((role: any) => {
      role.label = getRoleLabel(role.name);
      return role;
    });
  }

  return response.data;
};

const getSkills = async () => {
  const response = await skillCaller.skillGetAll();
  return response.data;
};

const getRolesByType = async (params: any) => {
  const { roles } = params;

  let type = roles ? roles[0] : null;

  if (roles && roles.length > 0) {
    if (roles.includes(RolesEnum.Operations)) {
      type = RolesEnum.Operations;
    }

    if (roles.includes(RolesEnum.Administrator)) {
      type = RolesEnum.Administrator;
    }
  }

  const response: any = await rolesCaller.rolesGetRolesByUserType({ type });

  if (response?.data) {
    response.data = response.data?.map((role: any) => {
      role.label = getRoleLabel(role.name);
      return role;
    });
  }

  const { data } = response;

  return data as unknown as Array<any>;
};

export default {
  deleteUserInvitation,
  getRoles,
  getRolesByType,
  getSkills,
  getUserInvitations,
  getUserInvitationsPaginated,
  resendUserInvitation,
  sendInvitation
};
