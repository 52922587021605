/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import {
  Box,
  Button,
  TableHead,
  TableRow,
  SliderProps,
  TableCell,
  Typography,
  styled,
  Collapse,
  useTheme,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ServiceSummary from './ServiceSummary';
import ServiceWindow from './ServiceWindow';
import AssignResources from './AssignResources';
import AssignSupervisor from './AssignSupervisor';
import ServiceTimeDuration from './ServiceTimeDuration';
import { getServices } from '../../../../utils/general';
import {
  accentColors,
  secondaryColors,
  textColors,
  warningColors
} from '../../../../theme/themeConfig';
import { CreateLead } from '../../../../utils/types';

export const ServicesDots = styled('span')(() => ({
  alignItems: 'center',
  borderRadius: '50%',
  color: 'white',
  display: 'flex',
  height: '44px',
  justifyContent: 'center',
  width: '44px'
}));

const RowElement = styled(TableCell)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  width: '100%'
}));

interface Props {
  saveStepper: ({ next }: any) => void;
  sendValues?: React.Dispatch<React.SetStateAction<any>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: CreateLead;
}

const ResourceServiceTimeList: React.FC<Props> =
  function ResourceServiceTimeList({
    sendValues,
    saveStepper,
    values,
    setValues,
    setStep
  }) {
    const [open, setOpen] = useState<Array<boolean>>([]);
    const { t } = useTranslation();
    const theme = useTheme();

    const serviceSummary = (index: any) => {
      return ServiceSummary({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        values
      });
    };

    const serviceWindow = (index: any) => {
      return ServiceWindow({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        values
      });
    };

    const assignResources = (index: any) => {
      return AssignResources({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        values
      });
    };

    const assignSupervisor = (index: any) => {
      return AssignSupervisor({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        setValuesST(value: any): void {
          setValues(values);
        },
        values
      });
    };

    const serviceTimeDuration = (index: any) => {
      return ServiceTimeDuration({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        values
      });
    };

    const auxOpen = (auxIndex: any): boolean => {
      const newOpen = [...open];
      newOpen[auxIndex] = !newOpen[auxIndex];
      setOpen(newOpen);
      return !newOpen[auxIndex];
    };

    const serviceTimeHeader = (index: any) => {
      // const auxOpen = undefined !== open[index];
      if (undefined === open[index]) {
        let auxOpen = open;
        auxOpen[index] = false;
        auxOpen = [...auxOpen];
      }

      const validateData = (): boolean => {
        const serviceTime = values?.serviceTimes?.[index];

        if (!serviceTime) return false;

        // 1. First Condition: assignSupervisor validation
        const isAssignSupervisorValid = serviceTime?.assignSupervisor
          ? serviceTime.supervisor?.id && serviceTime.supervisorTime > 0
          : !serviceTime.supervisor?.id &&
            parseFloat(serviceTime.supervisorTime) === 0;

        if (!isAssignSupervisorValid) return false;

        // 2. Second Condition: tasks must have resources
        const hasTasksWithResources = serviceTime.services?.some((service) =>
          service.tasks?.some(
            (task) => task.resources && task.resources.length > 0
          )
        );

        if (!hasTasksWithResources) return false;

        // 3. Third Condition: resourcesAssigned >= minimumResources
        const areResourcesValid = serviceTime.services?.every((service) =>
          service.tasks?.every(
            (task) => task.resourcesAssigned >= task.minimumResources
          )
        );

        if (!areResourcesValid) return false;

        return true;
      };

      return (
        <Box
          padding="24px"
          sx={{
            border: `1px solid ${secondaryColors.SECONDARY_1}`,
            borderRadius: '8px'
          }}
        >
          <TableHead
            sx={{
              borderRadius: '8px',
              display: 'flex',
              width: '100%'
            }}
          >
            {values.serviceTimes[index] && (
              <TableRow
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%'
                }}
              >
                <RowElement onClick={() => {}} padding="none">
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Box
                      display="grid"
                      gridTemplateColumns="174px 1fr 1fr"
                      sx={{ gridColumnGap: '16px' }}
                    >
                      <Typography
                        color={textColors.DARK_TEXT}
                        variant="secondary-semi"
                      >
                        {values &&
                        values.projectType !== 'adhoc' &&
                        values.projectType !== 'Ad-Hoc'
                          ? `${t(
                              'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                            )} #${index + 1}`
                          : `Ad-Hoc ${t(
                              'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                            )} #${index + 1}`}
                      </Typography>

                      <Typography color="mainText.300" variant="body_300">
                        {getServices(values?.serviceTimes[index])}
                      </Typography>

                      <Typography color="mainText.300" variant="body_300">
                        {values?.serviceTimes && values.projectType !== 'Ad-Hoc'
                          ? values?.serviceTimes[index].frequency
                          : values?.serviceTimes[index].startDate}
                      </Typography>
                    </Box>

                    <Box display="flex" gap="24px">
                      {validateData() ? (
                        <ServicesDots
                          sx={{ backgroundColor: accentColors.ACCENT_4 }}
                        >
                          <i className="custom-icon icon-active" />
                        </ServicesDots>
                      ) : (
                        <ServicesDots
                          sx={{ backgroundColor: warningColors.ERROR_4 }}
                        >
                          <i className="custom-icon icon-warningTriangle" />
                        </ServicesDots>
                      )}

                      <Button
                        onClick={() => auxOpen(index)}
                        sx={{ padding: 0 }}
                      >
                        <i
                          className="custom-icon icon-select"
                          style={{
                            color: `${secondaryColors.SECONDARY_4}`,
                            fontSize: '14px',
                            transform: `rotate(${
                              open[index] ? '180deg' : '0deg'
                            })`
                          }}
                        />
                      </Button>
                    </Box>
                  </Box>
                </RowElement>
              </TableRow>
            )}
          </TableHead>

          <Collapse in={open[index]}>
            <Box
              display="flex"
              flexDirection="column"
              gap="24px"
              marginTop="24px"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap="24px"
                padding="32px 24px 32px 24px"
                sx={{
                  border: 1,
                  borderColor: secondaryColors.SECONDARY_1,
                  borderRadius: '8px'
                }}
              >
                {serviceSummary(index)}

                <Divider
                  flexItem
                  sx={{ borderColor: secondaryColors.SECONDARY_1 }}
                />

                {serviceWindow(index)}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap="32px"
                padding="32px 24px 32px 24px"
                sx={{
                  border: 1,
                  borderColor: secondaryColors.SECONDARY_1,
                  borderRadius: '8px'
                }}
              >
                {assignResources(index)}
                {assignSupervisor(index)}
              </Box>
              {serviceTimeDuration(index)}
            </Box>
          </Collapse>
        </Box>
      );
    };

    return (
      <Box display="flex" flexDirection="column" gap="24px">
        {values?.serviceTimes?.length > 0 &&
          values?.serviceTimes.map((serviceTime: any, key: number) => {
            const auxKey = `schedule-serviceTime-${key}`;
            if (
              serviceTime &&
              serviceTime?.id &&
              typeof serviceTime?.id === 'number'
            ) {
              return (
                <React.Fragment key={`${auxKey}`}>
                  {serviceTimeHeader(key)}
                </React.Fragment>
              );
            }

            return null;
          })}
      </Box>
    );
  };

export default ResourceServiceTimeList;
