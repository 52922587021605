import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import blueBerryLogoMini from '../../assets/images/bluberry_logo_mini.png';
import { secondaryColors } from '../../theme/themeConfig';

interface Props {
  clientView?: boolean;
  counterOfferMode?: boolean;
  openAnalytics?: any;
  previewFlag?: boolean;
  proposalFormValue?: any;
  values: any;
}

const ProposalTitle: React.FC<Props> = function ProposalTitle({
  clientView,
  previewFlag,
  values,
  proposalFormValue,
  counterOfferMode,
  openAnalytics
}) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="16px"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" gap="16px">
        {clientView ? (
          <Box display="flex" flexDirection="row" gap="10px">
            <img
              alt="BlueBerry Logo"
              className="logo-image-mini"
              src={blueBerryLogoMini}
            />
            <Box display="flex" flexDirection="column" gap="2px">
              <Typography
                color="secondary"
                sx={{ fontSize: 22 }}
                variant="heading_300"
              >
                {t('LEAD.CREATE.PROPOSAL.titleProposal')}
              </Typography>
              <Typography
                color="secondary.200"
                sx={{ fontSize: 16 }}
                variant="body_500"
              >
                {values?.client.businessName}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography color="primary.main" variant="heading_500">
            {t('LEAD.CREATE.PROPOSAL.titleCreateProposal')}
            {previewFlag
              ? t('LEAD.CREATE.PROPOSAL.titlePreview')
              : t('LEAD.CREATE.PROPOSAL.titlePricing')}
          </Typography>
        )}

        {previewFlag ? null : (
          <Typography
            color={secondaryColors.SECONDARY_4}
            fontWeight={600}
            variant="body_200"
          >{`${t('LEAD.CREATE.PROPOSAL.subtitlePart1')} ${
            values?.profit || values?.profitPercentage || 0
          }% ${t('LEAD.CREATE.PROPOSAL.subtitlePart2')}`}</Typography>
        )}
      </Box>

      {previewFlag &&
      proposalFormValue?.personalizedMessage &&
      `${clientView}` !== 'true' ? (
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          padding="24px"
          sx={{
            border: `1px solid ${secondaryColors.SECONDARY_1}`,
            borderRadius: '8px'
          }}
        >
          <Typography
            color={secondaryColors.SECONDARY_5}
            variant="secondary-semi"
          >
            {t('LEAD.CREATE.PROPOSAL.personalizedMsgTitle')}
          </Typography>
          <TextField
            disabled
            multiline
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black !important'
              },
              '& .MuiInputBase-root': {
                WebkitTextFillColor: `${secondaryColors.SECONDARY_4} !important`,
                color: `${secondaryColors.SECONDARY_4} !important`,
                fontSize: '16px !important',
                padding: '0px !important'
              },
              '& fieldset': { border: 'none' },
              backgroundColor: 'white !important',
              height: 'auto',
              padding: '0px !important',
              width: '100%'
            }}
            value={proposalFormValue?.personalizedMessage || ''}
          />
        </Box>
      ) : null}

      {/* {openAnalytics && (
        <Button onClick={openAnalytics} variant="secondary-outlined">
          {t('LEAD.CREATE.PROPOSAL.openAnalytics')}{' '}
          <ShowChartIcon sx={{ marginLeft: '10px' }} />
        </Button>
      )} */}
    </Box>
  );
};

ProposalTitle.defaultProps = {
  clientView: false,
  counterOfferMode: false,
  openAnalytics: null,
  previewFlag: false,
  proposalFormValue: {}
};

export default ProposalTitle;
