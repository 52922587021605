/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  MenuItem,
  Modal,
  Paper,
  SlideProps,
  SliderProps,
  TextField,
  Typography,
  styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import ArrayChips from '../list/ArrayChips';
import RegularInput from '../input/RegularInput';
import { capitalizeString } from '../../utils/general';
import SelectInput from '../form/SelectInput';
import TimeComponent from '../input/TimeComponent';
import Loader from '../shared/Loader';
import SortableTable from '../list/sortableTable';
import AddChecklistModal from './AddChecklistModal';
import useNavigationStore from '../../store/zustand/navigation';
import { mainColors, secondaryColors } from '../../theme/themeConfig';

const rolesStyle = {
  backgroundColor: '#D9F2F0',
  border: '1px solid #68C0BA',
  borderRadius: '24px !important',
  color: '#0F5C56'
};

const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '17px',
  fontWeight: 'bolder',
  marginBottom: '-12px'
}));

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  maxHeight: '90vh',
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 600
}));

const ServiceButton = styled(Button)<SlideProps>(() => ({
  marginBottom: '15px',
  width: '216px'
}));

const SectionContainer = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: 16,
  justifyContent: 'center',
  marginTop: '10px',
  width: '100%'
}));

interface Props {
  setAddToCatalog: (item: boolean) => void;
  setValue: (item: any) => void;
}

const AddTaskModal: React.FC<Props> = function AddTaskModal({
  setValue,
  setAddToCatalog
}) {
  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const [getData, setData] = useState<boolean>(false);
  const [getSkills, setSkills] = useState<boolean>(false);
  const [getMeasure, setMeasure] = useState<boolean>(false);
  const [getMaterials, setMaterials] = useState<boolean>(false);
  const [tasks, setTasks] = useState<Array<any>>();
  const [taskSelected, setTaskSelected] = useState<any>();
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [askTask, setAskService] = useState<boolean>(false);
  const [taskChecklist, setTaskChecklist] = useState<Array<any>>([]);
  const [secondaryNestedValue, setSecondaryNestedValue] = useState<{
    name: string;
  }>({ name: '' });
  const [createSecondaryNested, setCreateSecondaryNested] =
    useState<boolean>(false);
  const [secondaryItems, setSecondaryItems] = useState<Array<any>>([]);
  const [materialList, setMaterialList] = useState<Array<any>>([]);
  const [secondaryValuesAutocomplete, setSecondaryValuesAutocomplete] =
    useState<any>('');

  const [openAddChecklistModal, setOpenAddChecklistModal] =
    useState<boolean>(false);
  const [addChecklist, setAddChecklist] = useState<boolean>(false);
  const [checklistFromModal, setChecklistFromModal] = useState<any>();

  //  ================================================================================= SWR
  const { data, isValidating: dataLoading } = useSWR(
    getData ? { url: 'api/AllTasks' } : null,
    api.taskApi.getAllTasks
  );

  const { data: skills, isValidating: skillsLoading } = useSWR(
    getSkills ? { url: 'api/AllSkills' } : null,
    api.skillApi.getAllSkills
  );

  const { data: measures, isValidating: measureLoading } = useSWR(
    getMeasure ? { url: 'api/AllMeasures' } : null,
    api.MeasureApi.getMeasure
  );

  const { data: materials } = useSWR(
    getMaterials ? 'api/getMaterials' : null,
    api.MaterialApi.getAllMaterials
  );

  const { data: taskData } = useSWR(
    askTask && undefined !== taskSelected?.id
      ? {
          id: taskSelected?.id,
          relations: 'tasks,skills,categories',
          url: 'api/getService'
        }
      : null,
    api.taskApi.getTask
  );

  const { data: createSecondaryElement } = useSWR(
    createSecondaryNested ? secondaryNestedValue : null,
    api.categoryApi.createCategory
  );

  //  ================================================================================= FUNCTIONS
  const isUnit = () => {
    if (!measures || !taskSelected.measure) {
      return false;
    }
    return taskSelected.measure === 'Unit';
  };

  const createNewSecondaryNestedElement = (newValue: any) => {
    setSecondaryNestedValue({ name: newValue.createValue });
  };

  //  ================================================================================= USEEFFECTS

  useEffect(() => {
    if (data) {
      setData(false);
      setTasks(data);
    }
  }, [data]);

  useEffect(() => {
    setData(true);
  }, []);

  useEffect(() => {
    if (taskData) {
      setValue(taskData);
    }
  }, [taskData]);

  useEffect(() => {
    if (taskSelected) {
      if (undefined !== taskSelected?.id) {
        setAskService(true);
      } else {
        setValue({ ...taskSelected });
      }
    }
  }, [taskSelected]);

  useEffect(() => {
    if (secondaryNestedValue && secondaryNestedValue.name !== '') {
      setCreateSecondaryNested(true);
    }
  }, [secondaryNestedValue]);

  useEffect(() => {
    if (createSecondaryElement) {
      setCreateSecondaryNested(false);
      const newValue = createSecondaryElement;
      setSecondaryItems([...secondaryItems, newValue]);
      setSecondaryValuesAutocomplete(newValue);
    }
  }, [createSecondaryElement]);

  useEffect(() => {
    if (secondaryValuesAutocomplete) {
      setSecondaryNestedValue({
        ...(secondaryValuesAutocomplete && secondaryValuesAutocomplete.id
          ? secondaryValuesAutocomplete.id
          : undefined)
      });
    }
  }, [secondaryValuesAutocomplete]);

  useEffect(() => {
    if (addChecklist && checklistFromModal) {
      const checklist = checklistFromModal.list.map((item: any) => {
        return { completed: false, name: item };
      });
      setTaskChecklist([...taskChecklist, ...checklist]);
      setAddChecklist(false);
      setChecklistFromModal(null);
    }
  }, [addChecklist]);

  //  ================================================================================= RENDER

  return (
    <Box
      alignItems="start"
      display="flex"
      flexDirection="column"
      justifyContent="start"
      style={{ maxHeight: 500, overflow: 'auto' }}
      width={500}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography variant="title">
            {t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.modalTitle')}
          </Typography>
          <Typography textAlign="left" variant="subtitle1">
            {t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.subTitle')}
          </Typography>
        </Box>
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          gap={0.5}
          width="100%"
        >
          <Typography color={mainColors.MAIN} variant="heading_400">
            {t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.name')}
          </Typography>
          {dataLoading && <Loader customColor="primary" />}
          {!dataLoading && tasks && tasks.length > 0 && (
            <Box display="flex" flexDirection="column" gap={1} width="100%">
              <AutoCompleteInput
                closeOnSelect
                onChange={(event: any, newValue: any | null) => {
                  if (undefined !== newValue?.createValue) {
                    setTaskSelected({ name: newValue.createValue, skills: [] });
                    setCreateMode(true);
                    setSkills(true);
                    setMeasure(true);
                    setMaterials(true);
                  } else {
                    setTaskSelected(newValue);
                    setValue(newValue);
                    setCreateMode(false);
                  }
                }}
                options={tasks}
                renderInput={(params) => (
                  <TextField
                    placeholder={`${t(
                      'LEAD.CREATE.SERVICES.ADD_TASK_MODAL.autoCompletePlaceholder'
                    )}`}
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'hidden'
                      },
                      borderStyle: 'hidden',
                      fontSize: '15px'
                    }}
                    {...params}
                  />
                )}
                value={taskSelected}
              />
              {!taskSelected && (
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <Divider
                    flexItem
                    sx={{ borderColor: secondaryColors.SECONDARY_1 }}
                  />
                  <Button
                    onClick={() => setValue({ lineItemTask: true })}
                    sx={{ maxWidth: '230px' }}
                    variant="primary-text"
                  >
                    <Box alignItems="center" display="flex" gap="10px">
                      <i className="custom-icon icon-add" />
                      <Typography variant="heading_328">
                        {t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.addLineTask')}
                      </Typography>
                    </Box>
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {taskSelected && (
        <Box
          alignItems="start"
          display="flex"
          flexDirection="column"
          justifyContent="start"
          width="100%"
        >
          <Box pt={2}>
            <SubTitle>
              {t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.description')}
            </SubTitle>
          </Box>

          {createMode ? (
            <RegularInput
              multiline
              customStyle={{ width: '100%' }}
              onChange={(description) =>
                setTaskSelected({ ...taskSelected, description })
              }
              value={taskSelected.description || ''}
            />
          ) : (
            <Paper
              sx={{
                alignItems: 'start',
                display: 'flex',
                mb: 2,
                mt: 2,
                p: 1,
                width: '100%'
              }}
            >
              <Typography variant="primary">
                {taskSelected.description && taskSelected.description !== ''
                  ? taskSelected.description
                  : `${t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.noData')}`}
              </Typography>
            </Paper>
          )}
          <Box pb={2} pt={2}>
            <SubTitle>
              {t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.skills')}
            </SubTitle>
          </Box>
          <Box width="100%">
            {!createMode && taskSelected.skills.length === 0 && (
              <Box display="flex" mt={2}>
                <Typography variant="primary">
                  {t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.noData')}
                </Typography>
              </Box>
            )}
            {createMode ? (
              <Box>
                {skillsLoading && <Loader customColor="primary" />}
                {!skillsLoading && skills && (
                  <AutoCompleteInput
                    closeOnSelect
                    disableCreate
                    multiple
                    onChange={(event: any, newValue: any | null) => {
                      setTaskSelected({ ...taskSelected, skills: newValue });
                    }}
                    options={skills}
                    renderInput={(params) => (
                      <TextField
                        placeholder={`${t(
                          'LEAD.CREATE.SERVICES.ADD_TASK_MODAL.autoCompletePlaceholderSkills'
                        )}`}
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                            borderStyle: 'hidden'
                          },
                          borderStyle: 'hidden',
                          fontSize: '15px'
                        }}
                        {...params}
                      />
                    )}
                    value={taskSelected.skills}
                  />
                )}
              </Box>
            ) : (
              <ArrayChips
                unlimited
                colorFont="#0F5C56"
                elementsArray={taskSelected.skills?.map(
                  (item: any) => item.name
                )}
                style={rolesStyle}
              />
            )}
            {createMode && (
              <Box>
                <Box display="flex" justifyContent="flex-start" pt={2}>
                  <SubTitle>{capitalizeString('checklist')}</SubTitle>
                </Box>
                <Box
                  className="scrollbar"
                  sx={{ marginTop: '16px', maxHeight: 200 }}
                >
                  <SortableTable
                    addManagement
                    bodyKeys={['name']}
                    onChange={(newChecklist) => {
                      const checklist = newChecklist.map((item: any) => {
                        return { ...item, completed: false };
                      });
                      setTaskChecklist(checklist);
                      setTaskSelected({
                        ...taskSelected,
                        checklist
                      });
                    }}
                    type="generic-input"
                    values={taskChecklist}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="end"
                  mb={4}
                  mt={1}
                  sx={{ cursor: 'pointer' }}
                >
                  <SubTitle
                    fontSize="16px"
                    onClick={() => {
                      setOpenAddChecklistModal(true);
                    }}
                  >
                    <Box alignItems="center" display="flex" fontSize="16px">
                      <i
                        className="custom-icon icon-add"
                        style={{ fontSize: 18 }}
                      />
                      {capitalizeString('Add Checklist from catalog')}
                    </Box>
                  </SubTitle>
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  width="100%"
                >
                  <SubTitle>{capitalizeString(t('MODAL.measure'))}</SubTitle>
                  <Box pl={2} width={200}>
                    {!measureLoading && (
                      <SelectInput
                        height="36"
                        onChange={(event) => {
                          const currentMeasure =
                            measures &&
                            measures.find(
                              (item: any) => item.name === event.target.value
                            );

                          setTaskSelected({
                            ...taskSelected,
                            measure: event.target.value,
                            measureId: currentMeasure.id || ''
                          });
                        }}
                        selectvariant="secondary"
                      >
                        {measures &&
                          measures.map((measure: any) => (
                            <MenuItem key={measure.id} value={measure.name}>
                              {measure.name}
                            </MenuItem>
                          ))}
                      </SelectInput>
                    )}
                    {measureLoading && <Loader customColor="primary" />}
                  </Box>
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="start"
                  width="100%"
                >
                  <SubTitle sx={{ pr: 2 }}>
                    {isUnit()
                      ? capitalizeString(t('MODAL.timePerMeasure', ['Unit']))
                      : capitalizeString(t('MODAL.measurePerHour'))}
                  </SubTitle>
                  {isUnit() ? (
                    <TimeComponent
                      setTime={(measurePerHour) =>
                        setTaskSelected({ ...taskSelected, measurePerHour })
                      }
                      time={taskSelected.measurePerHour || 0}
                    />
                  ) : (
                    <RegularInput
                      customStyle={{ width: 220 }}
                      onChange={(measurePerHour) =>
                        setTaskSelected({ ...taskSelected, measurePerHour })
                      }
                      type="number"
                      value={taskSelected.measurePerHour || 0}
                    />
                  )}
                </Box>
                <Box>
                  <Box display="flex" justifyContent="flex-start" pt={2}>
                    <SubTitle>
                      {' '}
                      {capitalizeString(t('MODAL.materials'))}
                    </SubTitle>
                  </Box>
                  <Box
                    className="scrollbar"
                    sx={{ marginTop: '16px', maxHeight: 200 }}
                  >
                    <SortableTable
                      addManagement
                      disableSort
                      createNestedElement={createNewSecondaryNestedElement}
                      nestedOptions={materials}
                      onChange={(newChecklist) => {
                        if (newChecklist?.length > 0 && newChecklist[0]?.id) {
                          const formatMaterials = newChecklist.map(
                            (material: any) => {
                              return {
                                cost: parseFloat(material.cost || '0'),
                                materialId: material.id,
                                name: material.name
                              };
                            }
                          );
                          setMaterialList(newChecklist);
                          setTaskSelected({
                            ...taskSelected,
                            materials: formatMaterials || null
                          });
                        }
                      }}
                      type="material-input"
                      values={materialList}
                    />
                  </Box>
                </Box>
                <Box mt={4}>
                  <Box display="flex" justifyContent="flex-start">
                    <SubTitle>
                      {capitalizeString(t('MODAL.minimumResources'))}
                    </SubTitle>
                  </Box>
                  <RegularInput
                    customStyle={{ fontSize: '15px', width: '100%' }}
                    onChange={(event: any) =>
                      setTaskSelected({
                        ...taskSelected,
                        minimumResources: Number(event)
                      })
                    }
                    type="number"
                    value={taskSelected.minimumResources}
                  />
                </Box>
                <Box alignItems="center" display="flex" pb={2} pt={2}>
                  <Checkbox
                    onChange={(event) => {
                      setAddToCatalog(event.target.checked || false);
                    }}
                  />
                  <SubTitle>
                    {t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.addToCatalog')}
                  </SubTitle>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {openAddChecklistModal && (
        <Modal
          closeAfterTransition
          open
          className="scrollbar"
          onClose={() => {}}
        >
          <Fade in>
            <ModalContainer className="scrollbar">
              <AddChecklistModal
                setValue={(value) => {
                  setChecklistFromModal(value);
                }}
              />
              <SectionContainer>
                <ServiceButton
                  color="error"
                  onClick={() => {
                    setOpenAddChecklistModal(false);
                    setAddChecklist(false);
                  }}
                  variant="outlined"
                >
                  <Typography>{`${t('GENERAL.cancelButton')}`}</Typography>
                </ServiceButton>
                <ServiceButton
                  onClick={() => {
                    setAddChecklist(true);
                    setOpenAddChecklistModal(false);
                  }}
                  variant="contained"
                >
                  <Typography>{`${t(
                    'GENERAL.addChecklistButton'
                  )}`}</Typography>
                </ServiceButton>
              </SectionContainer>
            </ModalContainer>
          </Fade>
        </Modal>
      )}
    </Box>
  );
};

export default AddTaskModal;
