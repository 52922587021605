/* eslint-disable react/jsx-props-no-spreading */
import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegularInput from './RegularInput';

interface TimeInput {
  hours: string;
  minutes: string;
}

interface TimeProps {
  containerProps?: any;
  disabled?: boolean;
  readOnly?: boolean;
  reverseOrder?: boolean;
  setTime: (time: number) => void;
  short?: boolean;
  textColor?: string;
  time: number;
}

const TimeComponent: React.FC<TimeProps> = function TimeComponent({
  containerProps,
  time,
  setTime,
  disabled,
  short,
  readOnly,
  reverseOrder,
  textColor
}) {
  const [localTime, setLocalTime] = useState<TimeInput>({
    hours: '00',
    minutes: '00'
  });
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (time) {
      // TODO: refactor this, try to use a function in a healpear file
      const decimalTimeString = `${time}`;
      let decimalTime = parseFloat(decimalTimeString);
      decimalTime = decimalTime * 60 * 60;
      const hours = Math.floor(decimalTime / (60 * 60));
      decimalTime -= hours * 60 * 60;
      const minutes = Math.round(decimalTime / 60);
      decimalTime -= minutes * 60;
      setLocalTime({
        hours: hours < 10 ? `0${hours}` : `${hours}`,
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`
      });
    }
  }, [time]);

  useEffect(() => {
    if (localTime.hours !== '00' || localTime.minutes !== '00') {
      const stringTime = `${localTime.hours}:${localTime.minutes}`;
      const t = stringTime.split(':');
      const t2 = `${parseInt(t[0], 10) + parseInt(t[1], 10) / 60}`;
      if (t2 && t2 !== '0') {
        setTime(parseFloat(t2));
      }
    }
  }, [localTime]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      gap={1}
      sx={{ justifyContent: short ? 'center' : 'inherit' }}
      {...containerProps}
    >
      <Box
        alignItems={reverseOrder ? 'stretch' : 'center'}
        display="flex"
        flexDirection={reverseOrder ? 'column-reverse' : 'row'}
        gap={reverseOrder ? '2px' : '8px'}
        sx={{ justifyContent: short ? 'center' : 'inherit' }}
      >
        {!readOnly && (
          <RegularInput
            customStyle={{
              '& .MuiInputBase-input': {
                padding: '8px'
              },
              backgroundColor: 'transparent !important',
              border: 1,
              borderColor: theme.palette.secondary[100],
              marginBottom: '0px',
              marginTop: '0px',
              width: 80
            }}
            disabled={disabled}
            onChange={(value: string) => {
              if (value.length > 1) {
                setLocalTime({ ...localTime, hours: value });
              } else {
                setLocalTime({ ...localTime, hours: `0${value}` });
              }
            }}
            type="number"
            value={localTime.hours}
          />
        )}
        <Typography color={textColor} variant="primary">
          {readOnly && <span>{localTime.hours}</span>}
          <Typography color={textColor} variant="body_100">
            {!short ? ` ${t('LEAD.CREATE.SERVICES.hours')}` : ':'}
            {!short && <>&nbsp;</>}
            {readOnly && !short && t('LEAD.CREATE.SERVICES.timeAnd')}
          </Typography>
        </Typography>
      </Box>
      <Box
        alignItems={reverseOrder ? 'stretch' : 'center'}
        display="flex"
        flexDirection={reverseOrder ? 'column-reverse' : 'row'}
        gap={reverseOrder ? '2px' : '8px'}
      >
        {!readOnly && (
          <RegularInput
            customStyle={{
              '& .MuiInputBase-input': {
                padding: '8px'
              },
              backgroundColor: 'transparent !important',
              border: 1,
              borderColor: theme.palette.secondary[100],
              marginBottom: '0px',
              marginTop: '0px',
              width: 80
            }}
            disabled={disabled}
            onChange={(value: string) => {
              if (value.length > 1) {
                setLocalTime({ ...localTime, minutes: value });
              } else {
                setLocalTime({ ...localTime, minutes: `${value}` });
              }
            }}
            type="number"
            value={localTime.minutes}
          />
        )}
        <Typography color={textColor} variant="primary">
          {readOnly && <span>{localTime.minutes}</span>}
          <Typography color={textColor} variant="body_100">
            {!short && ` ${t('LEAD.CREATE.SERVICES.minutes')}`}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

TimeComponent.defaultProps = {
  containerProps: {},
  disabled: undefined,
  readOnly: undefined,
  reverseOrder: false,
  short: false,
  textColor: 'currentcolor'
};

export default TimeComponent;
