/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import '../../style/App.scss';
import {
  Box,
  styled,
  Button,
  Typography,
  Tabs,
  Tab,
  Chip,
  Collapse,
  TableRow,
  TableHead,
  SliderProps,
  TableCell,
  Checkbox,
  ImageList,
  ImageListItem,
  List,
  ListItemText,
  BoxProps
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Dropzone from '../../components/input/Dropzone';
import Api from '../../api';
import Loader from '../../components/shared/Loader';
import {
  accentColors,
  mainColors,
  secondaryColors,
  successColors,
  surfaceColors,
  textColors,
  themePalette,
  warningColors
} from '../../theme/themeConfig';
import {
  formatSumTime,
  formatTimesAndCalculateDifference,
  getTaskStatus
} from '../../utils/calendarHelper';
import {
  TaskIterationStatusStatusEnum,
  TechnicianMainActionsEnum
} from '../../api-client/generated/api';
import ImageViewer from '../../components/ImageViewer';
import { checkGeofence, getUserLocation } from '../../utils/geofencingHelper';
import { toastNotification } from '../../utils/notificationHelper';
import ToastNotification from '../../components/shared/ToastNotification';

import useAuthStore from '../../store/zustand/auth';
import Notes from '../projects/Notes';

export const MainContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',
  justifyContent: 'left',
  width: '100%'
}));

export const RowContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 24px 16px 24px'
}));

export const TabsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderBottom: 'groove',
  borderBottomWidth: '2px',
  marginBottom: '1rem',
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(2)
}));

export const WhiteContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderTop: 'groove',
  borderTopColor: themePalette.BACKGROUND_BOX,
  borderTopWidth: '2px',
  padding: '16px 24px'
}));

const RowElement = styled(TableCell)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  width: '100%'
}));

const ItemCheckbox = styled(Checkbox)(() => ({
  '&.Mui-checked': {
    color: `${mainColors.MAIN} !important`
  },
  color: `${secondaryColors.SECONDARY_3} !important`,
  display: 'flex',
  paddingRight: '0px'
}));

const ItemText = styled(Typography)(() => ({
  color: `${mainColors.MAIN}`,
  fontFamily: 'Lexend',
  fontSize: '16px',
  fontWeight: '400',
  padding: '1rem'
}));

interface ListItemProps extends BoxProps {
  isTransparent?: boolean;
}

const ListItem = styled(Box)<ListItemProps>(({ isTransparent }) => ({
  backgroundColor: isTransparent ? 'trasparent' : 'white',
  border: 'solid',
  borderColor: isTransparent ? 'none' : secondaryColors.SECONDARY_1,
  borderRadius: '4px',
  borderWidth: isTransparent ? '0px' : '1px',
  display: 'flex',
  marginTop: '0.5rem',
  minHeight: '52px',
  width: '100%'
}));

const ListHead = styled(TableHead)(() => ({
  '.MuiTableHead-root': { boxShadow: 0 },
  backgroundColor: 'white',
  display: 'flex',
  width: '100%'
}));

const ListRow = styled(TableRow)(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '56px',
  width: '100%'
}));

const ListTitle = styled(Typography)(() => ({
  color: textColors.DARK_TEXT,
  fontFamily: 'Lexend',
  fontSize: '18px',
  fontWeight: '600',
  marginLeft: '8px'
}));

const CantChip = styled(Typography)(() => ({
  backgroundColor: secondaryColors.SECONDARY_3,
  borderRadius: '100px',
  color: textColors.TEXT,
  display: 'inline-flex',
  fontFamily: 'Lexend',
  fontSize: '12px',
  fontWeight: '600',
  marginLeft: '8px',
  minWidth: '33px',
  padding: '2px 16px',
  width: 'fit-content'
}));

const ListIconContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  position: 'static',
  right: '1rem'
}));

const ColapseHead = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
}));

interface Task {
  checked: boolean;
  id: number;
  name: string;
}

interface Props {
  // eslint-disable-next-line react/require-default-props
  calendarEventsDataMutate?: any;
  closeAfterComplete?: boolean;
  editable: boolean;
  manualClose: boolean;
  setGetCalendarEventsMobile: React.Dispatch<React.SetStateAction<boolean>>;
  setNoteProjectId?: (id: string) => void;
  setTaskDetailsData: React.Dispatch<React.SetStateAction<any>>;
  taskDetails: any;
}

const TechnicianTaskDetails: React.FC<Props> = function TechnicianTaskDetails({
  editable,
  calendarEventsDataMutate = undefined,
  manualClose = false,
  setGetCalendarEventsMobile,
  setTaskDetailsData,
  taskDetails,
  setNoteProjectId,
  closeAfterComplete = false
}) {
  const [step, setStep] = useState<number>(0);
  const { t } = useTranslation();

  const [currentTaskDetails, setCurrentTaskDetails] = useState<any>({});
  const [newTaskStatus, setNewTaskStatus] = useState('');
  const [newChecklistStatus, setNewChecklistStatus] = useState(false);
  const [checklistIndex, setChecklistIndex] = useState(-1);
  const [openMaterials, setOpenMaterials] = useState<boolean>(false);
  const [openChecklist, setOpenChecklist] = useState<boolean>(false);
  const [openImages, setOpenImages] = useState<boolean>(false);
  const [openNotes, setOpenNotes] = useState<boolean>(false);
  const [updateTask, setUpdateTask] = useState<boolean>(false);
  const [updateChecklist, setUpdateChecklist] = useState(0);
  const [contDrpoKey, setContDrpoKey] = useState(1);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [fireAPIUploadDocs, setFireAPIUploadDocs] = useState<boolean>(false);
  const [fireDeleteApi, setFireDeleteApi] = useState<boolean>(false);
  const [deleteID, setDeleteId] = useState<string>('');
  const [taskItearationFile, setTaskItearationFile] = useState<any>({});
  const [taskItearationFilesList, setTaskItearationFilesList] = useState<any[]>(
    []
  );
  const [getTaskIterationFiles, setGetTaskIterationFiles] =
    useState<boolean>(false);
  const [findFiles, setFindFiles] = useState<boolean>(false);
  const [fireCreateNote, setFireCreateNote] = useState<boolean>(false);
  const [noteText, setNoteText] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [imgData, setImagData] = useState<string>('');
  const [stimatedTime, setStimatedTime] = useState<number>(666);
  const [geofencingData, setGeofencingData] = useState<any>({
    geofencingApproved: null,
    hasPermission: false,
    lat: null,
    long: null
  });
  const [totalTime, setTotalTime] = useState(0);
  const [inProgressTask, setInProgressTask] = useState<boolean>(false);
  const userState = useAuthStore((state) => state);
  const [toastNotification, setToastNotification] =
    useState<toastNotification>();
  const [taskFiles, setTaskFiles] = useState<any>({});

  const allTasksCompleted =
    currentTaskDetails?.taskIteration?.[0]?.checkList.every(
      (item: { checked: boolean; id: number; name: string }) => item.checked
    );

  useEffect(() => {
    const timer = setInterval(() => {
      setTotalTime((prevTime) => {
        if (inProgressTask) {
          return prevTime + 1;
        }

        clearInterval(timer);

        return prevTime;
      });
    }, 60000); // 60000 milisegs = 1 min

    return () => clearInterval(timer);
  }, [inProgressTask]);

  const {
    data: taskIterationFilesData,
    isValidating: isValidatingTaskIteration,
    error: taskIterationFilesDataError
  } = useSWR(
    getTaskIterationFiles && currentTaskDetails?.taskIteration[0]?.id
      ? {
          currentTaskDetails,
          id: currentTaskDetails?.taskIteration[0]?.id,
          setCurrentTaskDetails,
          setTaskItearationFilesList
        }
      : null,
    Api.CalendarApi.technicianTaskManagenmentGetTaskIterationById
  );

  const { data: fileDocs } = useSWR(
    { taskId: taskDetails.id },
    Api.StorageApi.getTaskDetailFiles
  );

  useEffect(() => {
    setTaskFiles(fileDocs);
  }, [fileDocs]);

  const { data: taskUpdatedData, error: taskUpdatedError } = useSWR(
    updateTask && newTaskStatus !== ''
      ? {
          geofencingApproved: geofencingData.geofencingApproved,
          lat: geofencingData.lat,
          long: geofencingData.long,
          status: newTaskStatus,
          taskIterationId: currentTaskDetails.taskIteration[0]?.id
        }
      : null,
    Api.CalendarApi.technicianTaskManagenmentUpdate
  );

  const { data: checklistUpdatedData, error: checklistUpdatedError } = useSWR(
    updateChecklist > 0 && checklistIndex > -1
      ? {
          checklistId: updateChecklist,
          status: newChecklistStatus
        }
      : null,
    Api.CalendarApi.calendarEventsUpdateTaskIterationChecklistStatus
  );

  const {
    data: uploadFileResponse,
    error: uploadFileResponseError,
    isValidating: isValidatingUpload
  } = useSWR(
    fireAPIUploadDocs
      ? {
          file: taskItearationFile?.length > 0 ? taskItearationFile : {},
          taskId: currentTaskDetails?.taskIteration[0]?.id
        }
      : null,
    Api.StorageApi.uploadTaskIterationFile
  );

  const {
    data: deleteResponse,
    isValidating: isValidatingDelete,
    error: errorDelete
  } = useSWR(
    fireDeleteApi && deleteID !== ''
      ? {
          fileId: deleteID,
          metaDescription: 'taskIterationImage'
        }
      : null,
    Api.StorageApi.deleteFile
  );

  const { data: createTaskNoteData, error: createTaskNoteDataError } = useSWR(
    fireCreateNote &&
      noteText !== '' &&
      currentTaskDetails?.taskIteration[0]?.id
      ? {
          entity: 'taskIteration',
          entityId: `${currentTaskDetails?.taskIteration[0]?.id}`,
          noteText: `${noteText}`,
          taskIterationId: Number(currentTaskDetails?.taskIteration[0]?.id) ?? 0
        }
      : null,
    Api.CalendarApi.noteCreate
  );

  const { mutate: locationHistoryCreateMutate } = useSWR('api/locationHistory');

  useEffect(() => {
    if (createTaskNoteData && !createTaskNoteDataError) {
      setNoteText('');
      setFireCreateNote(false);
      const auxNotes = [
        ...currentTaskDetails.taskIteration[0].notes,
        createTaskNoteData
      ];
      setCurrentTaskDetails({
        ...currentTaskDetails,
        taskIteration: [
          {
            ...currentTaskDetails.taskIteration[0],
            notes: auxNotes
          }
        ]
      });
      if (calendarEventsDataMutate !== undefined && !manualClose) {
        setGetCalendarEventsMobile(true);
      }
      calendarEventsDataMutate(undefined, true);
    }
  }, [createTaskNoteData]);

  useEffect(() => {
    if (deleteResponse && !errorDelete && deleteResponse.status === 200) {
      if (currentTaskDetails?.taskIteration.length > 0) {
        const auxFiles = currentTaskDetails?.taskIteration[0]?.fileBank.filter(
          (file: any) => file?.id !== deleteID
        );
        setTaskItearationFilesList(auxFiles);
        setCurrentTaskDetails({
          ...currentTaskDetails,
          taskIteration: [
            {
              ...currentTaskDetails.taskIteration[0],
              fileBank: auxFiles
            }
          ]
        });
      }

      setFireDeleteApi(false);
      setDeleteId('');
      setFindFiles(true);
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (taskIterationFilesData && !taskIterationFilesDataError) {
      setGetTaskIterationFiles(false);
      setFindFiles(true);
      setContDrpoKey(contDrpoKey + 1);
    }
  }, [taskIterationFilesData]);

  useEffect(() => {
    if (taskUpdatedData && !taskUpdatedError) {
      setUpdateTask(false);
      setCurrentTaskDetails({
        ...currentTaskDetails,
        status: newTaskStatus,
        taskIteration: [
          {
            ...currentTaskDetails.taskIteration[0],
            status: newTaskStatus
          }
        ]
      });

      setNewTaskStatus(newTaskStatus);
      if (newTaskStatus === TaskIterationStatusStatusEnum.InProgress) {
        setInProgressTask(true);
      } else {
        setInProgressTask(false);
      }

      if (calendarEventsDataMutate !== undefined) {
        calendarEventsDataMutate(undefined, true);
      }

      setGetCalendarEventsMobile(true);

      if (closeAfterComplete && taskUpdatedData.status === 'completed')
        setTaskDetailsData({});
    }
  }, [taskUpdatedData]);

  useEffect(() => {
    if (checklistUpdatedData && !checklistUpdatedError) {
      const auxChecklist = currentTaskDetails?.taskIteration[0]?.checkList;
      auxChecklist[checklistIndex] = {
        ...auxChecklist[checklistIndex],
        checked: newChecklistStatus
      };

      setUpdateChecklist(0);
      setChecklistIndex(-1);

      setCurrentTaskDetails({
        ...currentTaskDetails,
        taskIteration: [
          {
            ...currentTaskDetails?.taskIteration[0],
            checkList: auxChecklist
          }
        ]
      });
    }
  }, [checklistUpdatedData]);

  useEffect(() => {
    let okChecklist = false;
    let okImage = false;

    if (currentTaskDetails && currentTaskDetails?.id) {
      if (
        currentTaskDetails?.checklist &&
        currentTaskDetails?.checklist.length === 0
      ) {
        okChecklist = true;
      }

      if (
        currentTaskDetails?.taskIteration &&
        currentTaskDetails?.taskIteration[0] &&
        currentTaskDetails?.taskIteration[0]?.checkList.length > 0 &&
        currentTaskDetails?.taskIteration[0]?.checkList.filter(
          (item: any) => !item.checked
        ).length === 0
      ) {
        okChecklist = true;
      }

      if (!currentTaskDetails.isImageRequired) {
        okImage = true;
      } else if (currentTaskDetails?.taskIteration[0]?.fileBank.length > 0) {
        okImage = true;
      }

      if (okChecklist && okImage) {
        if (
          editable &&
          currentTaskDetails?.taskIteration &&
          currentTaskDetails?.taskIteration[0] &&
          (currentTaskDetails?.taskIteration[0]?.status ===
            TaskIterationStatusStatusEnum.InProgress ||
            currentTaskDetails?.taskIteration[0]?.status ===
              TaskIterationStatusStatusEnum.Created)
        ) {
          setDisabledButton(false);
        } else {
          setDisabledButton(true);
        }
      } else if (
        editable &&
        currentTaskDetails?.taskIteration &&
        currentTaskDetails?.taskIteration[0] &&
        currentTaskDetails?.taskIteration[0]?.status ===
          TaskIterationStatusStatusEnum.Created
      ) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
    }
  }, [currentTaskDetails]);

  useEffect(() => {
    if (
      uploadFileResponse &&
      uploadFileResponse?.data &&
      !uploadFileResponseError
    ) {
      setFireAPIUploadDocs(false);
      const auxFiles: any[] = [];

      uploadFileResponse.data.forEach((file: any) => {
        if (
          !auxFiles.find(
            (auxFile) => auxFile?.fileOriginalName === file?.fileOriginalName
          )
        ) {
          auxFiles.push({
            ...file,
            name: file?.fileOriginalName,
            [file?.photoSize]: file?.fileUrl
          });
        } else {
          const fileIndex = auxFiles.findIndex(
            (auxFile) => auxFile?.fileOriginalName === file?.fileOriginalName
          );
          auxFiles[fileIndex] = {
            ...auxFiles[fileIndex],
            [file?.photoSize]: file?.fileUrl
          };
        }
      });

      setTaskItearationFilesList([...taskItearationFilesList, auxFiles[0]]);

      setCurrentTaskDetails({
        ...currentTaskDetails,
        taskIteration: [
          {
            ...currentTaskDetails?.taskIteration[0],
            fileBank: [...taskItearationFilesList, auxFiles[0]]
          }
        ]
      });

      setTaskItearationFile({});
      setFindFiles(true);
    }
  }, [uploadFileResponse]);

  const getGeofencingColorIndicator = () => {
    if (geofencingData.hasPermission) {
      if (geofencingData.geofencingApproved) {
        return successColors.SUCCESS;
      }
      return warningColors.ERROR;
    }
    return secondaryColors.SECONDARY;
  };

  const getLocationStatus = (): string => {
    if (geofencingData.hasPermission && geofencingData.geofencingApproved) {
      return t('GEOFENCING.locationConfirmed');
    }
    if (
      geofencingData.hasPermission &&
      geofencingData.geofencingApproved === false
    ) {
      return t('GEOFENCING.locationNotConfirmed');
    }
    return t('GEOFENCING.locationNotAllowed');
  };

  const fetchAddressCoords = async () => {
    try {
      const { lat, long } = await getUserLocation();

      const auxDetailsAddress = String(taskDetails?.address) || '';
      const addresses = await Api.MapsApi.searchDirection(
        auxDetailsAddress.replace('#', '')
      );
      if (addresses && addresses.features && addresses.features.length > 0) {
        const topAddress = addresses.features[0];
        const addressLat = topAddress.center[1];
        const addressLong = topAddress.center[0];

        const geofencingApproved = checkGeofence(lat, long, {
          lat: addressLat,
          long: addressLong,
          measureType: taskDetails.geofencingMeasure,
          radiusDistance: taskDetails.geofencingRadius
        });

        setGeofencingData({
          geofencingApproved,
          hasPermission: true,
          lat,
          long
        });
      }
    } catch (error) {
      setGeofencingData({
        geofencingApproved: false,
        hasPermission: false,
        lat: 0,
        long: 0
      });
    }
  };

  useEffect(() => {
    const auxStatus = getTaskStatus(taskDetails);

    setTaskItearationFilesList([]);
    const calcStimatedTime =
      taskDetails.measure === 'Unit'
        ? taskDetails.quantity *
          taskDetails.measurePerHour *
          taskDetails.minimumResources
        : (taskDetails.quantity / taskDetails.measurePerHour) *
          taskDetails.minimumResources;

    const taskTimeCalcs =
      taskDetails?.taskIteration && taskDetails?.taskIteration[0]
        ? formatTimesAndCalculateDifference(
            taskDetails?.taskIteration[0]?.startTime,
            taskDetails?.taskIteration[0]?.endTime || new Date().toISOString(),
            userState.timeFormat
          )
        : { differenceInMinutes: 0 };

    setStimatedTime(calcStimatedTime * 60);

    const workedTime = taskTimeCalcs?.differenceInMinutes || 0;

    setTotalTime(
      auxStatus === TaskIterationStatusStatusEnum.Created ? 0 : workedTime
    );

    if (auxStatus === TaskIterationStatusStatusEnum.InProgress) {
      setInProgressTask(true);
    }

    setCurrentTaskDetails({
      ...taskDetails,
      status: auxStatus,
      taskWorkedTime: workedTime
    });
    setGetTaskIterationFiles(true);
    fetchAddressCoords();
  }, []);

  const handleDownload = (documentFile: any) => {
    const link = document.createElement('a');
    link.href = documentFile?.fileUrl;
    link.download = documentFile?.fileOriginalName || 'downloaded-file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleToggleAll = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStatus = event.target.checked;
    const taskIteration = currentTaskDetails?.taskIteration[0];

    if (!taskIteration) return;

    const tasksToUpdate = taskIteration.checkList.filter(
      (item: Task) => item.checked !== newStatus
    );

    if (tasksToUpdate.length === 0) return;

    setCurrentTaskDetails({
      ...currentTaskDetails,
      taskIteration: [
        {
          ...taskIteration,
          checkList: taskIteration?.checkList.map((item: Task) => ({
            ...item,
            checked: newStatus
          }))
        }
      ]
    });

    await Promise.all(
      tasksToUpdate.map(async (item: Task) => {
        return Api.CalendarApi.calendarEventsUpdateTaskIterationChecklistStatus(
          {
            checklistId: item.id,
            status: newStatus
          }
        );
      })
    );
  };

  const callUpdateChecklist = (
    checklistId: number,
    event: any,
    index: number
  ) => {
    const inputStatus = event.target.checked;
    setChecklistIndex(index);
    setNewChecklistStatus(inputStatus);
    setUpdateChecklist(checklistId);
  };

  const documentRow = (documents: any) => {
    return (
      <>
        {documents.length > 0 &&
          documents.map((document: any, index: number) => {
            const auxKey = `document-list-item-parent-${index}-${Math.random()}`;
            return (
              <ListItem key={auxKey}>
                <ColapseHead
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    paddingBottom: '0.5rem',
                    paddingRight: '1rem',
                    paddingTop: '0.5rem'
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      marginLeft: '0.25rem'
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        backgroundColor: accentColors.ACCENT_3,
                        borderRadius: '4px',
                        marginRight: '0.5rem',
                        padding: '0.5rem'
                      }}
                    >
                      <FolderOutlinedIcon
                        style={{
                          color: textColors.TEXT,
                          display: 'flex',
                          fontSize: 25,
                          fontWeight: 'normal'
                        }}
                      />
                    </Box>
                    <Typography
                      color={textColors.DARK_TEXT}
                      fontFamily="Lexend"
                      fontSize="16px"
                      fontWeight="400"
                    >
                      {document?.fileOriginalName}
                    </Typography>
                  </Box>
                  <FileDownloadOutlinedIcon
                    onClick={() => handleDownload(document)}
                    style={{
                      color: surfaceColors.SURFACE,
                      fontSize: 25,
                      fontWeight: 'normal'
                    }}
                  />
                </ColapseHead>
              </ListItem>
            );
          })}{' '}
      </>
    );
  };

  const printFiles = () => {
    const elements: JSX.Element[] = [];

    Object.entries(taskFiles).forEach(([key, items]) => {
      (items as any).forEach((item: any) => {
        elements.push(
          <ListItem
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <ListItemText
              primary={`  ${item.fileOriginalName}`}
              primaryTypographyProps={{
                maxWidth: '250px',
                overflow: 'hidden',
                paddingLeft: '10px',
                paddingTop: '0px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              style={{}}
            />
            <a href={item.fileUrl}>
              <i
                className="custom-icon icon-download"
                style={{
                  alignSelf: 'flex-end',
                  borderTop: '10px',
                  color: '#005DA2',
                  fontSize: 24,
                  marginTop: '15px',
                  paddingRight: '20px',
                  paddingTop: '15px'
                }}
              />
            </a>
          </ListItem>
        );
      });
    });

    return elements;
  };
  const handleDelete = (fileId: string) => {
    if (fileId) {
      setDeleteId(fileId);
      setFireDeleteApi(true);
    }
  };

  const getDocumentItem = () => {
    return <Box>s</Box>;
  };

  return (
    <MainContainer>
      <RowContainer>
        <Typography color="primary.400" fontSize={24} variant="heading_300">
          <ArrowBackIos
            fill="grey"
            onClick={() => {
              if (manualClose) {
                setGetCalendarEventsMobile(true);
                calendarEventsDataMutate(undefined, true);
                setTaskDetailsData(false);
              } else {
                setTaskDetailsData({});
              }
            }}
            style={{ marginBottom: '-4px' }}
          />
          Task details
        </Typography>
      </RowContainer>
      <WhiteContainer>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Chip
              color={
                // eslint-disable-next-line no-nested-ternary
                currentTaskDetails.status ===
                `${TaskIterationStatusStatusEnum.Completed}`
                  ? 'success'
                  : currentTaskDetails.status ===
                    `${TaskIterationStatusStatusEnum.InProgress}`
                  ? 'warning'
                  : 'primary'
              }
              label={
                <Box
                  sx={{
                    alignItems: 'left',
                    justifyContent: 'left'
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        // eslint-disable-next-line no-nested-ternary
                        currentTaskDetails.status ===
                        TaskIterationStatusStatusEnum.InProgress
                          ? warningColors.ERROR
                          : currentTaskDetails.status ===
                            `${TaskIterationStatusStatusEnum.Completed}`
                          ? `${successColors.SUCCESS}`
                          : `${mainColors.MAIN}`
                    }}
                    variant="button_100"
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      currentTaskDetails.status ===
                      TaskIterationStatusStatusEnum.Completed
                        ? 'Completed'
                        : currentTaskDetails.status ===
                          TaskIterationStatusStatusEnum.InProgress
                        ? 'In progress'
                        : 'Incoming'
                    }
                  </Typography>
                </Box>
              }
              size="small"
              sx={{
                backgroundColor:
                  // eslint-disable-next-line no-nested-ternary
                  currentTaskDetails.status ===
                  TaskIterationStatusStatusEnum.InProgress
                    ? `${warningColors.LIGHT_ERROR} !important`
                    : currentTaskDetails.status ===
                      TaskIterationStatusStatusEnum.Completed
                    ? `${successColors.SUCCESS_LIGHT} !important`
                    : `${mainColors.MAIN_LIGHT} !important`
              }}
              variant="outlined"
            />
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              marginTop: '1rem',
              width: '100%'
            }}
          >
            <Typography color="primary.main" fontSize="24px" variant="title">
              {currentTaskDetails?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: 'groove',
              borderBottomWidth: '1px',
              display: 'flex',
              width: '100%'
            }}
          >
            <Typography
              color={surfaceColors.SURFACE}
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              marginBottom="1rem"
              marginTop="0.5rem"
              textAlign="justify"
              variant="title"
            >
              {currentTaskDetails?.description}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'flex-star',
              borderBottom: 'groove',
              borderBottomWidth: '1px',
              display: 'flex',
              justifyContent: 'space-between',
              pb: '1rem',
              pt: '1rem',
              width: '100%'
            }}
          >
            <Box
              sx={{
                alignItems: 'left',
                justifyContent: 'left'
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography
                  color={surfaceColors.SURFACE}
                  fontSize="12px"
                  fontWeight="700"
                  sx={{ minWidth: '110px' }}
                >
                  Scheduled time:
                </Typography>
                <Typography
                  color={surfaceColors.SURFACE}
                  fontSize="12px"
                  fontWeight="700"
                >
                  {formatSumTime(stimatedTime || 0)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', marginTop: '0.5rem' }}>
                <Typography
                  color={surfaceColors.SURFACE}
                  fontSize="12px"
                  fontWeight="700"
                  sx={{ minWidth: '110px' }}
                >
                  Current time
                </Typography>

                <Typography
                  color={surfaceColors.SURFACE}
                  fontSize="12px"
                  fontWeight="700"
                >
                  {formatSumTime(totalTime || 0)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'right'
              }}
            >
              <Chip
                label={`${currentTaskDetails.measure} ${parseInt(
                  currentTaskDetails.quantity,
                  10
                ).toString()}`}
                sx={{
                  backgroundColor: surfaceColors.SURFACE_6,
                  color: surfaceColors.SURFACE,
                  fontSize: '16px',
                  fontWeight: '400'
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'inline-flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Box>
              <Button
                onClick={() => {
                  if (setNoteProjectId) {
                    setNoteProjectId(currentTaskDetails.projectId);
                  }
                }}
                variant="text"
              >
                <Typography color="primary.main" variant="primary">
                  <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                    <i className="custom-icon icon-note" />
                    {t('NOTES.mobileTaskTitle')}
                  </Box>
                </Typography>
              </Button>
            </Box>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography
                className={`${
                  getGeofencingColorIndicator() === successColors.SUCCESS
                    ? 'ripple-animation'
                    : ''
                } `}
                color={getGeofencingColorIndicator()}
                fontSize={12}
                onClick={() => {
                  setToastNotification({
                    text: getLocationStatus(),
                    type: `${
                      // eslint-disable-next-line no-nested-ternary
                      getGeofencingColorIndicator() === successColors.SUCCESS
                        ? 'success'
                        : getGeofencingColorIndicator() === warningColors.ERROR
                        ? 'warning'
                        : 'info'
                    }`
                  });
                }}
                variant="secondary"
              >
                <i className="custom-icon icon-location" />
              </Typography>
            </Box>
          </Box>
          {/* <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box
                sx={{
                  alignItems: 'left',
                  justifyContent: 'left',
                  paddingRight: '2rem'
                }}
              >
                <Box sx={{ display: 'block' }}>
                  <Typography
                    color={textColors.DARK_TEXT}
                    fontSize="12px"
                    fontWeight="700"
                  >
                    Supervirsor
                  </Typography>
                </Box>
                <Box sx={{ display: 'block', marginTop: '0.5rem' }}>
                  {currentTaskDetails?.supervisors?.length > 0 && (
                    <Box alignItems="center" display="flex" gap="0.5rem">
                      {' '}
                      <AvatarGroup
                        max={4}
                        users={currentTaskDetails?.supervisors}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: 'block',
                  justifyContent: 'right'
                }}
              >
                <Box sx={{ display: 'block' }}>
                  <Typography
                    color={textColors.DARK_TEXT}
                    fontSize="12px"
                    fontWeight="700"
                  >
                    Team
                  </Typography>
                </Box>
                <Box sx={{ display: 'block', marginTop: '0.5rem' }}>
                  {currentTaskDetails?.technicians?.length > 0 && (
                    <Box alignItems="center" display="flex" gap="0.5rem">
                      <AvatarGroup
                        max={4}
                        users={currentTaskDetails?.technicians}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid> */}
        </Box>
      </WhiteContainer>
      <Box key="taskDetails-tabs-main-box">
        <Tabs
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setStep(newValue);
          }}
          sx={{
            '.MuiTab-root': {
              maxWidth: 'none',
              width: '50%'
            },
            '.MuiTabs-indicator': {
              width: '100%'
            },
            backgroundColor: 'white'
          }}
          value={step}
        >
          <Tab label="Information" value={0} />
          <Tab label="Documents" value={1} />
        </Tabs>
      </Box>
      {step === 0 && (
        <Box key="taskDetails-step-0-information-main-box">
          <Box key="taskDetails-step-0-materias-main-box">
            <ListHead>
              <ListRow>
                <RowElement onClick={() => setOpenMaterials(!openMaterials)}>
                  <ColapseHead>
                    <Box>
                      <ListTitle variant="primary">List of materials</ListTitle>
                      <CantChip>
                        {currentTaskDetails?.materials?.length || 0}
                      </CantChip>
                    </Box>
                    <ListIconContainer>
                      <Button onClick={() => setOpenMaterials(!openMaterials)}>
                        <i
                          className="custom-icon icon-select"
                          style={{
                            fontSize: 18,
                            transform: `rotate(${
                              openMaterials ? '180deg' : '0deg'
                            })`
                          }}
                        />
                      </Button>
                    </ListIconContainer>
                  </ColapseHead>
                </RowElement>
              </ListRow>
            </ListHead>

            {currentTaskDetails?.materials?.length > 0 && (
              <Collapse in={openMaterials}>
                <Box sx={{ padding: '34px 22px' }}>
                  {currentTaskDetails?.materials.map((material: any) => (
                    <ListItem
                      key={`mobile-task-detail-material-${Math.random()}`}
                    >
                      <ItemText>{material?.name}</ItemText>
                    </ListItem>
                  ))}
                </Box>
              </Collapse>
            )}
          </Box>

          {currentTaskDetails?.checklist &&
            currentTaskDetails?.checklist.length > 0 && (
              <Box key="taskDetails-step-0-checkList-main-box">
                <Box>
                  <ListHead>
                    <ListRow>
                      <RowElement
                        onClick={() => setOpenChecklist(!openChecklist)}
                      >
                        <ColapseHead>
                          <Box>
                            <ListTitle variant="primary">Checklist</ListTitle>
                            <CantChip>
                              {currentTaskDetails?.taskIteration[0]?.checkList
                                .length || 0}
                            </CantChip>
                          </Box>
                          <ListIconContainer>
                            <Button
                              onClick={() => setOpenChecklist(!openChecklist)}
                            >
                              <i
                                className="custom-icon icon-select"
                                style={{
                                  fontSize: 18,
                                  transform: `rotate(${
                                    openChecklist ? '180deg' : '0deg'
                                  })`
                                }}
                              />
                            </Button>
                          </ListIconContainer>
                        </ColapseHead>
                      </RowElement>
                    </ListRow>
                  </ListHead>
                  {currentTaskDetails?.status !== 'created' &&
                  currentTaskDetails?.taskIteration.length > 0 &&
                  currentTaskDetails?.taskIteration[0]?.checkList &&
                  currentTaskDetails?.taskIteration[0]?.checkList.length > 0 ? (
                    <Collapse
                      in={openChecklist}
                      key="task-details-checklist-iteration-colapse"
                    >
                      <Box sx={{ padding: '34px 22px' }}>
                        {currentTaskDetails?.taskIteration[0] && (
                          <ListItem isTransparent>
                            <ItemCheckbox
                              checked={allTasksCompleted}
                              onChange={(event) => {
                                if (
                                  !manualClose ||
                                  (manualClose &&
                                    currentTaskDetails?.taskIteration[0]
                                      ?.status ===
                                      TaskIterationStatusStatusEnum.InProgress)
                                ) {
                                  handleToggleAll(event);
                                }
                              }}
                            />
                            <ItemText>
                              {t('TECHNICIAN_VIEW.DETAILS.selectAll')}
                            </ItemText>
                          </ListItem>
                        )}
                        {currentTaskDetails?.taskIteration[0]?.checkList.map(
                          (checkItem: any, index: number) => {
                            const auxKey = `checklist-list-item-iteration-${index}-${Math.random()}`;
                            return (
                              <ListItem key={auxKey}>
                                <ItemCheckbox
                                  checked={checkItem?.checked}
                                  onChange={(event) => {
                                    if (
                                      !manualClose ||
                                      (manualClose &&
                                        currentTaskDetails?.taskIteration[0]
                                          ?.status ===
                                          TaskIterationStatusStatusEnum.InProgress)
                                    ) {
                                      callUpdateChecklist(
                                        checkItem?.id,
                                        event,
                                        index
                                      );
                                    }
                                  }}
                                />
                                <ItemText>{checkItem?.name}</ItemText>
                              </ListItem>
                            );
                          }
                        )}
                      </Box>
                    </Collapse>
                  ) : (
                    <Collapse
                      in={openChecklist}
                      key="task-details-checklist-parent-colapse"
                    >
                      <Box sx={{ padding: '2rem 2rem' }}>
                        {currentTaskDetails?.checklist.map(
                          (checkItem: any, index: number) => {
                            const auxKey = `checklist-list-item-parent-${index}-${Math.random()}`;
                            return (
                              <ListItem key={auxKey}>
                                <ItemCheckbox
                                  disabled
                                  checked={false}
                                  onChange={() => {}}
                                />
                                <ItemText>{checkItem?.name}</ItemText>
                              </ListItem>
                            );
                          }
                        )}
                      </Box>
                    </Collapse>
                  )}
                </Box>
              </Box>
            )}
          <Box key="taskDetails-step-0-images-main-box">
            <ListHead>
              <ListRow>
                <RowElement onClick={() => setOpenImages(!openImages)}>
                  <ColapseHead>
                    <Box>
                      <ListTitle variant="primary">
                        Images
                        {currentTaskDetails.isImageRequired && '*'}
                      </ListTitle>
                    </Box>
                    <ListIconContainer>
                      <Button onClick={() => setOpenImages(!openImages)}>
                        <i
                          className="custom-icon icon-select"
                          style={{
                            fontSize: 18,
                            transform: `rotate(${
                              openImages ? '180deg' : '0deg'
                            })`
                          }}
                        />
                      </Button>
                    </ListIconContainer>
                  </ColapseHead>
                </RowElement>
              </ListRow>
            </ListHead>
            {currentTaskDetails?.taskIteration &&
              currentTaskDetails?.taskIteration[0] &&
              currentTaskDetails?.taskIteration[0]?.id &&
              (isValidatingDelete ||
                isValidatingUpload ||
                isValidatingTaskIteration ||
                fireAPIUploadDocs) && (
                <Loader customColor="primary" marginTop="2rem" />
              )}
            <Collapse in={openImages}>
              {findFiles &&
                taskItearationFilesList.length > 0 &&
                currentTaskDetails &&
                currentTaskDetails?.taskIteration &&
                currentTaskDetails?.taskIteration.length > 0 &&
                currentTaskDetails?.taskIteration[0]?.fileBank.length > 0 && (
                  <Box
                    key={`task-details-image-box-${currentTaskDetails?.taskIteration[0]?.id}`}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      maxHeight: 300,
                      padding: '0rem 0.75rem 0rem 0.75rem'
                    }}
                  >
                    {currentTaskDetails?.taskIteration[0]?.id &&
                      !isValidatingDelete &&
                      !isValidatingUpload &&
                      !isValidatingTaskIteration && (
                        <ImageList
                          cols={3}
                          gap={8}
                          key={`task-iteration-image-list-${currentTaskDetails?.taskIteration[0]?.id}`}
                          rowHeight={108}
                          sx={{
                            height: 'auto',
                            maxHeight: 245,
                            padding: '0.5rem 2rem 0.5rem 2rem',
                            width: 500
                          }}
                          variant="quilted"
                        >
                          {findFiles &&
                            currentTaskDetails?.taskIteration[0]?.fileBank
                              .length > 0 &&
                            taskItearationFilesList.length > 0 &&
                            taskItearationFilesList.map(
                              (file: any, index: number) => {
                                return (
                                  <ImageListItem
                                    cols={
                                      // eslint-disable-next-line no-nested-ternary
                                      currentTaskDetails?.taskIteration[0]
                                        ?.fileBank.length <= 3 && index === 0
                                        ? 2
                                        : 1
                                    }
                                    key={`task-details-ImageListItem-${file?.id}`}
                                    rows={
                                      currentTaskDetails?.taskIteration[0]
                                        ?.fileBank.length <= 3 && index === 0
                                        ? 2
                                        : 1
                                    }
                                    style={{
                                      alignItems: 'center',
                                      position: 'relative',
                                      textAlign: 'center'
                                    }}
                                  >
                                    {findFiles && (
                                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                                      <img
                                        alt={file?.fileUrl}
                                        key={`taskIteration-image-${file?.id}}`}
                                        loading="eager"
                                        onClick={() => {
                                          setImagData(
                                            file?.large || file?.fileUrl
                                          );
                                          setIsOpen(true);
                                        }}
                                        src={`${
                                          currentTaskDetails?.taskIteration[0]
                                            ?.fileBank.length <= 3 &&
                                          index === 0
                                            ? file?.medium || file?.fileUrl
                                            : file?.small || file?.fileUrl
                                        }`}
                                        style={{
                                          borderRadius: '8px',
                                          height: '100%',
                                          objectFit: 'cover',
                                          width: 'fitConten !important'
                                        }}
                                        tabIndex={-1}
                                      />
                                    )}
                                    {currentTaskDetails.status !==
                                      TaskIterationStatusStatusEnum.Completed &&
                                      editable && (
                                        <CancelRoundedIcon
                                          key={`task-details-cancel-icon-${currentTaskDetails?.taskIteration[0].id}-${file?.id}`}
                                          onClick={() => {
                                            if (
                                              currentTaskDetails.status ===
                                              TaskIterationStatusStatusEnum.InProgress
                                            ) {
                                              handleDelete(file?.id);
                                            }
                                          }}
                                          sx={{
                                            backgroundColor:
                                              'rgb(255 255 255 / 67%)',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            fill: 'black',
                                            fontSize: '2.5rem',
                                            position: 'absolute',
                                            right: 0,
                                            top: 0
                                          }}
                                        />
                                      )}
                                  </ImageListItem>
                                );
                              }
                            )}
                        </ImageList>
                      )}
                  </Box>
                )}
              <Box
                key="taskDetails-step-0-notes-dropzone-box"
                sx={{ padding: '32px 7.5px' }}
              >
                <Dropzone
                  isMobile
                  disabledDrop={
                    currentTaskDetails.status !==
                    TaskIterationStatusStatusEnum.InProgress
                  }
                  filesList={[]}
                  key={`dropzone-task-iteration-${contDrpoKey}`}
                  multiple={false}
                  parent="taskDetails"
                  setFiles={(file) => {
                    setTaskItearationFile(file);
                    setFireAPIUploadDocs(true);
                    setContDrpoKey(contDrpoKey + 1);
                  }}
                  type="image"
                />
              </Box>
            </Collapse>
          </Box>
          <Box key="taskDetails-step-0-notes-main-box">
            <ListHead>
              <ListRow>
                <RowElement onClick={() => setOpenNotes(!openNotes)}>
                  <ColapseHead>
                    <Box>
                      <ListTitle variant="primary">Notes</ListTitle>
                    </Box>
                    <ListIconContainer>
                      <Button onClick={() => setOpenNotes(!openNotes)}>
                        <i
                          className="custom-icon icon-select"
                          style={{
                            fontSize: 18,
                            transform: `rotate(${
                              openNotes ? '180deg' : '0deg'
                            })`
                          }}
                        />
                      </Button>
                    </ListIconContainer>
                  </ColapseHead>
                </RowElement>
              </ListRow>
            </ListHead>

            <Collapse
              in={openNotes}
              sx={{
                '.textAreaInput-taskDetails': {
                  backgroundColor: `${textColors.TEXT} !important`,
                  fontSize: '16px !important',
                  padding: '0px !important'
                }
              }}
            >
              <Box padding={2}>
                <Notes
                  hideDeleteButton
                  technicianNotes
                  entity="taskIteration"
                  id={currentTaskDetails?.taskIteration?.[0]?.id}
                />
              </Box>
            </Collapse>
          </Box>
        </Box>
      )}
      {step === 1 && (
        <Box key="taskDetails-step-1-documents-main-box">
          <ListHead>
            <ListRow>
              <RowElement onClick={() => {}}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Box>
                    <ListTitle>List of documents</ListTitle>
                  </Box>
                </Box>
              </RowElement>
            </ListRow>
          </ListHead>
          <Box sx={{ padding: '1rem 2rem' }}>
            <Box sx={{ padding: '10px' }}>
              <List
                subheader={<li />}
                sx={{
                  maxHeight: 300,
                  // bgcolor: 'background.paper',
                  maxWidth: 360,
                  overflow: 'auto'
                }}
              >
                {taskFiles && printFiles()}
              </List>
            </Box>
          </Box>
        </Box>
      )}

      {currentTaskDetails &&
        currentTaskDetails.status !== TaskIterationStatusStatusEnum.Completed &&
        editable && (
          <WhiteContainer
            sx={{
              borderTop: 'none',
              paddingBottom: '2rem',
              paddingTop: '2rem'
            }}
          >
            {currentTaskDetails.status ===
              TaskIterationStatusStatusEnum.Created && (
              <Button
                fullWidth
                disabled={disabledButton}
                onClick={() => {
                  if (taskDetails.projectId) {
                    locationHistoryCreateMutate(
                      Api.LocationHistoryApi.createLocationHistory({
                        action: TechnicianMainActionsEnum.StartTask,
                        calendarEventId:
                          currentTaskDetails?.taskIteration[0]?.calendarEvents
                            .id,
                        projectId: taskDetails.projectId,
                        taskIterationId: currentTaskDetails.taskIteration[0]?.id
                      })
                    );
                  }
                  setNewTaskStatus(TaskIterationStatusStatusEnum.InProgress);
                  setInProgressTask(true);
                  setDisabledButton(true);
                  setUpdateTask(true);
                  setOpenMaterials(true);
                  setOpenChecklist(true);
                }}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '1rem'
                }}
                variant="primary"
              >
                {updateTask && <Loader customColor="primary" />}
                {!updateTask && 'Start task'}
              </Button>
            )}

            {currentTaskDetails.status ===
              TaskIterationStatusStatusEnum.InProgress && (
              <Button
                fullWidth
                disabled={disabledButton}
                onClick={() => {
                  if (taskDetails.projectId) {
                    locationHistoryCreateMutate(
                      Api.LocationHistoryApi.createLocationHistory({
                        action: TechnicianMainActionsEnum.Completed,
                        calendarEventId:
                          currentTaskDetails?.taskIteration[0]?.calendarEvents
                            .id,
                        projectId: taskDetails.projectId,
                        taskIterationId: currentTaskDetails.taskIteration[0]?.id
                      })
                    );
                  }
                  setNewTaskStatus(TaskIterationStatusStatusEnum.Completed);
                  setInProgressTask(false);
                  setDisabledButton(true);
                  setUpdateTask(true);
                }}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '1rem'
                }}
                variant="tertiary"
              >
                {updateTask && <Loader customColor="primary" />}
                {!updateTask && 'Completed'}
              </Button>
            )}
          </WhiteContainer>
        )}
      {isOpen && imgData !== '' && (
        <ImageViewer isOpen={isOpen} setIsOpen={setIsOpen} url={imgData} />
      )}
      {toastNotification && (
        <ToastNotification
          onClose={() => setToastNotification(undefined)}
          text={toastNotification.text}
          type={toastNotification.type}
        />
      )}
    </MainContainer>
  );
};

TechnicianTaskDetails.defaultProps = {
  closeAfterComplete: false,
  setNoteProjectId: (id) => {}
};

export default TechnicianTaskDetails;
