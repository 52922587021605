/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps } from 'react';
import { Select, SxProps, Theme } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

interface VariantProps extends ComponentProps<typeof Select> {
  backgroundColor?: string;
  height?: string;
  // eslint-disable-next-line react/require-default-props
  selectvariant?: 'secondary' | 'default';
  sx?: SxProps<Theme>;
}

const SelectInput: React.FC<VariantProps> = function SelectInput({
  children,
  height,
  backgroundColor,
  sx,
  ...props
}) {
  return (
    <Select
      {...props}
      IconComponent={ExpandMoreOutlinedIcon}
      sx={{
        '.MuiSelect-icon': {
          color: '#7D8090'
        },
        backgroundColor: backgroundColor || 'white',
        borderRadius: '0.5em',
        borderWidth: 0,
        height: height || '48px !important',
        marginBottom: '16px',
        marginRight: '16px',
        marginTop: '16px',
        textAlign: 'left',
        width: '100%',
        ...sx
      }}
    >
      {children}
    </Select>
  );
};

SelectInput.defaultProps = {
  backgroundColor: '',
  height: undefined,
  sx: {}
};

export default SelectInput;
