// FilterSelect.tsx

import React from 'react';
import { Box, MenuItem, SxProps, Theme } from '@mui/material';
import SelectInput from '../form/SelectInput';

function FilterSelect({
  label,
  value,
  options,
  onChange,
  width = '120px',
  sx
}: {
  label: string;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  options: { id: string; name: string }[];
  sx?: SxProps<Theme>;
  value: string;
  // eslint-disable-next-line react/require-default-props
  width?: string;
}) {
  return (
    <Box width={width || '120px'}>
      <SelectInput fullWidth onChange={onChange} sx={sx} value={value}>
        <MenuItem disabled value={label.toLowerCase()}>
          {label}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </SelectInput>
    </Box>
  );
}

FilterSelect.defaultProps = {
  sx: {}
};

export default FilterSelect;
