/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import CustomRadio from '../../../../../components/form/CustomRadio';
import Filter from '../../../../../components/list/Filter';
import { weekDays } from '../../../../../helpers/weekDays';
import DaysOfTheWeek from '../../../../../components/input/DaysOfTheWeek';
import { getCurrency } from '../../../../../utils/general';
import Loader from '../../../../../components/shared/Loader';
import { FormLabel } from '../../../../../components/form/parts';
import DatePickerTextField from '../DatePickerTextField';
import {
  secondaryColors,
  themePalette
} from '../../../../../theme/themeConfig';

interface Props {
  clientView?: boolean;
  deleteServiceTime: () => void;
  resetValues: () => void;
  serviceTime: any;
  setRecalculateApi: () => void;
  setServiceTime: (a: any) => void;
}

const CounterOfferCard: React.FC<Props> = function CounterOfferCard({
  serviceTime,
  resetValues,
  deleteServiceTime,
  setServiceTime,
  setRecalculateApi,
  clientView
}) {
  console.log('isMobile', isMobile);
  const { palette } = useTheme();
  const weekdays = useMemo(() => {
    return weekDays.map((day: any) => {
      return day.name;
    });
  }, []);
  const { t } = useTranslation();
  const readOnly = !clientView;
  const theme = useTheme();

  const getCounterOfferTrend = () => {
    switch (serviceTime?.actionToCounterOffer) {
      case 'discount':
        return (
          <Typography
            color="success.main"
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <TrendingDown />
          </Typography>
        );
      case 'penalization':
        return (
          <Typography
            color="error.main"
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <TrendingUp />
          </Typography>
        );
      case 'notApplicable':
        return (
          <Typography
            color="secondary.main"
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <TrendingFlat />
          </Typography>
        );
      default:
        break;
    }
    return <Box width={25} />;
  };

  if (!(serviceTime?.id || serviceTime?.serviceTimeId)) {
    return <Loader customColor="primary" customSize={24} />;
  }

  return (
    <Box
      sx={{
        mb: 2,
        mt: 2
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={isMobile ? 4 : 6}
        sx={{
          border: 1,
          borderColor: palette.divider,
          borderRadius: 4,
          p: 3
        }}
      >
        {serviceTime?.services && (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              color="primary"
              fontWeight={700}
              sx={{ width: '30%' }}
              variant="heading_300"
            >
              {t('LEAD.CREATE.SERVICES.SUMMARY.service')}:
            </Typography>
            <Typography
              color="secondary.main"
              sx={{ textAlign: isMobile ? 'right' : 'left', width: '70%' }}
              variant="body_300"
            >
              {serviceTime?.services[0]?.name}
            </Typography>
          </Box>
        )}

        <Box
          alignItems={isMobile ? 'flex-start' : 'center'}
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 0}
          justifyContent="space-between"
        >
          <Typography
            color="primary"
            sx={{ width: '30%' }}
            variant="heading_300"
          >
            {t('LEAD.CREATE.SERVICES.SUMMARY.frequency')}:
          </Typography>
          <FormControl
            disabled={readOnly}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              justifyContent: 'flex-start',
              textAlign: 'right',
              width: isMobile ? '100' : '70%'
            }}
          >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event: any) => {
                setServiceTime({
                  ...serviceTime,
                  frequency: event.target.value
                });
              }}
              sx={{ alignSelf: 'left', display: 'flex' }}
            >
              <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                gap={isMobile ? 1 : 2}
              >
                <FormControlLabel
                  checked={serviceTime?.frequency === 'Weekly'}
                  control={<CustomRadio />}
                  label={
                    <Typography
                      color={secondaryColors.SECONDARY_4}
                      variant="primary"
                    >
                      {t('LEAD.CREATE.SERVICES.weekly')}
                    </Typography>
                  }
                  value="Weekly"
                />
                <FormControlLabel
                  checked={serviceTime?.frequency === 'Biweekly'}
                  control={<CustomRadio />}
                  label={
                    <Typography
                      color={secondaryColors.SECONDARY_4}
                      variant="primary"
                    >
                      {t('LEAD.CREATE.SERVICES.biweekly')}
                    </Typography>
                  }
                  value="Biweekly"
                />
                <FormControlLabel
                  checked={serviceTime?.frequency === 'Monthly'}
                  control={<CustomRadio />}
                  label={
                    <Typography
                      color={secondaryColors.SECONDARY_4}
                      variant="primary"
                    >
                      {t('LEAD.CREATE.SERVICES.monthly')}
                    </Typography>
                  }
                  value="Monthly"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>

        {serviceTime?.frequency && serviceTime?.frequency === 'Monthly' && (
          <Box
            alignItems={isMobile ? 'flex-start' : 'center'}
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 2 : 0}
            justifyContent="space-between"
          >
            <Typography
              color="primary"
              sx={{ width: isMobile ? '100%' : '25%' }}
              variant="heading_300"
            >
              {t('LEAD.CREATE.SERVICES.frequencyWeek')}:
            </Typography>

            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              gap={isMobile ? 1 : 2}
              width={isMobile ? '100%' : '75%'}
            >
              <Box
                alignItems={isMobile ? 'flex-start' : 'center'}
                display="flex"
                flexDirection="row"
                gap={1}
                width={isMobile ? '100%' : 'auto'}
              >
                <Filter
                  arrayInput={['First', 'Second', 'Third', 'Fourth']}
                  backgroundColor={themePalette.BACKGROUND}
                  customStyle={{ margin: 0 }}
                  disabled={readOnly}
                  selectInput={serviceTime?.frequencyWeek}
                  setSelectInput={(frequencyWeek) => {
                    setServiceTime({ ...serviceTime, frequencyWeek });
                  }}
                  title="Week"
                  width={isMobile ? '50%' : '130px'}
                />
                <Filter
                  arrayInput={weekdays}
                  backgroundColor={themePalette.BACKGROUND}
                  customStyle={{ margin: 0 }}
                  disabled={readOnly}
                  selectInput={serviceTime?.frequencyDays?.[0]}
                  setSelectInput={(values) => {
                    setServiceTime({
                      ...serviceTime,
                      frequencyDays: [values]
                    });
                  }}
                  title="Day"
                  width={isMobile ? '50%' : '130px'}
                />
              </Box>
              <Typography
                alignSelf={isMobile ? 'flex-start' : 'center'}
                color="secondary.main"
                variant="heading_300"
              >
                {t('LEAD.CREATE.SERVICES.frequencyWeek2')}
              </Typography>
            </Box>
          </Box>
        )}

        {serviceTime?.frequency !== 'Monthly' && (
          <Box
            alignItems="center"
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 2 : 0}
            justifyContent="space-between"
          >
            <Typography
              color="primary"
              sx={{ width: isMobile ? '100%' : '25%' }}
              variant="heading_300"
            >
              {t('LEAD.CREATE.SERVICES.daysOfTheWeek')}:
            </Typography>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              gap={0.5}
              width={isMobile ? '100%' : '75%'}
            >
              <Button
                disabled={readOnly}
                onClick={() => {
                  if (serviceTime?.frequencyDays.length === 7) {
                    setServiceTime({ ...serviceTime, frequencyDays: [] });
                  } else {
                    setServiceTime({
                      ...serviceTime,
                      frequencyDays: weekDays.map((day) => day.name)
                    });
                  }
                }}
                sx={{ alignSelf: 'flex-start' }}
              >
                <Typography
                  color="primary.main"
                  textTransform="capitalize"
                  variant="heading_328"
                >
                  {t('LEAD.CREATE.SERVICES.allDays')}
                </Typography>
              </Button>
              <DaysOfTheWeek
                disabled={readOnly}
                setValues={(frequencyDays: any) => {
                  setServiceTime({ ...serviceTime, frequencyDays });
                }}
                type="squareSmall"
                values={serviceTime?.frequencyDays || []}
              />
            </Box>
          </Box>
        )}

        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 0}
          justifyContent="space-between"
        >
          <Typography
            color="primary"
            sx={{ width: isMobile ? '100%' : '30%' }}
            variant="heading_300"
          >
            {t('LEAD.CREATE.SERVICES.timePeriod')}:
          </Typography>
          <Box
            alignItems="start"
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 2 : 6}
            width={isMobile ? '100%' : '70%'}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="4px"
              width={isMobile ? '100%' : 200}
            >
              <FormLabel label={t('LEAD.CREATE.startDate')} />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={readOnly}
                  maxDate={dayjs(serviceTime?.projectEnd) || undefined}
                  minDate={dayjs(serviceTime?.projectStart) || undefined}
                  onChange={(startDate) => {
                    setServiceTime({ ...serviceTime, startDate });
                  }}
                  renderInput={(params) => (
                    <DatePickerTextField error={false} {...params} />
                  )}
                  value={serviceTime?.startDate || serviceTime?.projectStart}
                />
              </LocalizationProvider>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="4px"
              width={isMobile ? '100%' : 200}
            >
              <FormLabel label={t('LEAD.CREATE.endDate')} />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={readOnly}
                  maxDate={dayjs(serviceTime?.projectEnd) || undefined}
                  minDate={dayjs(serviceTime?.projectStart) || undefined}
                  onChange={(endDate) => {
                    setServiceTime({ ...serviceTime, endDate });
                  }}
                  renderInput={(params) => <DatePickerTextField {...params} />}
                  value={dayjs(serviceTime?.endDate || serviceTime?.projectEnd)}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>

        {clientView && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-end',
              width: '100%'
            }}
          >
            <Button
              color="error"
              onClick={deleteServiceTime}
              sx={{ gap: 1, width: 200 }}
              variant="text"
            >
              <i className="custom-icon icon-delete" />
              Delete Service
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          border: 1,
          borderColor: palette.divider,
          borderRadius: 4,
          mt: 2,
          p: 2
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            gap: 2,
            justifyContent: clientView ? 'space-between' : 'center',
            width: '90%'
          }}
        >
          {clientView && (
            <Button
              onClick={setRecalculateApi}
              sx={{
                backgroundColor: 'transparent',
                width: isMobile ? '100%' : 150
              }}
              variant="tertiary-outlined"
            >
              {t('GENERAL.recalculateButton')}
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'space-between',
              width: isMobile ? '100%' : '75%'
            }}
          >
            {getCounterOfferTrend()}

            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              gap={isMobile ? 2 : 1}
            >
              <Typography
                color="primary"
                textTransform="capitalize"
                variant="heading_300"
              >
                {t('LEAD.CREATE.PROPOSAL.visits')}:{' '}
              </Typography>
              <Typography
                color="success.main"
                fontWeight={500}
                variant="body_400"
              >
                {serviceTime?.availability?.generalInfo?.daysOfServiceCount ||
                  serviceTime?.numberOfVisits ||
                  0}
              </Typography>
            </Box>

            <Divider
              flexItem
              orientation="vertical"
              sx={{ borderColor: secondaryColors.SECONDARY_1 }}
            />

            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              gap={isMobile ? 2 : 1}
            >
              <Typography color="primary" variant="heading_300">
                {t('LEAD.CREATE.PROPOSAL.totalPerVisit')}:{' '}
              </Typography>
              <Typography color="success.main" variant="body_400">
                {getCurrency(
                  serviceTime?.sellPrice || serviceTime?.pricePerVisit
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CounterOfferCard.defaultProps = { clientView: false };

export default CounterOfferCard;
