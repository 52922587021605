/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography,
  styled,
  SliderProps,
  BoxProps,
  Modal,
  Checkbox,
  SlideProps,
  Tooltip,
  Backdrop,
  Switch,
  FormGroup,
  Paper,
  useTheme,
  Divider,
  TextFieldProps,
  Tabs,
  Tab,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SendIcon from '@mui/icons-material/Send';
import ModeIcon from '@mui/icons-material/Mode';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import useSWR from 'swr';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import RegularInput from '../../../components/input/RegularInput';
import TableComponent from '../../../components/shared/TableComponent';
import CustomRadio from '../../../components/form/CustomRadio';
import Filter from '../../../components/list/Filter';
import SummaryService from './components/SummaryService';
import Chat from './components/Chat';
import TableComponentEditable from '../../../components/shared/TableComponentEditable';
import Api from '../../../api';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import OkModal from '../../../components/modals/OkModal';
import {
  getMargin,
  getMarkup,
  getTotalPerVisit,
  getTotalProject,
  getTotalService,
  parserLeadCalculator
} from '../../../utils/calculatorHelper';
import {
  getQuotedTotal,
  getTotalQuoteMarkup,
  getTotalQuotePrice
} from '../../../utils/proposalHelper';
import {
  capitalizeString,
  formatDate,
  getColor,
  getCurrency
} from '../../../utils/general';
import {
  mainColors,
  secondaryColors,
  successColors,
  textColors,
  themeFont,
  themePalette
} from '../../../theme/themeConfig';
import Loader from '../../../components/shared/Loader';
import ProposalTitle from '../../../components/proposal/Title';
import ProposalGeneralInformation from '../../../components/proposal/GeneralInformation';
import EditButton from '../../../components/list/EditButton';
import ResendEmailButton from '../../../components/list/ResendEmailButton';
import OpenDetailButton from '../../../components/list/OpenDetailButton';
import ProposalView from './ProposalView';
import RejectProposalModal from '../../../components/modals/RejectProposalModal';
import Banner from '../../../components/shared/Banner';
import WatchersModal from '../../../components/modals/WatchersModal';
import CounterOfferReviewModal from '../../../components/modals/CounterOfferReviewModal';
import ProposalMessagesModal from '../../../components/modals/ProposalMessagesModal';
import ToastNotification from '../../../components/shared/ToastNotification';
import { toastNotification } from '../../../utils/notificationHelper';
import useAuthStore from '../../../store/zustand/auth';
import { TimeFormatEnum } from '../../../api-client/generated';

const CustomTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    color: secondaryColors.SECONDARY_3,
    fontFamily: themeFont.FONT_SECONDARY,
    fontSize: '16px',
    fontWeight: 400
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${secondaryColors.SECONDARY_1}`
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderStyle: 'hidden'
  },
  backgroundColor: themePalette.BACKGROUND,
  paddingLeft: 0,
  paddingRight: 0
}));

const BoxCentered = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%'
}));

const DrawerButton = styled(Button)<SlideProps>(() => ({
  marginBottom: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  width: '100%'
}));

const InvoiceContainer = styled(Box)<BoxProps>(({ theme }) => ({
  border: `1px solid ${secondaryColors.SECONDARY_1}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px'
}));

const ConfigSection = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: '100%'
}));

const ButtonsContainer = styled(Box)<SliderProps>(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));

function maskData(value: number) {
  // eslint-disable-next-line no-restricted-globals
  if (typeof value === 'number' && !isNaN(value)) {
    return value.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  }
  return 'Value no valid';
}

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  height: '70vh',
  left: '50%',
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900
};

const styleModalAnalytics = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  height: '84vh',
  left: '50%',
  padding: '10px 20px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%'
};

const getProposalListTable = ({
  proposalListData,
  typeModal,
  setShowConfirModal,
  setTypeModal,
  setSelectProposal,
  t,
  setShowProposalInfo,
  setShowProposalDetailModal,
  timeFormat
}: any) => {
  return (
    <TableComponentEditable
      disableAdd
      action={() => {}}
      handleOpenDraw={() => {}}
      headKeys={[
        'seen',
        'LastSent',
        'Recipient',
        'status',
        'resent',
        'showDetail'
      ]}
      onChange={() => {}}
      valuesArray={{
        data:
          proposalListData?.length > 0
            ? proposalListData.map((proposal: any) => {
                return {
                  LastSent: (
                    <Typography variant="body_300">
                      {dayjs(proposal.updatedAt).format(
                        timeFormat === TimeFormatEnum._12h
                          ? 'MMM DD [at] h:mma'
                          : 'MMM DD [at] HH:mm'
                      )}
                    </Typography>
                  ),
                  Recipient: (
                    <Typography variant="body_300">
                      {proposal?.lead.client
                        ? `${proposal.lead.client.firstName} ${proposal.lead.client.lastName}`
                        : 'N/A'}
                    </Typography>
                  ),
                  recall:
                    proposal.status === 'created' ||
                    (proposal.status === 'awaiting' &&
                      typeModal === 'recall') ? (
                      <EditButton
                        handleOpenDraw={() => {
                          setShowConfirModal(true);
                          setTypeModal('recall');
                          setSelectProposal(proposal);
                        }}
                        tooltip={t('TOOLTIP.PROPOSAL.recallButton')}
                      />
                    ) : null,
                  resent:
                    proposal.status === 'created' ||
                    proposal.status === 'awaiting' ? (
                      <ResendEmailButton
                        handleOpenDraw={() => {
                          setTypeModal('resend');
                          setSelectProposal(proposal);
                          setShowConfirModal(true);
                        }}
                        tooltip={`${t('TOOLTIP.PROPOSAL.resendButton')}`}
                      />
                    ) : null,
                  seen: (
                    <Tooltip
                      title={t(
                        `TOOLTIP.PROPOSAL.proposal${
                          proposal.seen ? 'Seen' : 'Unseen'
                        }`
                      )}
                    >
                      <Typography
                        color={proposal.seen ? 'success.main' : 'alert.main'}
                        variant="primary-bold"
                      >
                        <i
                          className={`custom-icon icon-${
                            proposal.seen ? 'active' : 'clear'
                          }`}
                        />
                      </Typography>
                    </Tooltip>
                  ),
                  showDetail: (
                    <OpenDetailButton
                      handleOpenDraw={() => {
                        setShowProposalInfo(proposal);
                        setShowProposalDetailModal(true);
                      }}
                      icon="project"
                      tooltip={`${t('TOOLTIP.PROPOSAL.openDetailButton')}`}
                    />
                  ),
                  status: (
                    <Typography variant="body_300">
                      {capitalizeString(
                        `${proposal.status} - ${dayjs(
                          proposal.updatedAt
                        ).format(
                          timeFormat === TimeFormatEnum._12h
                            ? 'MMM DD [at] h:mma'
                            : 'MMM DD [at] HH:mm'
                        )}`
                      )}
                    </Typography>
                  )
                };
              })
            : []
      }}
    />
  );
};

interface Props {
  // eslint-disable-next-line react/require-default-props
  clientView?: boolean;
  prevProposalData: any;
  saveStepper: ({ next }: any) => void;
  setAcceptProposal: React.Dispatch<React.SetStateAction<any>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const Proposal: React.FC<Props> = function Proposal({
  prevProposalData,
  values,
  setValues,
  setAcceptProposal,
  saveStepper,
  setStep,
  clientView = false
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [proposalFormValue, setProposalFormValue] = useState<any>({});
  const [previewFlag, setPreviewFlag] = useState<boolean>(clientView || false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [rejected, setRejected] = useState<boolean>(false);
  const [openSuccessful, setOpenSuccessful] = useState<boolean>(false);
  const [sentFlag, setSentFlag] = useState<boolean>(false);
  const [canNewCopyProposal, setCanNewCopyProposal] = useState<boolean>(false);
  const [createProposal, setCreateProposal] = useState<boolean>(false);
  const [updateProposal, setUpdateProposal] = useState<boolean>(false);
  const [showConfirModal, setShowConfirModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showOkModal, setShowOkModal] = useState<boolean>(false);
  const [getLeadsById, setGetLeadsById] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>('');
  const [quotePrice, setQuotePrice] = useState<any>(0);
  const [totalcostProject, setTotalcostProject] = useState<any>(0);
  const [totalcostProjectCost, setTotalcostProjectCost] = useState<any>(0);
  const [newMargin, setNewMargin] = useState<any>(0);
  const [totalcostvisit, setTotalcostvisit] = useState<any>(0);
  const [newMarginCost, setNewMarginCost] = useState<any>(0);
  const [validQuotePrice, setValidQuotePrice] = useState<boolean>(false);
  const [sentInfo, setSentInfo] = useState<boolean>(false);
  const [proposalData, setProposalData] = useState<any>({});
  const [proposalDataCurrent, setProposalDataCurrent] = useState<any>({});
  const [proposalListData, setProposalListData] = useState<any>([]);
  const [selectProposal, setSelectProposal] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showProposalDetailModal, setShowProposalDetailModal] =
    useState<boolean>(false);
  const [showCounterOfferReview, setShowCounterOfferReview] =
    useState<boolean>(false);
  const [showProposalInfo, setShowProposalInfo] = useState<any>(null);
  const valuesArray = Array.isArray(values) ? values : [values];
  const id = valuesArray[0]?.id;
  const [formMode, setFormMode] = useState<boolean>(false);
  const [enableSendProposalButton, setEnableSendProposalButton] =
    useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [showMessagesCatalog, setShowMessagesCatalog] =
    useState<boolean>(false);
  const [showWatchers, setShowWatchers] = useState<boolean>(false);
  const buyPrice = getTotalProject(values, t);
  const quotedMarkup = getTotalQuoteMarkup(values);
  const quotedPriceValue = getTotalQuotePrice(values);
  const quotedSellPrice = (quotedMarkup / 100) * buyPrice + buyPrice;
  const [generalError, setGeneralError] = useState<any>();
  const [counterOfferList, setCounterOfferList] = useState<any>([]);
  const [counterOfferApi, setCounterOfferApi] = useState<boolean>(false);
  const [counterOfferCurrent, setCounterOfferCurrent] = useState<any>();
  const [toastNotification, setToastNotification] =
    useState<toastNotification>();
  const [message, setMessage] = useState<string>('');
  const userState = useAuthStore((state) => state);
  const [tabIndex, setTabIndex] = useState(0);
  const [openAnalytics, setOpenAnalytics] = useState<boolean>(false);
  const [getCalculator, setGetCalculator] = useState<boolean>(true);
  const [currentData, setCurrentData] = useState<any>({});
  const [projectionData, setProjectionData] = useState<any>({});
  // ========================================================================== SWR

  const { data: counterOfferData, error: counterOfferError } = useSWR(
    counterOfferApi && proposalDataCurrent?.id
      ? { id: proposalDataCurrent?.id, url: 'getCounterOffer' }
      : null,
    Api.ProposalApi.getCounterOffers
  ) as any;

  const { data: proposalsByLead, mutate: getProposalMutate } = useSWR(
    sentFlag ? { id, url: 'getProposalMultiple' } : null,
    Api.ProposalApi.getProposalsByLead
  ) as any;

  const { data: responseProposalData, error: responseProposalDataError } =
    useSWR(
      sentInfo === true && typeModal !== 'recall' ? selectProposal.id : null,
      Api.ProposalApi.resendProposal
    );

  const { data: response2ProposalData, error: response2ProposalDataError } =
    useSWR(
      sentInfo === true && typeModal === 'recall'
        ? { id: selectProposal.id, message }
        : null,
      Api.ProposalApi.recallProposal
    );

  const { data: proposalByIdData, error: getIdProposalError } = useSWR(
    `${clientView}` !== 'true' && getLeadsById && values.id ? values.id : null,
    Api.ProposalApi.getProposalByLead
  );

  const { data: createProposalData, error: createProposalError } = useSWR(
    createProposal ? [values, proposalFormValue, totalcostProject] : null,
    Api.ProposalApi.createProposal
  );

  const { data: updateProposalResponse, error: updateProposalError } = useSWR(
    updateProposal
      ? [values, proposalFormValue, totalcostProject, proposalListData?.[0]?.id]
      : null,
    Api.ProposalApi.proposalUpdate
  );

  const { data: currentCalculatorResponse, error: currentCalculatorError } =
    useSWR(
      getCalculator
        ? {
            endDate: values.endDate,
            id: values.id,
            startDate: values.startDate
          }
        : null,
      Api.leadsApi.leadApi.getLeadAnalytics
    );

  const projectionEndDate = dayjs(values.startDate)
    .add(
      Math.ceil(
        dayjs(values.endDate).diff(dayjs(values.startDate), 'year', true)
      ),
      'year'
    )
    .format('YYYY-MM-DD');

  const {
    data: projectionsCalculatorResponse,
    error: projectionsCalculatorError
  } = useSWR(
    getCalculator
      ? {
          endDate: projectionEndDate,
          id: values.id,
          startDate: values.startDate
        }
      : null,
    Api.leadsApi.leadApi.getLeadAnalytics
  );

  useEffect(() => {
    if (currentCalculatorResponse && projectionsCalculatorResponse) {
      setGetCalculator(false);
      setCurrentData(currentCalculatorResponse);
      setProjectionData(projectionsCalculatorResponse);
    }
  }, [currentCalculatorResponse, projectionsCalculatorResponse]);

  useEffect(() => {
    if (currentCalculatorError || projectionsCalculatorError) {
      setGetCalculator(false);
      setCurrentData({});
      setProjectionData({});
    }
  }, [currentCalculatorError, projectionsCalculatorError]);

  // ++++++ dnkdnjd

  const sections = [
    {
      data1: currentData?.pricing,
      data2: projectionData?.pricing,
      title: 'Pricing'
    },
    {
      data1: currentData?.analytics,
      data2: projectionData?.analytics,
      title: 'Analytics'
    }
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  // ========================================================================== FUNCTION

  const resetLeadId = (id: string) => {
    setSentInfo(false);
    setGetLeadsById(true);
    getProposalMutate(undefined, true);
    navigate(`/app/leads/edit-lead`, {
      state: { id }
    });
    saveStepper({ next: 0 });
  };

  function ChartCategory({
    title,
    data1,
    data2
  }: {
    data1: any;
    data2: any;
    title: string;
  }) {
    const formatLabel = (label: string) => {
      return label
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
    };

    const categories =
      data1 && Object.keys(data1).length > 0
        ? Object.keys(data1).map(formatLabel)
        : [];
    const values1 =
      data1 && Object.keys(data1).length > 0 ? Object.values(data1) : [];
    const values2 =
      data2 && Object.keys(data2).length > 0 ? Object.values(data2) : [];

    const series = [
      {
        data: values1 as number[],
        name: 'Current'
      },
      {
        data: values2 as number[],
        name: 'Projections'
      }
    ];

    const options = {
      chart: {
        height: 400,
        type: 'area' as const,
        zoom: {
          enabled: false
        }
      },
      colors: ['#1E90FF', '#32CD32'],
      fill: {
        gradient: {
          opacityFrom: 0.5,
          opacityTo: 0,
          shadeIntensity: 1,
          stops: [0, 90, 100]
        },
        type: 'gradient'
      },
      grid: {
        padding: {
          bottom: 50,
          left: 30
        }
      },
      legend: {
        position: 'top' as const
      },
      stroke: {
        curve: 'smooth' as const,
        width: 2
      },
      tooltip: {
        intersect: false,
        shared: true
      },
      xaxis: {
        categories,
        labels: {
          rotate: -30
        }
      },

      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: (value: number) => value.toFixed(2)
        },
        logarithmic: true
      }
    };

    return (
      <Box>
        <Box
          sx={{
            border: `1px solid ${secondaryColors.SECONDARY_1}`,
            borderRadius: '10px',
            overflow: 'hidden',
            padding: '20px'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                  {t('LEAD.CREATE.PROPOSAL.current')}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  {t('LEAD.CREATE.PROPOSAL.stats')}
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                  {t('LEAD.CREATE.PROPOSAL.projections')}
                </TableCell>
              </TableRow>{' '}
              <TableRow>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                  {`${values.startDate} to ${values.endDate}`}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }} />
                <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                  {`${values.startDate} to ${projectionEndDate}`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values1.map((value, index) => (
                <TableRow key={index}>
                  <TableCell align="right">
                    {value as React.ReactNode}
                  </TableCell>
                  <TableCell align="center">
                    {categories[index] || ''}
                  </TableCell>
                  <TableCell align="left">
                    {String(values2[index]) || ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Typography mb={2} mt={4} textAlign="center" variant="h6">
          {title}
        </Typography>
        {values1.length === 0 || values2.length === 0 ? (
          <Typography color="error" textAlign="center" variant="h6">
            {t('LEAD.CREATE.PROPOSAL.errorMessage')}
          </Typography>
        ) : (
          <Chart
            height={options.chart.height}
            options={options}
            series={series}
            type="area"
          />
        )}
      </Box>
    );
  }

  // ========================================================================== LIFECYCLE

  useEffect(() => {
    if (updateProposalError) {
      setUpdateProposal(false);
    }
  }, [updateProposalError]);

  useEffect(() => {
    if (counterOfferData && !counterOfferError) {
      setCounterOfferApi(false);
      setCounterOfferList(counterOfferData);
    }
  }, [counterOfferData]);

  useEffect(() => {
    if (response2ProposalData && !response2ProposalDataError) {
      resetLeadId(response2ProposalData?.lead?.id);
    }
  }, [response2ProposalData]);

  useEffect(() => {
    if (responseProposalData && !responseProposalDataError) {
      setShowOkModal(false);
      setShowConfirModal(false);
      setSentInfo(false);
      setGetLeadsById(true);
      getProposalMutate(undefined, true);
    }
  }, [responseProposalData]);

  useEffect(() => {
    if (proposalsByLead && Array.isArray(proposalsByLead)) {
      const proposalsMultiple = proposalsByLead.map((proposal: any) => {
        return { ...proposal, lead: proposal?.lead || values };
      });
      setProposalListData(proposalsMultiple);
      setProposalDataCurrent(proposalsMultiple?.[0]);
      setGetLeadsById(false);
      if (proposalsMultiple.length === 0) {
        setFormMode(true);
      } else if (proposalsMultiple?.[0]?.status === 'created') {
        // TODO: set created proposal values to the current form
        // setValues({...values, profit:  })
        setFormMode(true);
      }
      setCounterOfferApi(true);
      setSentFlag(false);
      setLoading(false);
    }
  }, [proposalsByLead]);

  useEffect(() => {
    if (proposalByIdData && !getIdProposalError) {
      setProposalData(proposalByIdData);
    }
  }, [canNewCopyProposal, proposalByIdData]);

  useEffect(() => {
    if (createProposalData && !createProposalError) {
      setValues({ ...values, readOnly: true });
      setCreateProposal(false);
      setProposalDataCurrent(createProposalData);
      getProposalMutate(null, true);
      setSentFlag(true);
      setFormMode(false);
    }
  }, [createProposalData]);

  useEffect(() => {
    setValues({
      ...values,
      status: proposalListData[0]?.lead.status
    });
  }, [proposalListData]);

  useEffect(() => {
    if (createProposalError) {
      setCreateProposal(false);
      setGeneralError(t('ERROR.general'));
      setLoading(false);
    }
  }, [createProposalError]);

  useEffect(() => {
    if (updateProposalResponse) {
      setValues({ ...values, readOnly: true });
      getProposalMutate(undefined, true);
      setSentFlag(true);
      setFormMode(false);
      setUpdateProposal(false);
      navigate(`/app/leads/edit-lead`, {
        state: { id: values?.id }
      });
    }
  }, [updateProposalResponse]);

  useEffect(() => {
    if (`${clientView}` === 'true') {
      setPreviewFlag(true);
      setProposalData(prevProposalData);
    }
    const margin = Math.floor(Math.random() * 30) + 20;
    const newServiceTimes = values?.serviceTimes?.map((serviceTime: any) => {
      const randomFloat = Math.random();
      const result = randomFloat * (700 - 300) + 300;
      const visit = Math.floor(Math.random() * 6) + 1;
      const valueMargin = result * (margin / 100);
      const totalMarginInclude = result + valueMargin;
      return {
        ...serviceTime,
        proposalCost: totalMarginInclude,
        totalCost: result,
        visits: visit
      };
    });
    setProposalFormValue({
      ...values,
      actualMargin: margin,
      allowCounterOffers: true,
      approvalRequired: true,
      margin,
      serviceTimes: newServiceTimes
    });
    setTimeout(() => {
      setSentFlag(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (quotedSellPrice) {
      setProposalFormValue({
        ...proposalFormValue,
        marginInProposal: getMargin(buyPrice, quotedSellPrice).percent,
        proposalValue: parseFloat(quotedSellPrice.toFixed(2))
      });
    }
  }, [quotedSellPrice]);

  // ============================================================================ FUNCTIONS

  // eslint-disable-next-line react/no-unstable-nested-components
  function ServiceSummary() {
    return (
      <Box
        borderRadius="8px"
        gap="24px"
        padding="24px"
        sx={{ border: `1px solid ${secondaryColors.SECONDARY_1}` }}
      >
        {/* {previewFlag && `${clientView}` !== 'true' ? (
          <Box>
            <Typography sx={{ fontSize: 26 }} variant="body_500">
              {t('LEAD.CREATE.PROPOSAL.summaryScopeWork')}
            </Typography>
          </Box>
        ) : null} */}
        {values && values?.serviceTimes && values?.serviceTimes.length > 0 && (
          <Box display="flex" flexDirection="column" gap="48px" width="100%">
            {values?.serviceTimes?.map((serviceTime: any, index: number) => {
              if (typeof serviceTime.id === 'number') {
                return (
                  <SummaryService
                    clientView={clientView}
                    index={index}
                    key={`service-time${index}`}
                    serviceTime={serviceTime}
                    setNewMargin={setNewMargin}
                    setNewMarginCost={setNewMarginCost}
                    setTotalcostProject={setTotalcostProject}
                    setTotalcostProjectCost={setTotalcostProjectCost}
                    setTotalcostvisit={setTotalcostvisit}
                    setValidQuotePrice={setValidQuotePrice}
                    setValues={setValues}
                    totalcostProject={totalcostProject}
                    totalcostProjectCost={totalcostProjectCost}
                    totalvisit={
                      serviceTime.availability?.generalInfo
                        ?.daysOfServiceCount || 0
                    }
                    type="proposal"
                    validQuotePrice={validQuotePrice}
                    values={values}
                  />
                );
              }

              return null;
            })}
          </Box>
        )}
      </Box>
    );
  }

  function OtroProjectWideCosts() {
    const isItemLoaded = (index: any) =>
      !!values?.taxableItems?.[index] || !!values?.Misc?.[index];
    return (
      <Box display="flex" flexDirection="column" gap="16px">
        <Box>
          {(values?.Misc?.length > 0 || values?.taxableItems?.length > 0) && (
            <Typography variant="body_500">
              {t('LEAD.CREATE.PROPOSAL.tableProjectCostsTitle')}
            </Typography>
          )}
        </Box>
        <Box width="114%">
          {values?.Misc?.length > 0 && (
            <TableComponent
              action={() => {}}
              headKeys={['misc', '', '', '', 'total']}
              isItemLoaded={isItemLoaded}
              itemCount={1}
              loadMoreItems={false}
              valuesArray={{ data: values.Misc }}
            />
          )}
        </Box>
        <Box width="114%">
          {values?.taxableItems?.length > 0 && (
            <TableComponent
              action={() => {}}
              headKeys={['taxableItems', '', 'subTotal', 'salesTax', 'total']}
              isItemLoaded={isItemLoaded}
              itemCount={1}
              loadMoreItems={false}
              valuesArray={{ data: values.taxableItems }}
            />
          )}
        </Box>
      </Box>
    );
  }

  // TODO: USE CALCULATOR FUNCTIONS AND DELETES THESE ONES.
  const TotalProjectCost = useRef(0);
  const TotalProjectProposal = useRef(0);
  const TotalProposalMargin = useRef(0);
  const SumMarginProposal = useRef(0);
  const SumDiference = useRef(0);
  const OriginalSum = useRef(0);
  const TotalCostMargin = useRef(0);
  const TotalCosttaxt = useRef(0);
  const TotalVisist = useRef(0);
  const TotalMisc = useRef(0);

  // TODO: USE CALCULATOR FUNCTIONS AND DELETES THESE ONES.
  useEffect(() => {
    TotalProjectCost.current = 0;
    TotalProjectProposal.current = 0;
    TotalProposalMargin.current = 0;
    SumMarginProposal.current = 0;
    SumDiference.current = 0;
    OriginalSum.current = 0;
    TotalCostMargin.current = 0;
    TotalCosttaxt.current = 0;
    TotalVisist.current = 0;
    values?.serviceTimes?.forEach((value: any) => {
      TotalProjectCost.current += value.serviceTimeCost || 0;

      TotalProjectProposal.current +=
        value.serviceTimeCost * (value?.proposalCost || 0);

      TotalProposalMargin.current += value?.profit || 0;
      OriginalSum.current =
        TotalProjectProposal.current -
        TotalProjectProposal.current * (value.profit / 100);
      TotalVisist.current += Number(
        value.availability?.generalInfo?.daysOfServiceCount
      );
    });
  }, [TotalProjectCost, values]);

  useEffect(() => {
    const invoiceFormDefaultValues = {
      frequency: proposalFormValue?.frequency || 'weekly',
      frequencyDay: proposalFormValue?.frequencyDay || 'Monday',
      paymentTerms: proposalFormValue?.paymentTerms || 'net 10',
      reminder:
        proposalFormValue?.reminder ||
        t('LEAD.CREATE.PROPOSAL.invoiceConfigSelectEvery48')
    };
    setProposalFormValue({
      ...proposalFormValue,
      ...invoiceFormDefaultValues
    });
  }, []);

  values?.taxableItems?.forEach(
    (value: any) => {
      TotalCosttaxt.current = Number(value?.subTotal);

      SumMarginProposal.current += validQuotePrice
        ? quotePrice * (values.profit / 100 || 1)
        : TotalProjectCost.current * (values.profit / 100 || 1);

      TotalProposalMargin.current /= values?.serviceTimes?.length ?? 1;

      SumDiference.current =
        TotalProjectProposal.current - TotalProjectCost.current;

      TotalCostMargin.current =
        SumMarginProposal.current + TotalProjectCost.current;
    },
    [values]
  );

  values?.Misc?.forEach(
    (value: any) => {
      TotalMisc.current = Number(value?.total);
    },
    [values]
  );

  const profitPercentage = Number(values?.profit || 0) / 100;

  const totalCost =
    Number(TotalCosttaxt?.current || 0) +
    Number(totalcostProject || 0) +
    profitPercentage * Number(totalcostProject || 0);

  // const markup = totalCost - totalcostProjectCost;s
  const percentValue = (Number(values?.profit) / 100) * totalcostProjectCost;
  const totalWithPercent = totalcostProjectCost + percentValue;

  // eslint-disable-next-line react/no-unstable-nested-components
  function UpdatePricingMargin() {
    const grossMarginPercentage =
      ((totalWithPercent - totalcostProjectCost) / totalWithPercent) * 100;
    const grossMarginAmount =
      (grossMarginPercentage / 100) *
      (validQuotePrice ? totalcostProject : totalcostProjectCost);

    const margenBruto =
      (Math.round(parseFloat(String(grossMarginPercentage))) / 100) *
      totalcostProject;
    const nuevoPrecioFinal = totalcostProject + margenBruto;
    const markupCost = totalcostProject * (Number(values?.profit) / 100);

    const buyPrice = getTotalProject(values, t);
    const sellPrice = values?.profit
      ? (parseFloat(values.profit || 0) / 100) * buyPrice + buyPrice
      : buyPrice;
    const margin = getMargin(buyPrice, sellPrice);
    const markup = getMarkup(buyPrice, sellPrice);
    const quotedMarkup = getTotalQuoteMarkup(values);

    // The formula for the quotedSellPrice is the same as in SummaryService.tsx
    // * (totalvisit * quotePrice)
    const quotedSellPrice =
      getQuotedTotal(values) || (quotedMarkup / 100) * buyPrice + buyPrice;
    const quotedMargin = getMargin(buyPrice, quotedSellPrice);

    const colorTableItem = (title: string) => (
      <Typography
        color={getColor(values?.profit, quotedMarkup)}
        variant="primary"
      >
        {title}
      </Typography>
    );

    const data: any[] = [
      {
        calculationItem: t('LEAD.CREATE.PROPOSAL.tableMarkup'),
        calculator: `${Number(values?.profit).toFixed(0)} %`,
        proposal: colorTableItem(`${quotedMarkup.toFixed(2)} %`),
        quotedProposal: `$ ${
          validQuotePrice
            ? maskData(markupCost)
            : maskData(markup.amount || 0) || 0
        }`
      },
      // {
      //   calculationItem: t('LEAD.CREATE.PROPOSAL.tableTotalProjectCost'),
      //   calculator: `$ ${maskData(totalcostProjectCost || 0) || 0}`,
      //   quotedProposal: `$ ${maskData(totalcostProjectCost || 0) || 0}`
      // },
      {
        calculationItem: t('LEAD.CREATE.PROPOSAL.tableMargin'),
        calculator: `${margin.percent} %`,
        proposal: colorTableItem(`${quotedMargin.percent} %`),
        quotedProposal: validQuotePrice
          ? `$ ${maskData(margenBruto)}`
          : `$ ${maskData(grossMarginAmount)}`
      },
      {
        calculationItem: t('LEAD.CREATE.PROPOSAL.tableQuotedPrice'),
        calculator: `${getCurrency(sellPrice)}`,
        proposal: colorTableItem(`${getCurrency(quotedSellPrice)}`)
      }
      // {
      //   calculationItem: t('LEAD.CREATE.PROPOSAL.tableCollectedSalesTax'),
      //   calculator: `$ ${maskData(TotalCosttaxt?.current || 0) || 0}`,
      //   quotedProposal: `$ ${maskData(TotalCosttaxt?.current || 0) || 0}`
      // },
      // {
      //   calculationItem: t('LEAD.CREATE.PROPOSAL.tableTotal'),
      //   calculator: `$ ${maskData(totalWithPercent || 0) || 0}`,
      //   quotedProposal: `$ ${maskData(totalcostProject || 0) || 0}`
      // }
    ];
    const isItemLoaded = (index: any) => !!data[index];
    return (
      <Box display="flex" flexDirection="column" gap="24px">
        <Typography fontWeight={600} variant="body_400">
          {t('LEAD.CREATE.PROPOSAL.tableTitleUpdatedPricing')}
        </Typography>
        <TableComponent
          fullSize
          action={() => {}}
          headKeys={[
            'calculationItem',
            'calculator',
            'proposal'
            // 'quotedProposal'
          ]}
          isItemLoaded={isItemLoaded}
          itemCount={1}
          loadMoreItems={false}
          valuesArray={{ data } as unknown as { data: any[] }}
        />
      </Box>
    );
  }

  function calculateFrequency() {
    dayjs.extend(customParseFormat);
    const projectStart = new Date();
    const projectEnd = new Date('2023-05-23');
    const weeksBetween = dayjs(values?.projectEnd ?? projectEnd).diff(
      dayjs(values?.projectStart ?? projectStart),
      'week'
    );
    const monthsBetween = dayjs(values?.projectEnd ?? projectEnd).diff(
      dayjs(values?.projectStart ?? projectStart),
      'month'
    );

    switch (proposalFormValue?.frequency) {
      case 'weekly':
        return 'Weekly';
      case 'biweekly':
        return 'Biweekly';
      case 'monthly':
        return 'Monthly';
      default:
        return 1;
    }
  }
  const [fireUsersAPI, setFireUsersAPI] = useState<boolean>(true);
  const pageNumber = useRef<number>(1);
  const loadMoreItems = () => {
    pageNumber.current += 1;
    setFireUsersAPI(true);
  };
  // eslint-disable-next-line react/no-unstable-nested-components

  function PricingSummary() {
    const data = values?.serviceTimes?.map((service: any, index: number) => {
      const amount = getCurrency(service?.quotePrice || 0);
      return {
        amount,
        serviceTitle: `${t(
          'LEAD.CREATE.PROPOSAL.tablePricingBillingService'
        )} #${index + 1}: (${service?.frequency} with ${
          service?.availability?.generalInfo?.daysOfServiceCount
        } visits)`
      };
    });

    // data.push({
    //   amount: `$ ${1} `,
    //   serviceTitle: `${t('LEAD.CREATE.PROPOSAL.tableInvoicePart1')} ${
    //     proposalFormValue?.frequency
    //   } ${t('LEAD.CREATE.PROPOSAL.tableInvoicePart2')}`
    // });

    const frequencyInvoice = calculateFrequency();
    const totalCalculated = proposalFormValue?.depositAmount
      ? proposalFormValue?.depositAmount
      : TotalProjectProposal.current / 1;

    const data2 = [
      {
        amount: getCurrency(proposalFormValue?.depositAmount || 0),
        detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceSecurityDeposit')
      },
      {
        amount: getCurrency(quotedSellPrice),
        detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceSecurityDepositAlternative')
      },
      // {
      //   amount: `$ ${TotalMisc.current || 0} `,
      //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceTotalMiscelaneoues')
      // },
      // {
      //   amount: `$ ${TotalCosttaxt.current || 0} `,
      //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceSalesTaxable')
      // },
      // {
      //   amount: maskData(TotalCosttaxt.current || 0),
      //   detail: t('LEAD.CREATE.PROPOSAL.tableInvoiceCollectedTax')
      // },
      {
        amount: getCurrency(quotedSellPrice),
        backgroundColor: themePalette.BACKGROUND_TABLE,
        detail: (
          <Typography
            color={textColors.DARK_TEXT}
            fontWeight={600}
            variant="body_300"
          >
            {t('LEAD.CREATE.PROPOSAL.tableInvoiceTotal')}
          </Typography>
        )
      }
    ];

    const isItemLoaded = (index: any) => {
      if (undefined !== data && undefined !== data[index]) {
        return data[index];
      }
      return false;
    };

    return (
      <Box display="flex" flexDirection="column" gap="32px">
        <Box display="flex" flexDirection="column" gap="24px">
          <Typography fontWeight={600} variant="body_400">
            {t('LEAD.CREATE.PROPOSAL.pricingSummaryTable1')}
          </Typography>

          <TableComponent
            fullSize
            action={() => {}}
            headKeys={['serviceTitle', 'amount']}
            isItemLoaded={isItemLoaded}
            itemCount={1}
            loadMoreItems={loadMoreItems}
            valuesArray={{ data } as unknown as { data: any[] }}
          />
        </Box>

        <Box display="flex" flexDirection="column" gap="24px">
          <Typography fontWeight={600} variant="body_400">
            {t('LEAD.CREATE.PROPOSAL.pricingSummaryTable2')}
          </Typography>

          <TableComponent
            fullSize
            action={() => {}}
            headKeys={['detail', 'amount']}
            isItemLoaded={isItemLoaded}
            itemCount={1}
            loadMoreItems={loadMoreItems}
            valuesArray={{ data: data2 }}
          />
          {values?.company?.minimumInvoice >= quotedSellPrice && (
            <Banner
              text={`${t('LEAD.CREATE.PROPOSAL.minimumPriceErrorMessage', [
                values?.company?.minimumInvoice === quotedSellPrice
                  ? 'equal to'
                  : 'less than',
                values?.company?.minimumInvoice
              ])}`}
              type="error"
            />
          )}
        </Box>
      </Box>
    );
  }

  const invoiceConfiguration = () => {
    const weekDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];

    return (
      <Box>
        {(values.projectType === 'regular' ||
          values.projectType === 'Regular') && (
          <InvoiceContainer>
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="secondary-semi">
                {t('LEAD.CREATE.PROPOSAL.invoiceConfigTitle')}
              </Typography>
              <Typography
                color={secondaryColors.SECONDARY_4}
                variant="body_300"
              >
                {t('LEAD.CREATE.PROPOSAL.invoiceConfigSubTitle')}
              </Typography>
            </Box>

            <Divider
              flexItem
              sx={{ borderColor: secondaryColors.SECONDARY_1 }}
            />

            <Box display="flex" flexDirection="column" gap="8px">
              <Box
                alignItems="center"
                display="grid"
                gridTemplateColumns="200px 1fr"
              >
                <Typography color="primary.main" variant="heading_300">
                  {t('LEAD.CREATE.PROPOSAL.invoiceFrequency')}
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="invoice-frequency"
                    name="invoice-frequency"
                    onChange={(event: any) => {
                      setProposalFormValue({
                        ...proposalFormValue,
                        frequency: event.target.value
                      });
                    }}
                  >
                    <Box display="flex" gap="48px">
                      <FormControlLabel
                        checked={proposalFormValue?.frequency === 'weekly'}
                        control={<CustomRadio />}
                        label={
                          <Typography color="mainText.200" variant="body_300">
                            {t('LEAD.CREATE.SERVICES.weekly')}
                          </Typography>
                        }
                        value="weekly"
                      />
                      <FormControlLabel
                        checked={proposalFormValue?.frequency === 'biweekly'}
                        control={<CustomRadio />}
                        label={
                          <Typography color="mainText.200" variant="body_300">
                            {t('LEAD.CREATE.SERVICES.biweekly')}
                          </Typography>
                        }
                        value="biweekly"
                      />
                      <FormControlLabel
                        checked={proposalFormValue?.frequency === 'monthly'}
                        control={<CustomRadio />}
                        label={
                          <Typography color="mainText.200" variant="body_300">
                            {t('LEAD.CREATE.SERVICES.monthly')}
                          </Typography>
                        }
                        value="monthly"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box
                alignItems="center"
                display="grid"
                gridTemplateColumns="200px 300px 184px 1fr"
              >
                <Typography color="primary.main" variant="heading_300">
                  {t('LEAD.CREATE.PROPOSAL.invoiceConfigSendOn')}
                </Typography>

                <Filter
                  arrayInput={weekDays}
                  backgroundColor={themePalette.BACKGROUND}
                  selectInput={proposalFormValue?.frequencyDay}
                  setSelectInput={(frequencyDay) => {
                    setProposalFormValue({
                      ...proposalFormValue,
                      frequencyDay
                    });
                  }}
                  title={t('LEAD.CREATE.PROPOSAL.invoiceConfigSelectDay')}
                  width="260px"
                />

                {proposalFormValue?.frequency === 'monthly' && (
                  <>
                    <Typography color="primary.main" variant="heading_300">
                      {t('LEAD.CREATE.PROPOSAL.invoiceConfigWeek')}
                    </Typography>

                    <Filter
                      arrayInput={['First', 'Second', 'Third', 'Fourth']}
                      backgroundColor={themePalette.BACKGROUND}
                      selectInput={proposalFormValue?.frequencyWeek}
                      setSelectInput={(frequencyWeek) => {
                        setProposalFormValue({
                          ...proposalFormValue,
                          frequencyWeek
                        });
                      }}
                      title={t('LEAD.CREATE.PROPOSAL.invoiceConfigSelectWeek')}
                      width="260px"
                    />
                  </>
                )}
              </Box>

              <Box
                alignItems="center"
                display="grid"
                gridTemplateColumns="200px 300px 184px 1fr"
              >
                <Typography color="primary.main" variant="heading_300">
                  {t('LEAD.CREATE.PROPOSAL.invoiceConfigSelectLapse')}
                </Typography>

                <Filter
                  arrayInput={['net 10', 'net 15', 'net 30']}
                  backgroundColor={themePalette.BACKGROUND}
                  selectInput={proposalFormValue?.paymentTerms}
                  setSelectInput={(paymentTerms) => {
                    setProposalFormValue({
                      ...proposalFormValue,
                      paymentTerms
                    });
                  }}
                  title={t('LEAD.CREATE.PROPOSAL.invoiceConfigSelectPayment')}
                  width="260px"
                />

                <Typography color="primary.main" variant="heading_300">
                  {t('LEAD.CREATE.PROPOSAL.invoiceConfigReminder')}
                </Typography>

                <Filter
                  arrayInput={[
                    t('LEAD.CREATE.PROPOSAL.invoiceConfigSelectEvery12'),
                    t('LEAD.CREATE.PROPOSAL.invoiceConfigSelectEvery24'),
                    t('LEAD.CREATE.PROPOSAL.invoiceConfigSelectEvery48')
                  ]}
                  backgroundColor={themePalette.BACKGROUND}
                  selectInput={proposalFormValue?.reminder}
                  setSelectInput={(reminder) => {
                    setProposalFormValue({
                      ...proposalFormValue,
                      reminder
                    });
                  }}
                  title={t('LEAD.CREATE.PROPOSAL.invoiceConfigTimeLapse')}
                  width="260px"
                />
              </Box>
            </Box>
          </InvoiceContainer>
        )}
      </Box>
    );
  };

  const getDepositAmount = () => {
    return (
      <Box
        display="flex"
        gap="40px"
        padding="24px"
        sx={{
          border: `1px solid ${secondaryColors.SECONDARY_1}`,
          borderRadius: '8px'
        }}
      >
        <Box display="flex" flex={1} flexDirection="column" gap="8px">
          <Typography
            color={secondaryColors.SECONDARY_5}
            variant="secondary-semi"
          >
            {t('LEAD.CREATE.PROPOSAL.invoiceConfigSecurityTitle')}
          </Typography>
          <Typography color={secondaryColors.SECONDARY_4} variant="body_200">
            {t('LEAD.CREATE.PROPOSAL.invoiceConfigSecurityLabel')}
          </Typography>
        </Box>

        <Box alignItems="center" display="flex" gap="4px">
          <RegularInput
            customStyle={{
              fontSize: '16px',
              margin: 0,
              width: '392px'
            }}
            onChange={(value: any) => {
              setProposalFormValue({
                ...proposalFormValue,
                depositAmount: value
              });
            }}
            type="number"
            value={proposalFormValue?.depositAmount || 0}
          />
          <Typography color={secondaryColors.SECONDARY_4}>(%)</Typography>
        </Box>
      </Box>
    );
  };

  const personalizedMessage = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="24px"
        padding="24px"
        sx={{
          border: `1px solid ${secondaryColors.SECONDARY_1}`,
          borderRadius: '8px'
        }}
      >
        <Box display="flex" gap="40px" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography
              color={secondaryColors.SECONDARY_5}
              variant="secondary-semi"
            >
              {t('LEAD.CREATE.PROPOSAL.personalizedMsgTitle')}
            </Typography>
            <Typography color={secondaryColors.SECONDARY_4} variant="body_200">
              {t('LEAD.CREATE.PROPOSAL.personalizedMsgSubtitle')}
            </Typography>
          </Box>

          <Box display="flex" gap="8px">
            <Button
              color="error"
              onClick={() =>
                setProposalFormValue((prev: any) => {
                  return {
                    ...prev,
                    messageTemplateId: null,
                    personalizedMessage: ''
                  };
                })
              }
              sx={{ height: '46px' }}
              variant="outlined"
            >
              Clear
            </Button>
            <Button
              fullWidth
              onClick={() => setShowMessagesCatalog(true)}
              sx={{ height: '46px', width: '304px' }}
              variant="primary-outlined"
            >
              {t('LEAD.CREATE.PROPOSAL.buttonAddCatalogMessage')}
            </Button>
          </Box>
        </Box>
        <CustomTextField
          multiline
          onChange={(value) => {
            setProposalFormValue({
              ...proposalFormValue,
              personalizedMessage: value.target.value
            });
          }}
          value={proposalFormValue?.personalizedMessage || ''}
        />
      </Box>
    );
  };

  const getReviewProposal = () => {
    return !(values?.serviceTimes?.length > 0);
  };

  const reviewProposal = () => {
    return (
      <Box display="flex" flexDirection="column" gap="32px">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap="8px">
            {clientView ? null : (
              <Typography
                color={
                  previewFlag
                    ? textColors.DARK_TEXT
                    : secondaryColors.SECONDARY_5
                }
                fontWeight={600}
                variant="body_400"
              >
                {previewFlag
                  ? t('LEAD.CREATE.PROPOSAL.reviewProposalTitle1')
                  : t('LEAD.CREATE.PROPOSAL.reviewProposalTitle2')}
              </Typography>
            )}

            <Typography color={secondaryColors.SECONDARY_4} variant="body_200">
              {previewFlag
                ? t('LEAD.CREATE.PROPOSAL.reviewProposalSubTitle1')
                : t('LEAD.CREATE.PROPOSAL.reviewProposalSubTitle2')}
            </Typography>
          </Box>
          {!previewFlag ? (
            <Button
              disabled={getReviewProposal()}
              onClick={() => setPreviewFlag(true)}
              variant="primary"
            >
              {t('LEAD.CREATE.PROPOSAL.buttonReviewProposal')}
            </Button>
          ) : null}
        </Box>

        {previewFlag && (
          <Box display="flex" flexDirection="column" gap="32px">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={proposalFormValue?.approvalRequired}
                    onChange={(e, approvalRequired) => {
                      setProposalFormValue({
                        ...proposalFormValue,
                        approvalRequired
                      });
                    }}
                  />
                }
                label={
                  <Typography variant="body_300">
                    {t('LEAD.CREATE.PROPOSAL.approvalRequired')}
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={proposalFormValue?.allowCounterOffers}
                    onChange={(e, allowCounterOffers) => {
                      setProposalFormValue({
                        ...proposalFormValue,
                        allowCounterOffers
                      });
                    }}
                  />
                }
                label={
                  <Typography variant="body_300">
                    {t('LEAD.CREATE.PROPOSAL.counterOfferToggle')}
                  </Typography>
                }
              />
            </FormGroup>

            {proposalFormValue?.allowCounterOffers && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="24px"
                  padding="24px"
                  sx={{
                    backgroundColor: successColors.SUCCESS_6,
                    border: `1px solid ${palette.success.main}`,
                    borderRadius: '8px'
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Typography variant="heading_300">
                      {t('LEAD.CREATE.PROPOSAL.discountTitle')}
                    </Typography>
                    <Typography variant="body_200">
                      {t('LEAD.CREATE.PROPOSAL.discountSubtitle')}
                    </Typography>
                  </Box>

                  <Box display="flex" gap="24px">
                    <Box
                      display="flex"
                      flex={1}
                      flexDirection="column"
                      gap="4px"
                    >
                      <Typography color="primary" variant="heading_300">
                        {t('LEAD.CREATE.PROPOSAL.discountVariance')}
                      </Typography>
                      <RegularInput
                        customStyle={{
                          '& .MuiInputBase-input': {
                            height: 36
                          },
                          margin: 0
                        }}
                        onChange={(varianceDiscountPercentage) => {
                          setProposalFormValue({
                            ...proposalFormValue,
                            varianceDiscountPercentage
                          });
                        }}
                        type="number"
                        value={
                          proposalFormValue?.varianceDiscountPercentage || 0
                        }
                      />
                    </Box>

                    <Box
                      display="flex"
                      flex={1}
                      flexDirection="column"
                      gap="4px"
                    >
                      <Typography color="primary" variant="heading_300">
                        {t('LEAD.CREATE.PROPOSAL.discountPercent')}
                      </Typography>
                      <RegularInput
                        customStyle={{
                          '& .MuiInputBase-input': {
                            height: 36
                          },
                          margin: 0
                        }}
                        onChange={(proposalDiscountPercentage) => {
                          setProposalFormValue({
                            ...proposalFormValue,
                            proposalDiscountPercentage
                          });
                        }}
                        type="number"
                        value={
                          proposalFormValue?.proposalDiscountPercentage || 0
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  gap="24px"
                  padding="24px"
                  sx={{
                    backgroundColor: palette.warning.light,
                    border: `1px solid ${palette.warning.main}`,
                    borderRadius: '8px'
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Typography variant="heading_400">
                      {t('LEAD.CREATE.PROPOSAL.penalizationTitle')}
                    </Typography>
                    <Typography variant="body_200">
                      {t('LEAD.CREATE.PROPOSAL.penalizationSubtitle')}
                    </Typography>
                  </Box>
                  <Box display="flex" gap="24px">
                    <Box
                      display="flex"
                      flex={1}
                      flexDirection="column"
                      gap="4px"
                    >
                      <Typography color="primary" variant="heading_300">
                        {t('LEAD.CREATE.PROPOSAL.penalizationVariance')}
                      </Typography>
                      <RegularInput
                        customStyle={{
                          '& .MuiInputBase-input': {
                            height: 36
                          },
                          margin: 0
                        }}
                        onChange={(variancePenalizationPercentage) => {
                          setProposalFormValue({
                            ...proposalFormValue,
                            variancePenalizationPercentage
                          });
                        }}
                        type="number"
                        value={
                          proposalFormValue?.variancePenalizationPercentage || 0
                        }
                      />
                    </Box>
                    <Box
                      display="flex"
                      flex={1}
                      flexDirection="column"
                      gap="4px"
                    >
                      <Typography color="primary" variant="heading_300">
                        {t('LEAD.CREATE.PROPOSAL.penalizationPercent')}
                      </Typography>
                      <RegularInput
                        customStyle={{
                          '& .MuiInputBase-input': {
                            height: 36
                          },
                          margin: 0
                        }}
                        onChange={(proposalPenalizationPercentage) => {
                          setProposalFormValue({
                            ...proposalFormValue,
                            proposalPenalizationPercentage
                          });
                        }}
                        type="number"
                        value={
                          proposalFormValue?.proposalPenalizationPercentage || 0
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const buttons = () => {
    return (
      <ButtonsContainer
      // width={previewFlag ? '100%' : 300}
      >
        {previewFlag ? (
          <Box display="flex" gap="16px" mt="8px">
            <Button
              fullWidth
              onClick={() => setPreviewFlag(false)}
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '10px',
                minWidth: 254
              }}
              variant="primary-outlined"
            >
              <ModeIcon fontSize="small" />
              <Typography variant="heading_328">
                {t('LEAD.CREATE.PROPOSAL.buttonContinueEditing')}
              </Typography>
            </Button>
            <Button
              fullWidth
              disabled={values?.company?.minimumInvoice > quotedSellPrice}
              onClick={() => {
                window.localStorage.setItem(
                  'customer-proposal',
                  JSON.stringify(proposalFormValue)
                );
                if (proposalListData?.[0]?.status === 'created') {
                  setUpdateProposal(true);
                } else {
                  setCreateProposal(true);
                }
                setLoading(true);
              }}
              sx={{
                minWidth: 304
              }}
              variant="primary"
            >
              {proposalFormValue?.approvalRequired
                ? t('LEAD.CREATE.PROPOSAL.buttonSendProposal')
                : t('LEAD.CREATE.PROPOSAL.buttonConvertProject')}
            </Button>
          </Box>
        ) : null}
      </ButtonsContainer>
    );
  };

  const handleOk = () => {
    // if (typeModal === 'recall') {
    setShowOkModal(false);
    setSentInfo(true);
    // }
  };

  const proposalListingPages = () => {
    return (
      <Box display="flex" flexDirection="column" gap="32px">
        <Box
          alignItems="center"
          display="flex"
          gap="40px"
          justifyContent="space-between"
        >
          <Box display="flex" flex={1} flexDirection="column" gap="16px">
            <Box display="flex" gap="4px">
              <Typography color="primary.main" variant="heading_500">
                {t('LEAD.CREATE.PROPOSAL.proposalTitle')}
              </Typography>
              <Tooltip title={t('TOOLTIP.PROPOSAL.watchers')}>
                <Button
                  onClick={() => {
                    setShowWatchers(true);
                  }}
                  sx={{ padding: 0 }}
                >
                  <i
                    className="custom-icon icon-eye"
                    style={{ color: secondaryColors.SECONDARY_4 }}
                  />
                </Button>
              </Tooltip>
            </Box>
            <Typography color={secondaryColors.SECONDARY_4} variant="body_200">
              {t('LEAD.CREATE.PROPOSAL.proposalSentToClient')}
            </Typography>
          </Box>

          <Button
            disabled={values?.status === 'Converted'}
            onClick={() => {
              setShowConfirModal(true);
              setTypeModal('recall');
              setSelectProposal(proposalListData[0]);
            }}
            variant="cancel"
          >
            <Box alignItems="center" display="flex" gap="10px">
              <i className="custom-icon icon-backspace" />
              <Typography variant="heading_328">
                {t('LISTING_PAGES.recallProposal')}
              </Typography>
            </Box>
          </Button>
          <Tooltip title={t('TOOLTIP.PROPOSAL.messages')}>
            <Button
              onClick={() => {
                setShowChat(true);
              }}
              variant="primary"
            >
              <Box alignItems="center" display="flex" gap="10px">
                <i className="custom-icon icon-chat" />
                <Typography variant="heading_328">
                  {t('LEAD.CREATE.PROPOSAL.viewMessages')}
                </Typography>
              </Box>
            </Button>
          </Tooltip>
        </Box>
        {/* Status:{values.status}

        {/* refreshProposal:{`${sentFlag}`} */}
        <Box display="flex" flexDirection="column" gap="32px">
          {getProposalListTable({
            proposalListData,
            setSelectProposal,
            setShowConfirModal,
            setShowProposalDetailModal,
            setShowProposalInfo,
            setTypeModal,
            t,
            timeFormat: userState?.timeFormat,
            typeModal
          })}

          <Box display="flex" flexDirection="column" gap="32px">
            <Typography
              color={textColors.DARK_TEXT}
              fontWeight={600}
              variant="body_400"
            >
              {t('LEAD.CREATE.PROPOSAL.counterOfferListTitle')}
            </Typography>

            {counterOfferList?.length > 0 && (
              <TableComponentEditable
                disableAdd
                action={() => {}}
                handleOpenDraw={(selected: any) => {}}
                headKeys={[
                  'action',
                  'priceVariance',
                  'date',
                  'serviceTimes',
                  'totalVisits',
                  'review'
                ]}
                onChange={(selected) => {
                  setShowCounterOfferReview(true);
                  setCounterOfferCurrent(selected);
                }}
                valuesArray={{
                  data: counterOfferList?.map((counterOffer: any) => {
                    const { values } = counterOffer;
                    const { counterOfferData: serviceTimes } = values;
                    const { actionToCounterOffer } = serviceTimes?.[0] || {};
                    let totalVisits = 0;
                    serviceTimes?.forEach((service: any) => {
                      totalVisits += service?.numberOfVisits || 0;
                    });
                    return {
                      ...counterOffer,
                      date: formatDate(counterOffer?.createdAt),
                      priceVariance:
                        actionToCounterOffer === 'notApplicable'
                          ? 'No Variance'
                          : actionToCounterOffer,
                      serviceTimes: serviceTimes?.length,
                      totalVisits
                    };
                  })
                }}
              />
            )}

            {!counterOfferList ||
              (counterOfferList?.length === 0 && (
                <Box
                  alignItems="center"
                  borderRadius="12px"
                  display="flex"
                  justifyContent="center"
                  padding="24px 100px 24px 100px"
                  sx={{ backgroundColor: mainColors.MAIN_LIGHT }}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                  >
                    <i
                      className="custom-icon icon-receivedEmail"
                      style={{
                        color: mainColors.MAIN_3
                      }}
                    />
                    <Typography color={mainColors.MAIN_3} variant="body_200">
                      {t('ERROR.listingError', [
                        t('LEAD.CREATE.PROPOSAL.counterOfferListTitle')
                      ])}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>

          {showCounterOfferReview && (
            <CounterOfferReviewModal
              counterOffer={counterOfferCurrent}
              currentProposal={proposalDataCurrent}
              onClose={() => setShowCounterOfferReview(false)}
              onReset={(id: string) => resetLeadId(id)}
            />
          )}

          {showConfirModal && (
            <ConfirmModal
              modalText={
                typeModal === 'resend'
                  ? t('MODAL.confirModal')
                  : t('MODAL.confirModal2')
              }
              modalType={typeModal === 'recall' ? 'recall' : typeModal}
              setInput={setMessage}
              setSentFlag={setSentFlag}
              setShowModal={setShowConfirModal}
              setShowOkModal={setShowOkModal}
            />
          )}

          {showOkModal && (
            <OkModal
              client={
                typeModal === 'resend'
                  ? selectProposal?.lead?.client?.email
                  : `${selectProposal?.lead.client?.firstName} ${selectProposal?.lead.client?.lastName}`
              }
              modalText={
                typeModal === 'resend'
                  ? 'Proposal Resent to'
                  : 'Proposal Recalled'
              }
              onOk={handleOk}
              setShowModal={setShowConfirModal}
            />
          )}
          {showProposalDetailModal && (
            <Modal
              onClose={() => {
                setShowProposalDetailModal(false);
              }}
              open={showProposalDetailModal}
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <Box>
                <ProposalView
                  admin
                  closeModal={() => {
                    setShowProposalDetailModal(false);
                  }}
                  proposalInfo={showProposalInfo}
                />
              </Box>
            </Modal>
          )}
          {showChat && (
            <Modal
              onClose={() => {
                setShowChat(false);
              }}
              open={showChat}
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  background: 'white',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 600,
                  p: 4,
                  width: 800
                }}
              >
                <Chat
                  id={proposalDataCurrent?.id}
                  messages={proposalDataCurrent?.proposalMessages}
                  updateMessages={(proposalMessages) => {
                    setProposalDataCurrent({
                      ...proposalDataCurrent,
                      proposalMessages
                    });
                  }}
                />
              </Box>
            </Modal>
          )}
          {showWatchers && (
            <WatchersModal
              id={proposalDataCurrent?.id}
              mutate={() => {}}
              roles={['Operations']}
              setShowModal={setShowWatchers}
              type="proposal"
              watchers={proposalDataCurrent?.watchers}
            />
          )}
        </Box>
      </Box>
    );
  };

  // ================================================= RENDER

  if (loading) {
    return (
      <BoxCentered>
        <Loader customColor="primary" customSize={48} />
      </BoxCentered>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {generalError && (
        <Box>
          <Banner text={generalError} type="error" />
        </Box>
      )}

      {!formMode && proposalListingPages()}
      {formMode && (
        <Box display="flex" flexDirection="column" gap="24px">
          <ProposalTitle
            clientView={clientView}
            openAnalytics={() => setOpenAnalytics(true)}
            previewFlag={previewFlag}
            proposalFormValue={proposalFormValue}
            values={values}
          />
          {previewFlag ? <ProposalGeneralInformation values={values} /> : null}

          {ServiceSummary()}

          {OtroProjectWideCosts()}

          {previewFlag ? PricingSummary() : UpdatePricingMargin()}
          {previewFlag ? null : invoiceConfiguration()}
          {previewFlag ? null : getDepositAmount()}
          {previewFlag ? null : personalizedMessage()}
          {clientView ? null : reviewProposal()}
          {clientView ? null : buttons()}
          {/* {clientView && !rejected && !accepted ? <Chat /> : null}  setProposalFormValue({
              ...proposalFormValue,
              personalizedMessage: value.target.value
            }); */}
          {showMessagesCatalog && (
            <ProposalMessagesModal
              open={showMessagesCatalog}
              setClose={() => setShowMessagesCatalog(false)}
              setToastNotification={setToastNotification}
              setValue={(value: any) => {
                setProposalFormValue((prev: any) => {
                  return {
                    ...prev,
                    messageTemplateId: value?.messageTemplateId || '',
                    personalizedMessage: `${value?.message}`
                  };
                });
              }}
            />
          )}
          {proposalData && proposalData?.id && showRejectModal && (
            <RejectProposalModal
              setRejected={setRejected}
              setShowRejectModal={setShowRejectModal}
              view="proposal"
            />
          )}
          <Modal
            aria-describedby="modal-modal-description-profile"
            aria-labelledby="modal-modal-title-profile"
            onClose={() => setOpenAnalytics(false)}
            open={openAnalytics}
          >
            <Box sx={styleModalAnalytics}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  height: '100% !important',
                  overflowY: 'auto',
                  padding: 4,
                  position: 'relative'
                }}
              >
                <Box sx={{ width: '80%' }}>
                  <Tabs centered onChange={handleChange} value={tabIndex}>
                    {sections.map((section, index) => (
                      <Tab key={index} label={section.title} />
                    ))}
                  </Tabs>
                  <Box mb={10} mt={4}>
                    <ChartCategory
                      data1={sections[tabIndex].data1}
                      data2={sections[tabIndex].data2}
                      title={sections[tabIndex].title}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: 'white',
                  bottom: 0,
                  boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
                  left: 0,
                  padding: 2,
                  position: 'absolute',
                  width: '100%'
                }}
              >
                {buttons()}
              </Box>
            </Box>
          </Modal>
          <Modal
            aria-describedby="modal-modal-description-profile"
            aria-labelledby="modal-modal-title-profile"
            onClose={() => setOpenSuccessful(false)}
            open={openSuccessful}
          >
            <Box sx={style}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  height: '100% !important',
                  position: 'relative'
                }}
              >
                <Box sx={{ textAlign: 'left', width: '100%' }}>
                  <Typography sx={{ fontSize: 24 }} variant="body_400">
                    Blue Commercial Cleaning LOGO
                  </Typography>
                  <Box height={300} mt={2} overflow="scroll">
                    <TextField
                      disabled
                      multiline
                      sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                          WebkitTextFillColor: 'black !important'
                        },
                        '& fieldset': { border: 'none' },
                        backgroundColor: 'white !important',
                        border: `1px solid black`,
                        height: '100% !important',
                        minHeight: '112px',
                        width: '100%'
                      }}
                      value={proposalFormValue?.personalizedMessage || ''}
                    />
                  </Box>
                  <br />
                </Box>
                <Box
                  bottom={100}
                  position="absolute"
                  textAlign="left"
                  width="100%"
                >
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={t('LEAD.CREATE.PROPOSAL.proposalModalAccept')}
                  />
                  <Box mt={2}>
                    <Typography variant="body_300">
                      {t('LEAD.CREATE.PROPOSAL.proposalModalSign')}
                    </Typography>
                  </Box>
                  <RegularInput customStyle={{ mt: -2, width: '100%' }} />
                </Box>
                <Box
                  bottom={0}
                  display="flex"
                  mb={4}
                  mt={-3}
                  position="absolute"
                  width="100%"
                >
                  <Button
                    fullWidth
                    onClick={() => setOpenSuccessful(false)}
                    sx={{ alignItems: 'center', display: 'flex', gap: 2, m: 1 }}
                    variant="cancel"
                  >
                    {t('LEAD.CREATE.PROPOSAL.buttonClientDecline')}
                  </Button>
                  <Button
                    fullWidth
                    onClick={() => setOpenSuccessful(false)}
                    sx={{ alignItems: 'center', display: 'flex', gap: 2, m: 1 }}
                    variant="tertiary"
                  >
                    {t('LEAD.CREATE.PROPOSAL.buttonClientAccept')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
      {toastNotification && (
        <ToastNotification
          onClose={() => setToastNotification(undefined)}
          text={toastNotification.text}
          type={toastNotification.type}
        />
      )}
    </Box>
  );
};

export default Proposal;
