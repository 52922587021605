import { Sheet } from 'react-modal-sheet';
import { ReactNode } from 'react';
import { Box } from '@mui/system';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface BottomSheetHeaderProps {
  onClose: () => void;
  title?: string;
}

interface BottomSheetProps extends BottomSheetHeaderProps {
  children: ReactNode;
  detent?: 'content-height' | 'full-height';
  isOpen: boolean;
  onCloseEnd?: () => void;
  onOpenStart?: () => void;
}

function BottomSheetHeader({ title, onClose }: BottomSheetHeaderProps) {
  return (
    <Box
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px'
      }}
    >
      <Typography color="primary" variant="heading_400">
        {title}
      </Typography>
      <IconButton onClick={onClose} sx={{ height: '10px' }}>
        <CloseIcon sx={{ fontSize: '16px' }} />
      </IconButton>
    </Box>
  );
}

function BottomSheet({
  isOpen,
  detent,
  children,
  onClose,
  onOpenStart,
  onCloseEnd,
  title
}: BottomSheetProps) {
  return (
    <Sheet
      detent={detent}
      isOpen={isOpen}
      onClose={onClose}
      onCloseEnd={onCloseEnd}
      onOpenStart={onOpenStart}
      style={{ zIndex: 50 }}
    >
      <Sheet.Container>
        <BottomSheetHeader onClose={onClose} title={title} />
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
}

BottomSheet.defaultProps = {
  detent: 'content-height',
  onCloseEnd: () => {},
  onOpenStart: () => {},
  title: ''
};

BottomSheetHeader.defaultProps = {
  title: ''
};

export default BottomSheet;
