import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { themePalette } from '../../theme/themeConfig';

interface StatProps {
  title: string;
  value: string;
}

const StatContainer = styled(Stack)(() => ({
  backgroundColor: themePalette.BACKGROUND,
  border: '1px solid #DDDDDD',
  borderRadius: '8px',
  padding: '16px',
  width: '100%'
}));

function Stat({ title, value }: StatProps) {
  return (
    <StatContainer spacing="8px">
      <Typography color="mainText.main" fontWeight="bold" variant="body_300">
        {title}
      </Typography>
      <Typography color="mainText.300" fontWeight="bold" variant="body_400">
        {value}
      </Typography>
    </StatContainer>
  );
}

export default Stat;
