import {
  Button,
  Box,
  Container,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  styled,
  SlideProps,
  CircularProgress
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';

import RegularInput from '../input/RegularInput';
import PasswordInput from '../input/PasswordInput';
import useAuthStore from '../../store/zustand/auth';
import Api from '../../api';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from '../../utils/authHelper';
import { FormLabel } from '../form/parts';
import Loader from '../shared/Loader';
import { loginLandingByRole } from '../../helpers/routesConstants';
import { roleName } from '../../utils/general';

interface Props {
  isMobile: boolean;
  setCurrentProcess: React.Dispatch<React.SetStateAction<string>>;
}

const Impersonate: React.FC<Props> = function Impersonate({
  isMobile,
  setCurrentProcess
}) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  // const { cache } = useSWRConfig();
  const userState = useAuthStore((state) => state);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [shouldAuth, setShouldAuth] = useState<boolean>(false);
  const [fireProfile, setFireProfile] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(
    getLocalStorage('rememberMe') || false
  );
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const autoLogin = getLocalStorage('keepSessionOpen');
  const { mutate } = useSWRConfig();

  // === ATTEMPT

  // ======================================== API

  // eslint-disable-next-line max-len

  const {
    data: profile,
    isValidating: isValidatingProfile,
    error: errorProfile
  } = useSWR(fireProfile ? 'api/profile' : null, Api.authApi.getProfile);

  // Clear cache when set Profile, & re-login with same User.
  const clearCache = () => mutate({ email, password, rememberMe }, null, true);
  const clearProfile = () => mutate('api/profile', null, true);

  const handleLogin = () => {
    if (email !== '' && password !== '') {
      clearCache();
      setShouldAuth(true);
    } else {
      setError(true);
      setErrorMessage(`${t('GENERAL.inputRequired')}`);
    }
  };

  const checkBoxChecked = (event: any) => {
    setRememberMe(event.target.checked);
    setLocalStorage('rememberMe', event.target.checked);
  };

  const forgotPassword = () => {
    navigate('/forgotPassword');
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {`${errorMessage}`}
        </Typography>
      </Container>
    );

  const loginValidation = (profile: any) => {
    const role = profile?.roles?.[0]?.name || 'default';
    if (profile && profile.email) {
      const autoLogin = getLocalStorage('keepSessionOpen');
      if (false || profile.block) {
        // if (!profile.active || profile.block) { // validation to be defined
        // attempt
        setError(true);
        setShouldAuth(false);
        setFireProfile(false);
      } else if (autoLogin) {
        userState.login();
        navigate(loginLandingByRole[role as keyof typeof loginLandingByRole], {
          replace: true
        });
      } else {
        userState.login();
        if (profile.mfa) {
          // should come in the token
          setCurrentProcess('mfa');
        } else {
          navigate(
            loginLandingByRole[role as keyof typeof loginLandingByRole],
            {
              replace: true
            }
          );
        }
      }
    }
  };

  useEffect(() => {
    if (profile) {
      const {
        roles,
        email,
        mfa,
        block,
        active,
        firstName,
        lastName,
        skills,
        phoneNumber,
        id,
        timeFormat,
        company
      } = profile;
      const { plan } = company;
      const rolesName = roles.map((ele: any) => ele.name);
      const skillsName = skills.map((ele: any) => ele.name);
      userState.setUser({
        active,
        block,
        email,
        firstName,
        id,
        lastName,
        mfa,
        phoneNumber,
        plan,
        roles: rolesName,
        skills: skillsName,
        timeFormat
      });
      loginValidation(profile);
    }
  }, [profile]);

  useEffect(() => {
    const oldToken = getLocalStorage('accessToken');
    if (state?.token) {
      clearProfile();
      if (!state?.isGoingBack && state.token !== oldToken) {
        removeLocalStorage('accessTokenImpersonate');
        setLocalStorage('accessTokenImpersonate', oldToken);
      }
      if (state?.isGoingBack) {
        removeLocalStorage('accessTokenImpersonate');
      }
      removeLocalStorage('accessToken');
      setLocalStorage('accessToken', state?.token);
      if (state.requestTwoFactor) {
        setCurrentProcess('mfa');
      } else {
        setFireProfile(true);
      }
    }
  }, [state]);

  useEffect(() => {
    if (errorProfile) {
      setError(true);
    }
  }, [errorProfile]);

  return (
    <Box
      sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
    >
      <CircularProgress size={40} />
    </Box>
  );
};

export default Impersonate;
